import { React, useState, useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useMsal } from '@azure/msal-react';
import { Box } from '@mui/system';
import { CardContent, Typography, Grid, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import { useUsers } from '../../helpers/hooks/userHooks';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { UserContext } from '../../components/Routes/Routes';
import { AssignRecruiter } from '../../helpers/hooks/adminHooks';
import { useCandidates } from '../../helpers/hooks/candidatesHooks'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';


import { useAlert, positions } from 'react-alert'
import SpinLoader from '../../components/SpinLoader/SpinLoader';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Multiselect from 'multiselect-react-dropdown';
function getRecruiters(jobs, jobId) {
    let recruiters = [];

    for (let i = 0; i < jobs?.length; i++) {
        if (jobs[i].jobId === jobId) {
            if (jobs[i].jobAssignments && jobs[i].jobAssignments.length) {
                jobs[i].jobAssignments.forEach((assignment) => {
                    if (assignment?.user?.userId) {
                        if (assignment?.user?.status === 'Active') {
                            assignment?.user?.roleAssignments?.map((el) => {
                                if (el.role.roleName === 'Recruiter') {
                                    console.log('*************************')
                                    console.log(assignment?.user?.displayName)
                                    recruiters.push({ userId: assignment?.user?.userId, displayName: assignment?.user?.displayName });
                                }
                            })

                        }
                    }
                });
            }
        }
    }
    return recruiters;
}

const Referal = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const { instance, accounts } = useMsal();
    const [search, setSearch] = useState({});
    const [open, setOpen] = useState();
    const [form, setForm] = useState({ "isReferal": true });
    const { addReferalCandidate, useGetReferalCandidates } = useUsers();

    const candidates = useGetReferalCandidates(accounts[0].localAccountId, search);

    const { candidateStatuses, gender, sources } = useMetaData();

    const [currentCandidate, setCurrentCandidate] = useState(null);
    const { assignCandidateToRecruiter } = AssignRecruiter();
    const value = useContext(UserContext);
    const { uploadDocuments } = useCandidates();
    const alert1 = useAlert()
    const { useJobRequisitions } = useJobs()



    const jobRequisitions = useJobRequisitions()
    const [jobTitle, setJobTitle] = useState([]);
    const [jobDetails, setJobDetailss] = useState();


    useEffect(() => {
        if (JSON.stringify(search) === '{}') {
            candidates.refetch();
        }

    }, [JSON.stringify(search), jobDetails])


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {

        setJobDetailss();

        setOpen(false);
    };
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }
    const handlesubmit = (e) => {
        e.preventDefault();


        if (form?.jobId) {
            form.candidateStatusId = candidateStatuses?.data?.data.filter((status) => status.displayText.status === 'Screening')[0]?.metaDataId;
            // form.company = jobs.data.data.filter((job) => job.jobId === form.jobId)[0].company;
            form.company = jobRequisitions.data.data.filter((job) => job.jobId === form.jobId)[0].company;
            form.otherSource = 'referral';
            form.sourceId = sources?.data?.data.filter((source) => source?.displayText?.source === 'Other')[0]?.metaDataId;

            const formData = new FormData();
            Object.keys(form).forEach(key => {
                formData.append(key, form[key]);
            });

            if (form?.file) {
                addReferalCandidate.mutate(form, {
                    onSuccess: (data) => {
                        formData.append('candidateId', data.data.candidateId);
                        formData.append('documentName', 'resume');
                        uploadDocuments.mutate({ formData });
                    }
                });
                alert(`Candidate ${form.candidateName} successfully Created`)
                setOpen(false);
            } else {
                alert("Please add attachment")
            }
        } else {
            alert('Please Select Job Title')

        }
    }

    const handleUpdate = (e) => {
        if (currentCandidate) {
            assignCandidateToRecruiter.mutate({ candidateId: currentCandidate, formData: form });
        }
    }


    const setJobDetails = (e, value) => {
        if (jobRequisitions?.data?.data) {
            const filteredData = jobRequisitions?.data?.data?.filter((e) => e?.jobId === value?.value);
            console.log(filteredData); // check the new filtered values
            setJobDetailss(filteredData);
        }
    }

    const handleSelectJobsColumns = (e) => {
        let value = e.map((a) => a.value)
        setSearch({ ...search, job: value })
        setJobTitle(e)

    }

    const handleRemoveJobsColumns = (e) => {

        let value = e.map((a) => a.value)

        setSearch({ ...search, job: value })

        setJobTitle(e)

    }

    function Reset() {
        setSearch({});

        window.location.reload(false);
    }

    if (uploadDocuments.isLoading) {
        alert1.show(form?.file?.name, { position: positions.BOTTOM_RIGHT });
    }


    if (sources.isLoading || sources.isFetching || candidateStatuses.isLoading || candidateStatuses.isFetching || jobRequisitions.isFetching
        || jobRequisitions.isLoading) {
        return <SpinLoader />
    }


    return (
        <>
            {(addReferalCandidate.isLoading) && <SpinLoader />}

            <Box ml={10} mt={15} sx={{ boxShadow: 1 }}  >

                {/* 

                <FormControl>

                    <Autocomplete
                        disablePortal
                        id="jobFilter"
                        name="job"
                        // value={search?.job ? JobsWithRecruiter?.data?.data.filter((job) => job.jobId === search.job)[0].jobTitle : undefined}
                        options={jobRequisitions?.data?.data.map((job) => ({ label: job.jobTitle, value: job.jobId }))}
                        // options={(jobRequisitions.data?.data.map((job) => {
                        //     if (job.status === 'Active') {
                        //         return { label: job.jobTitle, value: job.jobId }
                        //     }
                        // }))}
                        sx={{ width: 250 }}
                        style={{ marginBottom: '20px', marginRight: "20px" }}
                        // getOptionLabel={}
                        renderInput={(params) => <TextField {...params} label="Job-Title" />}
                        onChange={(e, job) => {
                            setSearch({ ...search, job: job.value })
                        }}
                    />
                </FormControl> */}


                <FormControl >
                    <Multiselect
                        placeholder='Job Title'
                        hidePlaceholder={true}
                        displayValue="key"
                        onKeyPressFn={function noRefCheck() { }}
                        onSelect={(e) => { handleSelectJobsColumns(e) }}
                        onRemove={(e) => { handleRemoveJobsColumns(e) }}
                        onSearch={function noRefCheck() { }}
                        options={jobRequisitions?.data?.data?.map((el) => { return { key: el.jobTitle, value: el.jobId } })}
                        selectedValues={jobTitle}
                        style={{
                            multiselectContainer: {
                                zIndex: 888,
                                "white-space": "nowrap",
                            },
                            searchBox: {
                                margin: '5px 15px 0 0',
                                width: '300px',
                                height: '50px',
                                zIndex: 999,
                                overflow: "hidden"
                            },
                        }}
                        showCheckbox
                    />

                </FormControl>


                <FormControl>
                    <InputLabel id="demo-simple-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-label"
                        id="candidateStatusId"
                        label="status"
                        name='candidateStatusId'
                        value={search?.status ? candidateStatuses?.data?.data.filter((status) => status.metaDataId === search.status)[0].displayText.status : undefined}
                        sx={{ width: 200, height: 55 }}
                        style={{ marginBottom: '20px', marginRight: "20px" }}
                        onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}>
                        {candidateStatuses?.data && candidateStatuses.data.data.map(status =>
                            <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.status}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <TextField type="text" name="keyword" label="" placeholder='search' value={search?.keyword ? search?.keyword : ''}
                    variant='outlined' onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })} />

                <Button
                    variant="contained"
                    endIcon={<PersonSearchTwoToneIcon />}
                    sx={{ borderRadius: 3 }}
                    style={{
                        padding: "10px",
                        marginBottom: '10px',
                        marginLeft: '70px'
                    }}
                    onClick={(e) => { candidates.refetch() }}>
                    Search</Button>

                <Button variant="contained"
                    sx={{ borderRadius: 3 }}
                    style={{ padding: "10px", marginBottom: '10px', marginLeft: '20px' }}
                    onClick={(e) => { Reset(e) }} > Reset </Button>

                <Button variant="contained" endIcon={<AddIcon />}
                    sx={{ borderRadius: 3 }}
                    style={{ padding: "10px", marginBottom: '10px', marginLeft: '20px' }}
                    onClick={handleClickOpen}> Add Referal </Button>

                <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose}> </Dialog>

                <TableContainer sx={{ overflow: 'auto', marginTop: 4, maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: "#243c80", color: "white" }}>
                            <TableRow
                                sx={{
                                    "& th": {
                                        borderBottom: "2px solid black",
                                        fontSize: "0.9rem",
                                        color: "white",
                                        backgroundColor: "#243c80",
                                        borderLeft: "1px solid #3b4864",
                                        height: '1px'
                                    }
                                }}
                            >
                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Referred on Date</TableCell>
                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Referred By</TableCell>
                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Name</TableCell>
                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Job Title</TableCell>
                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Status</TableCell>
                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Recruiter Name</TableCell>

                                {

                                    (window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') === 'TA Manager') &&


                                    <>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Recruiters</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Update</TableCell>

                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "white" }}>
                            {candidates?.data && candidates?.data?.data.map((candidate) => {
                                return <TableRow hover
                                    key={candidate?.candidate?.candidateId}>
                                    <TableCell >{moment(candidate?.user?.createdDate).format("dddd, MMMM Do YYYY")}</TableCell>
                                    <TableCell >{candidate?.user?.displayName}</TableCell>
                                    <TableCell>{candidate?.candidate?.candidateName}</TableCell>

                                    <TableCell>{candidate?.candidate?.jobTitle?.jobTitle}</TableCell>
                                    <TableCell>{candidate?.candidate?.candidateStatus?.displayText?.status}</TableCell>
                                    <TableCell>{candidate?.candidate?.createdBy?.displayName}</TableCell>
                                    {
                                        (window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') === 'TA Manager') &&

                                        <>
                                            <TableCell>
                                                <InputLabel id="recruiter-select-label">Recruiters Name</InputLabel>
                                                <Select
                                                    labelId="recruiter-select-label"
                                                    id="recruiter-select"
                                                    label="Recruiter"
                                                    name='recruiter'
                                                    sx={{ width: 300, height: 50, borderRadius: 4 }}
                                                    defaultValue={candidate?.candidate?.createdById}
                                                    // value={jobs?.data?.data[0].jobAssignments[0].user.displayName}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setCurrentCandidate(candidate?.candidate?.candidateId)
                                                    }}>
                                                    {/* {
                                                     JobsWithRecruiter?.data?.data && getRecruiters(JobsWithRecruiter?.data?.data, candidate?.candidate?.jobId).map((recruiter) =>
                                                        <MenuItem key={recruiter.userId} value={recruiter.userId}>{recruiter.displayName}</MenuItem>)
                                                } */}
                                                    {
                                                        jobRequisitions?.data?.data && getRecruiters(jobRequisitions?.data?.data, candidate?.candidate.jobId).map((recruiter) => <MenuItem key={recruiter.userId}
                                                            value={recruiter.userId}>{recruiter.displayName}</MenuItem>)
                                                    }

                                                </Select>
                                            </TableCell>

                                            <TableCell>
                                                <Button variant="outlined" endIcon={<EditIcon />} sx={{ borderRadius: 4 }}
                                                    onClick={(e) => { handleUpdate(e) }}>Update</Button>
                                            </TableCell>
                                        </>
                                    }

                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>



                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='lg'>

                    <DialogContent>
                        <h2 style={{
                            padding: '10px', backgroundColor: "cornflowerblue",
                            fontWeight: 700, fontSize: "20px",
                        }} > Referal - Form </h2>

                        <form onSubmit={(e) => handlesubmit(e)}>
                            {/* <form> */}
                            <Box sx={{
                                padding: "10px",
                                display: 'grid',
                                gap: 1,
                                gridTemplateColumns: 'repeat(3, 3fr)',
                                border: '#4774ce'
                            }}>

                                <Grid container>

                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            <TextField
                                                required

                                                label="Candidate Name"
                                                name="candidateName"
                                                placeholder='Enter Candidate Full Name'
                                                variant='outlined'
                                                fullWidth margin='dense'
                                                onChange={(e) => handleChange(e)}

                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            <TextField

                                                type="email"
                                                name="candidateEmail"
                                                label="Email"
                                                placeholder='Enter Candidate Email'
                                                variant='outlined'
                                                fullWidth margin='dense'
                                                onChange={(e) => handleChange(e)}
                                                required />

                                        </FormControl>

                                    </Grid>

                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            <TextField

                                                type="number"
                                                name="candidatePhone"
                                                label="Contact Number"
                                                InputProps={{ inputProps: { min: "999999999", max: "9999999999", step: "1" } }}
                                                placeholder='Enter Phone Number'
                                                variant='outlined'
                                                margin='dense'
                                                fullWidth
                                                onChange={(e) => handleChange(e)}
                                                required />

                                        </FormControl>

                                    </Grid>



                                </Grid>

                                <Grid container>

                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            <TextField
                                                required
                                                abel="Experience"
                                                name="totalExperience"
                                                placeholder='Enter Experience *'
                                                variant='outlined'
                                                margin='dense'
                                                onChange={(e) => handleChange(e)} />
                                        </FormControl>

                                    </Grid>

                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            <TextField
                                                type="key-skill"
                                                name="keySkills"
                                                label="keySkills"
                                                placeholder='Enter relevant key skill'
                                                variant='outlined'
                                                margin='dense'
                                                multiline fullWidth
                                                onChange={(e) => handleChange(e)} />
                                        </FormControl>
                                    </Grid>

                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            Select Gender


                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="gender"
                                                onChange={(e) => handleChange(e)}>
                                                {gender?.data && gender?.data?.data.map((gen) =>
                                                    <FormControlLabel value={gen.metaDataId} control={<Radio required />} label={gen.displayText.gender} />
                                                )}

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                                            <Autocomplete
                                                required
                                                // disablePortal
                                                id="jobFilter"
                                                name="job"
                                                options={jobRequisitions.data?.data.map((job) => ({ key: 'job', value: job.jobId, label: job.jobTitle }))}
                                                sx={{ width: 300 }}
                                                style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                                                renderInput={(params) => <TextField {...params} label="Job-Title *" />}

                                                onChange={(e, value) => {
                                                    setJobDetails(e, value);
                                                    setForm({ ...form, jobId: value.value })
                                                }}
                                            />
                                        </FormControl>

                                    </Grid>


                                    {jobDetails?.length > 0 &&
                                        <>
                                            <Grid>
                                                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                                                    <TextField
                                                        label="Company"
                                                        name="Company"
                                                        placeholder='Company'
                                                        variant='outlined'
                                                        margin='dense'
                                                        disabled={true}
                                                        value={jobDetails?.map((el) => el?.company)}
                                                    />
                                                </FormControl>

                                            </Grid>


                                            <Grid>
                                                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                                                    <TextField
                                                        label="Location"
                                                        name="Location"
                                                        placeholder='Location'
                                                        variant='outlined'
                                                        margin='dense'
                                                        disabled={true}

                                                        value={jobDetails?.map((el) => el?.city)}
                                                    />
                                                </FormControl>

                                            </Grid>

                                        </>
                                    }

                                </Grid>


                                <Grid container>
                                    <Grid>
                                        <FormControl variant="standard" sx={{ width: 300, marginTop: 4 }} size="small">
                                            {/* <label id="documents">{form?.file ? 'Reupload' : 'Upload Resume'}</label> */}
                                            <label id="documents">Upload Resume</label>

                                            <input
                                                id='documents'
                                                name='file'
                                                required type="file"
                                                accept=".doc,.docx,application/pdf"
                                                onChange={(e) => {
                                                    if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                        || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
                                                        setForm({ ...form, [e.target.name]: e.target.files[0] });
                                                    } else {
                                                        alert('Document type should be PDf or Word only');
                                                        e.target.value = ''
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid>

                                    </Grid>
                                </Grid>

                                <Grid container>

                                    {jobDetails?.length > 0 &&
                                        <>

                                            <Grid>

                                                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                                                    <TextField
                                                        label=" Min / Max Experience"
                                                        variant="outlined"
                                                        name='experience'
                                                        disabled={true}
                                                        margin='dense'
                                                        value={jobDetails?.map((el) => el?.experience)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </>
                                    }
                                </Grid>

                            </Box>

                            <Button size="medium" sx={{ mt: '40px', ml: '10px' }} variant="contained" color="success" type='submit'>
                                Submit
                            </Button>

                            <Button
                                sx={{ mt: '40px', ml: '30px' }}
                                size="medium"
                                endIcon={<CloseIcon />}
                                variant="contained" color="primary"
                                type='button' onClick={handleClose}>Close</Button>

                        </form>



                    </DialogContent >
                </Dialog >
            </Box >

        </>
    )
};

export default Referal;