
import * as React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';


// //**********************************************Drawer**************************************************************************

import { useState, useContext } from 'react';

import { NavLink } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Person3Icon from '@mui/icons-material/Person3';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Collapse } from "@mui/material";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import AssessmentIcon from '@mui/icons-material/Assessment';
import { UserContext } from '../../components/Routes/Routes';




const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',


    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function MiniDrawer() {

  const user = useContext(UserContext);


  let salaryVisibility;
  user?.data?.roleAssignments.filter((el) => {
    if (el.role.roleName === 'SalaryAccess') {
      salaryVisibility = true
    }
  }
  )

  const role = window.localStorage.getItem('role');

  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const handleClose = () => {
    setDrawerOpen(false)
  }


  const handleClickOpen = () => {
    setDrawerOpen(true)
  }

  const handleClickAdmin = () => {
    setOpen(!open);
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);


  const handleClick = () => {
    setOpen1(!open1);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleClick2 = () => {
    setOpen2(!open2);
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open} sx={{ width: '20px' }} onMouseOver={handleDrawerOpen} onMouseOut={handleDrawerClose}>
        <DrawerHeader>
          {/* {open ?
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>

            :
            <IconButton
              color="inherit"
              aria-label="open drawer"

              onClick={handleDrawerOpen}
              edge="start"


            > <MenuIcon /></IconButton>
          } */}
        </DrawerHeader>
        <Divider />

        {open &&
          <>
            <img src="/logos/logo-high.png" style={{ width: 'auto', background: 'white', height: 'auto' }} />
          </>
        }

        {!open &&
          <>
            <img src="/favicon.png" style={{ width: '30px', background: 'white', height: 'auto', marginLeft: '25px', marginTop: '10px', marginBottom: '100px' }} />
          </>

        }

        <List>

          <NavLink to={'/'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
            <ListItem divider >
              <ListItemButton>
                <ListItemIcon>
                  <HomeOutlinedIcon />
                </ListItemIcon>
                <h3 style={{ p: '70px' }}>Home</h3>
              </ListItemButton>
            </ListItem>
          </NavLink>



          {(window.localStorage.getItem('role') === 'HR Manager' || window.localStorage.getItem('role') === 'HR' || window.localStorage.getItem('role') === 'Recruiter' || window.localStorage.getItem('role') === 'TA Manager' || window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') === 'Hiring Manager') &&
            <>
              <NavLink to={'/candidates'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                <ListItem divider>
                  <ListItemButton>
                    <ListItemIcon>
                      <Person3Icon />
                    </ListItemIcon>
                    <h3>Candidates</h3>
                  </ListItemButton>
                </ListItem>
              </NavLink>


              <NavLink to={'/jobRequisition'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                <ListItem divider>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArticleOutlinedIcon />
                    </ListItemIcon>
                    <h3>Job Requisition</h3>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {salaryVisibility === true &&
                <>
                  <NavLink to={'/SalaryView'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                    <ListItem divider>
                      <ListItemButton>
                        <ListItemIcon>
                          <ArticleOutlinedIcon />
                        </ListItemIcon>
                        <h3>SalaryView</h3>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                </>
              }
            </>
          }

          {(window.localStorage.getItem('role') !== 'Referal') && (window.localStorage.getItem('role') !== 'HR Manager') &&
            <>

              <>
                <ListItemButton onClick={handleClick2} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <h3>Dashboards</h3>
                  {open2 ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink to={'/dashboard'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <DashboardIcon />
                        </ListItemIcon>
                        <h3>Job Dashboard</h3>
                      </ListItemButton>
                    </NavLink>

                    {(window.localStorage.getItem('role') !== 'Referal') && (window.localStorage.getItem('role') !== 'Hiring Manager') &&
                      <>

                        <ListItem divider>
                          <ListItemButton>
                            <NavLink to={'/recruiterdashboard'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                              <ListItemIcon>
                                <DashboardIcon />
                              </ListItemIcon>
                              <h3>Recruiter Dashboard</h3>
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                      </>}


                  </List>

                </Collapse>

              </>


            </>
          }

          <NavLink to={'/referral'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
            <ListItem divider>
              <ListItemButton>
                <ListItemIcon>
                  <Diversity3Icon />
                </ListItemIcon>
                <h3>Referral</h3>
              </ListItemButton>
            </ListItem>
          </NavLink>




          {(window.localStorage.getItem('role') === 'TA Manager' || window.localStorage.getItem('role') === 'Admin') &&

            <>
              <ListItemButton onClick={handleClick} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <h3>Admin</h3>
                {open1 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={open1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                {salaryVisibility === true &&
                <>
                  <NavLink to='/admin/users' style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>

                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <PersonOutlineOutlinedIcon />
                      </ListItemIcon>
                      <h3>Users</h3>
                    </ListItemButton>

                  </NavLink>
                  </>
                }


                  <NavLink to={'/admin/remainder'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <CircleNotificationsIcon />
                      </ListItemIcon>
                      <h3>Reminder</h3>
                    </ListItemButton>
                  </NavLink>

                  <NavLink to={'/admin/templates'} style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ContentPasteIcon />
                      </ListItemIcon>
                      <h4 height={8} >RealTime Templates</h4>
                    </ListItemButton>
                  </NavLink>


                  {/* <NavLink to='/admin/metadata' style={{ 'textDecoration': 'none', display: 'flex', alignItems: 'center', color: 'black' }}>

                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <PersonOutlineOutlinedIcon />
                      </ListItemIcon>
                      <h3>MetaData</h3>
                    </ListItemButton>

                  </NavLink> */}

                </List>
              </Collapse>

            </>
          }
        </List>

      </Drawer>

    </Box >
  );
}





