import React, { useContext, useState, useEffect, useRef, useCallback, } from 'react';
import './Home.css';
import moment from 'moment';
import { UserContext } from '../../components/Routes/Routes';
import SpinLoader from '../../components/SpinLoader/SpinLoader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue, lightGreen } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useInterviews } from '../../helpers/hooks/interviewsHooks';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import GreetingCard from '../../components/GreetingCard/GreetingCard'
import AddOnboardingDetails from '../../components/AddOnboardingDetails/AddOnboardingDetails';
//!---------------------------------------------------------------------------\
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import { ArticleOutlined } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import Button from '@mui/material/Button';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
//!---------------------------------------------------------------------------\
function SimpleDialog(props) {
  const { onClose, selectedValue, open, roles } = props;

  const handleClose = () => {
    if (!selectedValue) alert('First Select Any Role to continue.');
    else onClose(selectedValue);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select A Role To Continue</DialogTitle>
      <List sx={{ pt: 0 }}>
        {roles?.map((role) => (
          <ListItem button onClick={() => handleListItemClick(role?.roleName)} key={role?.roleName}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={role?.roleName} />
          </ListItem>
        ))}

      </List>
    </Dialog>
  );
}

export function SimpleDialogDemo({ roles, setIsRoleSelected }) {
  const [open, setOpen] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    window.localStorage.setItem('role', value);
    setIsRoleSelected(true);
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        roles={roles}
      />
    </div>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function FullWidthTabs() {
  const { getInterview } = useInterviews();
  const [candidate, setCandidate] = useState(null);
  const valueUser = useContext(UserContext);
  const user = valueUser?.data;
  const [isRoleSelected, setIsRoleSelected] = useState(window.localStorage.getItem('role'));
  const interview = getInterview
  let roles;
  let count = 0;
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = useState({});
  const [candidateStatus, setCandidateStatus] = useState();
  const { useGetCandidatesHome } = useCandidates();
  const candidates = useGetCandidatesHome(search);
  console.log(search);
  const theme = useTheme();
  const todayDate = moment(new Date());
  let date = moment(todayDate).format('YYYY-MM-DD')
  const [isOnboardingForm, setOnboardingForm] = useState(false);
  const onboardingCandidate = candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Offered')
  const referalCandidate = candidates?.data?.data?.rows?.filter((el) => el?.joiningDate === null ? '' : moment(el?.joiningDate)?.add(3, 'month').format('YYYY-MM-DD') === `${date}` && el?.otherSource === 'referral')

  if (user?.roleAssignments && user?.roleAssignments?.length > 0) {
    roles = user?.roleAssignments
      .map((assignment) => assignment.role)
      .filter((role) => role.roleName !== 'SalaryAccess');

  }

  useEffect(() => {
  }, [roles, interview, candidates]);

  const handleSearch = (e) => {
    setSearch({ [e.target.name]: e.target.value })
  }

  function Reset() {
    setSearch({});
    window.location.reload(false);
  }

  useEffect(() => {
    candidates.refetch()
  }, [candidateStatus])

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setSearch({ ['status']: '95d729ef-0b79-45ea-8eb4-afbb0128ab04' })
      // setSearch({['OffereddateSelector']: 'today' })

    }
    if (newValue === 2) {
      setSearch({ ['status']: '7bfcc09d-7014-47bd-8bda-984081ddd991' })
      // setSearch({['OffereddateSelector']: 'today' })

    }
    if (newValue === 3) {

      setSearch({ ['JoineddateSelector']: 'today' })

    }
    if (newValue === 4) {
      setSearch({});
    }
    // if (newValue === 4) {
    //   setSearch({ ['status']: '7bfcc09d-7014-47bd-8bda-984081ddd991' })
    //   // setSearch({ ['expectedJoiningCandidates']: 'today' })

    // }
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {

    setValue(index);
  };
  if (candidates.isLoading && candidates.isFetching) {
    return <SpinLoader />
  }
  console.log(candidates?.data?.data?.rows);
  return (
    <>
      <Box>
        {
          !isRoleSelected ? <SimpleDialogDemo roles={roles} setIsRoleSelected={setIsRoleSelected} /> :
            <>
              <GreetingCard />
            </>
        }
      </Box>


      <div style={{ height: 'auto', margin: '50px', marginTop: '10px' }}>
        {isOnboardingForm &&
          <AddOnboardingDetails setOnboardingForm={setOnboardingForm} isOnboardingForm={isOnboardingForm} candidate={candidate} />
        }
      </div>

      {(window.localStorage.getItem('role') !== 'Referal') &&


        <Box
          sx={{
            bgcolor: 'background.paper',
            width: 'auto', marginTop: 40,
            marginLeft: 14,
            marginRight: 7,
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >

              <Tab label="Today interview" {...a11yProps(0)} />
              <Tab label="To Be Offered Candidates" {...a11yProps(1)} onClick={() => setCandidateStatus('95d729ef-0b79-45ea-8eb4-afbb0128ab04')} />
              <Tab label="Expected Joining" {...a11yProps(2)} onClick={() => setCandidateStatus('7bfcc09d-7014-47bd-8bda-984081ddd991')} />
              <Tab label="Joined Candidates" {...a11yProps(3)} onClick={() => setCandidateStatus('a5392787-669d-43cd-ba75-773c1a8ddc02')} />
              <Tab label="Today 3 Months Completed Referral Candidates" {...a11yProps(4)} />



            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <h3 style={{
                marginBottom: '10px',
                color: "Black", fontWeight: 700, fontSize: "17px",
              }}>Today interview <Badge badgeContent={interview?.data?.data?.length} color="primary"
                showZero size="large" sx={{ margin: 1, marginLeft: 2 }} /></h3>

              <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead stickyHeader>
                    <TableRow hover>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          // minWidth: 100,
                          // maxWidth: 100,
                          width: '10px',
                          border: "2px solid #3b4864"
                        }}
                      > Sr.No</TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Candidate Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          width: '300px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        JobTitle
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '200px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Time - IST/EST
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >

                        Panel Member
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >

                        Interview Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Current Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        RecruiterName
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Duration(Min)
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        InterviewMode
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interview?.data?.data?.length === 0 ?
                      <>

                      </>
                      :
                      <>
                        {interview?.data?.data?.map((el) => {
                          count = count + 1;
                          return (
                            <>
                              <TableRow hover>
                                <TableCell> {count}</TableCell>
                                <TableCell>{el?.candidate?.candidateName} </TableCell>
                                <TableCell> {el?.jobTitle?.jobTitle} </TableCell>
                                <TableCell>{moment(el?.interviewStartTime, 'HH:mm:ss').format('h:mm A')} / {moment(el?.interviewStartTime, 'HH:mm:ss').subtract(9.5, 'hours').format('h:mm A')}
                                </TableCell>
                                <TableCell> {el?.panelMemberNames.map((el) => el.displayName)} </TableCell>
                                <TableCell> {el?.interviewStatus} </TableCell>
                                <TableCell> {el?.candidate?.candidateStatus?.displayText?.status} </TableCell>
                                <TableCell>{el?.createdBy?.displayName}</TableCell>
                                <TableCell> {el?.interviewDuration}</TableCell>
                                <TableCell>{el?.interviewMode === true ? 'Online' : 'Face to Face'} </TableCell>

                              </TableRow>
                            </>
                          );
                        })}
                      </>}
                  </TableBody>
                </Table>
              </TableContainer>

            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <h3 style={{
                marginBottom: '10px',
                color: "Black", fontWeight: 700, fontSize: "17px",
              }}
              >Expected Offered Candidates <Badge badgeContent={candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'DocVerificationCompleted')?.length} color="primary" showZero size="large"
                sx={{ margin: 1, marginLeft: 2 }} /></h3>


              <Grid container m={2} gap={5}>

                {/* <RadioGroup
                  row
                  aria-labelledby="input-radio-buttons-group-source-label"
                  name="OffereddateSelector"
                  required
                  // defaultValue={'today'}
                  onChange={(e) => handleSearch(e)}>
                  <FormControlLabel value={'today'} control={<Radio required />} label='Today' />
                  <FormControlLabel value={'last7days'} control={<Radio required />} label='Last 7 Days' />
                  <FormControlLabel value={'last1month'} control={<Radio required />} label='Last 1 month' />

                </RadioGroup>

                <Button
                  variant="contained"
                  endIcon={<PersonSearchTwoToneIcon />}
                  style={{
                    padding: "10px",
                    marginBottom: '10px',
                    marginLeft: '70px'
                  }}
                  onClick={(e) => { candidates.refetch() }}>Search</Button>

                <Button variant="contained"
                  style={{ padding: "5px", marginBottom: '10px', marginLeft: '20px' }}
                  size='small'
                  onClick={(e) => { Reset({}) }} > Reset </Button> */}
              </Grid>
              <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead stickyHeader>
                    <TableRow hover>

                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >

                        Candidate Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          width: '300px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        JobTitle
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        RecruiterName
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Selected Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'DocVerificationCompleted').length === 0 ?
                      <>

                      </>
                      :
                      <>
                        {candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'DocVerificationCompleted')?.map((el) => {
                          return (
                            <>
                              <TableRow hover>
                                <TableCell>{el?.candidateName}</TableCell>
                                <TableCell > {el?.jobTitle?.jobTitle}</TableCell>
                                <TableCell>{el.createdBy.displayName}</TableCell>
                                <TableCell >{el?.candidateStatus?.displayText?.status}</TableCell>
                                <TableCell >{moment(el?.selectedRejectedDate).format("dddd, MMMM Do YYYY")}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </>}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <h3 style={{
                marginBottom: '10px',
                color: "Black", fontWeight: 700, fontSize: "17px",
              }}
              >Expected Joining Candidates <Badge badgeContent={candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Offered')?.length} color="primary" showZero size="large"
                sx={{ margin: 1, marginLeft: 2 }} /></h3>

              <Grid container m={2} gap={5}>

                <RadioGroup
                  row
                  aria-labelledby="input-radio-buttons-group-source-label"
                  name="expectedJoiningCandidates"
                  // defaultValue={'today'}
                  required
                  onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}>

                  <FormControlLabel value={'today'} control={<Radio required />} label='Today' />
                  <FormControlLabel value={'last7days'} control={<Radio required />} label='Next 7 Days' />
                  <FormControlLabel value={'last1month'} control={<Radio required />} label='Next 1 month' />

                </RadioGroup>

                <Button
                  variant="contained"
                  endIcon={<PersonSearchTwoToneIcon />}
                  style={{
                    padding: "10px",
                    marginBottom: '10px',
                    marginLeft: '70px'
                  }}
                  onClick={(e) => { candidates.refetch() }}>Search</Button>

                <Button variant="contained"
                  style={{ padding: "5px", marginBottom: '10px', marginLeft: '20px' }}
                  size='small'
                  onClick={(e) => { Reset({}) }} > Reset </Button>
              </Grid>

              <TableContainer sx={{ width: '100%', overflow: 'auto', height: '70vh', }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead stickyHeader>
                    <TableRow hover>

                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >

                        Candidate Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          width: '300px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        JobTitle
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        RecruiterName
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Expected Joined Date
                      </TableCell>
                      {(window.localStorage.getItem('role') === 'Recruiter') &&
                        <TableCell
                          align="left"
                          style={{
                            position: 'sticky',
                            left: 0,
                            background: '#243c80',
                            color: 'white',
                            top: 0, zIndex: 666,
                            width: '150px',
                            border: "1px solid #3b4864"
                          }}
                        >
                          Onboarding Details
                        </TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Offered')?.length === 0 ?
                      <>
                      </>
                      :
                      <>
                        {candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Offered')?.map((el) => {
                          return (
                            <>
                              <TableRow hover>
                                <TableCell>{el?.candidateName}</TableCell>
                                <TableCell > {el?.jobTitle?.jobTitle}</TableCell>
                                <TableCell>{el.createdBy.displayName}</TableCell>
                                <TableCell >{el?.candidateStatus?.displayText?.status}</TableCell>
                                <TableCell> {moment(el?.tentativeDateOfJoining).format("dddd, MMMM Do YYYY")}</TableCell>
                                {(window.localStorage.getItem('role') === 'Recruiter') &&
                                  <TableCell onClick={(e) => {
                                    setOnboardingForm(true);
                                    setCandidate(el)
                                  }}> <ArticleOutlined />
                                  </TableCell>
                                }
                              </TableRow>
                            </>
                          );
                        })}
                      </>}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <h3
                style={{
                  marginBottom: '10px',
                  color: "Black", fontWeight: 700, fontSize: "17px",
                }}
              >Joined Candidate <Badge badgeContent={candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Joined')?.length} color="primary" showZero size="large"
                sx={{ margin: 1, marginLeft: 2 }} /> </h3>

              <Grid container m={2} gap={5}>

                <RadioGroup
                  row
                  aria-labelledby="input-radio-buttons-group-source-label"
                  name="JoineddateSelector"
                  defaultValue={'today'}
                  required
                  onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}>

                  <FormControlLabel value={'today'} control={<Radio required />} label='Today' />
                  <FormControlLabel value={'last7days'} control={<Radio required />} label='Last 7 Days' />
                  <FormControlLabel value={'last1month'} control={<Radio required />} label='Last 1 month' />

                </RadioGroup>

                <Button
                  variant="contained"
                  endIcon={<PersonSearchTwoToneIcon />}
                  style={{
                    padding: "10px",
                    marginBottom: '10px',
                    marginLeft: '70px'
                  }}
                  onClick={(e) => { candidates.refetch() }}>Search</Button>

                <Button variant="contained"
                  style={{ padding: "5px", marginBottom: '10px', marginLeft: '20px' }}
                  size='small'
                  onClick={(e) => { Reset({}) }} > Reset </Button>
              </Grid>
              <TableContainer sx={{ width: '100%', overflow: 'auto' }}>

                <Table stickyHeader aria-label="sticky table">
                  <TableHead stickyHeader>
                    <TableRow hover>

                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >

                        Candidate Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          width: '300px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        JobTitle
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        RecruiterName
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                       Joining Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Joined').length === 0 ?
                      <>

                      </>
                      :
                      <>
                        {candidates?.data?.data?.rows?.filter((el) => el.candidateStatus?.displayText?.status === 'Joined')?.map((el) => {

                          return (
                            <>
                              <TableRow hover>
                                <TableCell>{el?.candidateName}</TableCell>
                                <TableCell>{el?.jobTitle?.jobTitle}</TableCell>
                                <TableCell>{el?.createdBy?.displayName}</TableCell>
                                <TableCell> {el?.candidateStatus?.displayText?.status}</TableCell>
                                <TableCell> {moment(el?.joiningDate).format("dddd, MMMM Do YYYY")}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </>}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <h3 style={{
                marginBottom: '10px',
                color: "Black", fontWeight: 700, fontSize: "17px",
              }}>Today 3 Month Completed Referral Candidate <Badge badgeContent={referalCandidate?.length} color="primary" showZero size="large" sx={{ margin: 1, marginLeft: 2 }} />
              </h3>
              <TableContainer sx={{ width: '100%', overflow: 'auto' }}>

                <Table stickyHeader aria-label="sticky table">
                  <TableHead stickyHeader>
                    <TableRow hover>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >

                        Candidate Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          width: '300px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        JobTitle
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        RecruiterName
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#243c80',
                          color: 'white',
                          top: 0, zIndex: 666,
                          width: '150px',
                          border: "1px solid #3b4864"
                        }}
                      >
                        Referred By
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referalCandidate?.length === 0 ?
                      <>

                      </>
                      :
                      <>
                        {referalCandidate?.map((el) => {

                          return (
                            <>
                              <TableRow hover>

                                <TableCell> {el?.candidateName}</TableCell>
                                <TableCell> {el?.jobTitle?.jobTitle}</TableCell>
                                <TableCell> {el?.createdBy?.displayName}</TableCell>
                                <TableCell> {el?.candidateStatus?.displayText?.status}</TableCell>
                                <TableCell>{el?.referredBy?.user?.displayName}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </>}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>

          </SwipeableViews>
        </Box>
      }
    </>
  );
}