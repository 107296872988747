import React, { useContext, useState, useEffect} from 'react';
import { useSpring, animated } from 'react-spring';
import './GreetingCard.css'; // Create this CSS file for styling
import moment from 'moment';
const GreetingCard = () => {
  // Define an animation using react-s
  const [greeting, setGreeting] = useState('');
  const animation = useSpring({
    from: { opacity: 0, transform: 'translateY(-100%)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 900 },
  });

  useEffect(() => {
    const currentHour = moment().format("HH");

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting("Good Afternoon");
    } else if (currentHour >= 17 && currentHour < 22) {
      setGreeting("Good Evening");
    } else if (currentHour >= 22 && currentHour < 5) {
      setGreeting("Good Night");
    } else {
      setGreeting("Hello")
    }
  }, );
  
  let date = moment().format("dddd, MMMM Do YYYY");

  return (
    <animated.div className="greeting-card" style={animation}>
      <h1 style={{color: "#243c80"}}>{greeting}</h1>
      <h3 >Welcome To RecruiterApp</h3>
      <h3>Have a great day!</h3>
      <h4>Date : {date}</h4>

    </animated.div>
  );
};

export default GreetingCard;