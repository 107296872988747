import React, { useState, useRef, useEffect } from 'react'
import './Remainder.css'
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useAdmin, EmailRemainder } from '../../helpers/hooks/adminHooks';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../../components/TextEditor/TextEditor';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Multiselect from 'multiselect-react-dropdown';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { useUsers } from '../../helpers/hooks/userHooks';
import Users from '../Users/Users';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CleaningServices } from '@mui/icons-material';
export default function Remainder() {
    const { AllappUsers, useAppUsers } = useUsers();

    const appUsers = useAppUsers();
    
    const [roleNameforUsers, setRoleNameforUsers] = useState(null);
    const users = AllappUsers(roleNameforUsers);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [duration, setDuration] = useState(null);
    const [columnNames, setColumnNames] = useState(null);
    const status = useRef();
    // const templateForm = useRef();
    const { candidateStatuses, jobAssign } = useMetaData();
    const [selectedColumns, setSelectedColumns] = useState([]);
    const { useGetEmailRemainderStatus, updateDuration } = EmailRemainder();
    const emailTemplates = useGetEmailRemainderStatus(selectedStatus);
    const { roles } = useAdmin();
    const [enable, setEnable] = useState(false);
    const [showJob, setShowJob] = useState(false);
    const [showCandidate, setShowCandidate] = useState(false);
    const [templateUserId, settemplateUserId] = useState([]);
    // const [selectedTemplateUserId, setselectedTemplateUserId] = useState([]);

    let emailtemp;

    useEffect(() => {
        // if (roles?.data) {
        //     let temp = roles?.data?.data.map((role) => ({ label: role.roleName, value: role.roleId }));
        //     if (temp) {
        //         temp.push({ label: 'Others', value: '' });
        //         setColumnNames(temp);
        //     }
        // }
        // if (emailTemplates.data) {
        //     if (columnNames) {
        //         let arr = columnNames?.filter((column) => emailTemplates?.data?.data.some((template) =>
        //             template.roleId === (column.value ? column.value : null)));
        //         if (arr) {
        //             setSelectedColumns(arr)
        //         }
        //     }
        //     setDuration(emailTemplates?.data?.data[0]?.duration)
        // }

        if (emailTemplates?.data?.data) {
            emailtemp = emailTemplates?.data?.data?.map((el) => {
                return {
                    key: el?.role?.roleName, value: el?.role?.roleId
                }
            })
            setSelectedColumns(emailtemp)
            setDuration(emailTemplates?.data?.data[0]?.duration === undefined ||
                emailTemplates?.data?.data[0]?.duration === null
                ? 0 : emailTemplates?.data?.data[0]?.duration)
    
            // setselectedTemplateUserId(emailTemplates?.data?.data?.map((el) => el.userId))
        }
        if (enable === true) {
            users.refetch();
            setEnable(false);
        }
        // if (showJob) {
        //     setShowJob(true);
        // }
        // if (showCandidate) {
        //     setShowCandidate(true);
        // }
    }, [
        JSON.stringify(emailTemplates?.data?.data),
        status?.current?.value,
        // enable,
        // showJob,
        // showCandidate,
        selectedStatus,
    ]);
    const selectChange = (e) => {
        setSelectedStatus(status.current.value)
    }
    const handleChange = (e) => {
        if (e.target.value === 'job') {
            refresh()
            setShowJob(true)
        } else {
            setShowJob(false)
        }
        if (e.target.value === 'candidate') {
            refresh()
            setShowCandidate(true)
        } else {
            setShowCandidate(false)
        }
    }
    const handleSelectRole = (e) => {
        let value = e.map((a) => a.key)
        setRoleNameforUsers(...value)
        setSelectedColumns(e)
        setEnable(true)
    }
    const handleRemoveRole = (e) => {
        let value = e.map((a) => a.key)
        setSelectedColumns(e)
        // setEnable(true)
    }
    // const handleSelectUser = (e) => {
    //     let value = e.map((a) => a.value)
    //     settemplateUserId(e)
    // }
    // const handleRemoveUser = (e) => {
    //     let value = e.map((a) => a.value)
    //     settemplateUserId(e)
    // }
    const handleSelectStatus = (e) => {
        let value = e.map((a) => a.value)
        setSelectedStatus(...value)
  
    }
    const handleRemoveStatus = (e) => {
        let value = e.map((a) => a.value)
        setSelectedStatus(...value)
      
      
    }
    const handleSetDuration = (e) => {
        
        if (selectedStatus) {
            if(selectedStatus==='7bfcc09d-7014-47bd-8bda-984081ddd991' || selectedStatus==='a5392787-669d-43cd-ba75-773c1a8ddc02'){
                setDuration(1)
            }
            if (emailTemplates?.data?.data?.map((el) => el.body)?.length) {

                if (duration > 0) {
                    updateDuration.mutate({ id: selectedStatus, formData: { duration: duration } },
                        {
                            onSuccess: (data) => {
                                alert(`${duration} Days Duration Set Successfully`)
                            }
                        },
                        {
                            onError: (data) => {
                                alert('error')
                            }
                        }
                    )

                } else {
                    alert(`Please Set Duration Greater Than ${duration} Days`)

                }
            }else{
                alert(`Please Select Role Add Template and Compose then Set Duration`)
            }
        } else {
            alert(`Please Select Status First`)
        }
        window.location.reload(false);
    }
    // const handleAssign = (e) => {
    //     setSelectedStatus(e.target.value);
    // }
    function refresh() {
        setRoleNameforUsers(null)
        setSelectedColumns(null)
        emailTemplates.refetch()
        setSelectedStatus(null)
        // setselectedTemplateUserId([])
    }
    function reset() {
        window.location.reload(false);
    }
    return (
        <Box m={5}>
            <Grid container  sx={{  marginLeft: 30,  marginTop: 3, }} >
                {/* <Grid xs='auto'>
                    <FormControl variant="standard" sx={{ width: 300, marginTop: 18, marginLeft: 3 }} size="small">
                        select option
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="Job"
                            label="Job"
                            name="Job"
                            size='small'
                            margin='normal'
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem key='job' value='job'>Job</MenuItem>
                            <MenuItem key='candidate' value='candidate'>Candidate</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* {
                    showJob === true ?
                        <>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 300, marginTop: 15, marginLeft: 2 }} size="small">
                                    <label>Assign</label>
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onSelect={(e) => { handleSelectStatus(e) }}
                                        onRemove={(e, value) => { handleRemoveStatus(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        options={jobAssign?.data?.data.map((el) => {
                                            return { key: el.displayText.status, value: el.metaDataId }
                                        })}

                                        //   onChange={(e) => handleAssign(e)}
                                        selectionLimit={1}
                                        placeholder='Select Status'
                                        hidePlaceholder={true}
                                        style={{
                                            multiselectContainer: {
                                                zIndex: 888,
                                                "white-space": "nowrap",

                                            },
                                            searchBox: {
                                                margin: '10px 10px 0 0',
                                                width: '250px',
                                                height: '55px',
                                                zIndex: 999,
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 300, marginTop: 15, marginLeft: 1 }} size="small">
                                    <label> Select Role</label>
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onSelect={(e) => { handleSelectRole(e) }}
                                        onRemove={(e, value) => { handleRemoveRole(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        options={roles?.data?.data?.map((el) => { return { key: el.roleName, value: el.roleId } })}
                                        selectedValues={selectedColumns}
                                        onChange={(e) => setSelectedColumns(e)}
                                        selectionLimit={1}
                                        placeholder=' Select Role'
                                        hidePlaceholder={true}
                                        style={{
                                            multiselectContainer: {
                                                zIndex: 888,
                                                "white-space": "nowrap",

                                            },
                                            searchBox: {
                                                // border: 'none',
                                                // 'border-radius': '0px',
                                                margin: '10px 10px 0 0',
                                                width: '250px',
                                                height: '55px',
                                                zIndex: 999,
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 300, marginTop: 15, marginLeft: 1 }} size="small">
                                    <label> Select User</label>
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onSelect={(e) => { handleSelectUser(e) }}
                                        onRemove={(e, value) => { handleRemoveUser(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        options={appUsers?.data?.data?.map((el) => { return { key: el.displayName, value: el.userId } })}
                                        selectedValues={selectedTemplateUserId?.flat()?.map((el) => { return { key: el?.key, value: el?.value } })}
                                        placeholder='Select User'
                                        hidePlaceholder={true}
                                        style={{
                                            multiselectContainer: {
                                                zIndex: 888,
                                                "white-space": "nowrap",
                                            },
                                            searchBox: {
                                                margin: '10px 10px 0 0',
                                                width: '250px',
                                                height: '55px',
                                                zIndex: 999,
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 150, marginTop: 14, marginLeft: 1 }} size="small">
                                    Duration
                                    <TextField
                                        required
                                        id="Duration"
                                        name="duration"
                                        type='number'
                                        InputProps={{ inputProps: { min: '0' } }}
                                        variant="filled"
                                        defaultValue={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                        margin='normal'
                                        multiline fullWidth

                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <Button
                                    variant="contained"
                                    sx={{  marginTop: 19, marginLeft: 3, padding:1}}
                                    size='small'
                                    type="submit"
                                    onClick={(e) => handleSetDuration(e)
                                    }
                                > Set Duration </Button>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl sx={{ marginTop: 19, marginLeft: 2 }}  >
                                    <Button variant="contained" onClick={(e) => { reset() }}>Reset</Button>
                                </FormControl>
                            </Grid>
                        </> : <></>
                } */}
                {/* {
                    showCandidate === true ? */}
                        {/* <> */}
                       
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 300, marginTop: 15, marginLeft: 4 }} size="small">
                                    <label>Select Status</label>
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onSelect={(e) => { handleSelectStatus(e) }}
                                        onRemove={(e, value) => { handleRemoveStatus(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        options={candidateStatuses?.data?.data?.map((el) => {
                                            return { key: el.displayText.status, value: el.metaDataId }
                                        })}

                                        //   onChange={(e) => handleChange(e)}
                                        selectionLimit={1}
                                        placeholder='Select Status'
                                        hidePlaceholder={true}
                                        style={{
                                            multiselectContainer: {
                                                zIndex: 888,
                                                "white-space": "nowrap",

                                            },
                                            searchBox: {
                                                margin: '10px 10px 0 0',
                                                width: '250px',
                                                height: '55px',
                                                zIndex: 999,
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />
                                </FormControl>
                            </Grid>
                           
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 300, marginTop: 15, marginLeft: 1 }} size="small">
                                    <label> Select Role</label>
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onSelect={(e) => { handleSelectRole(e) }}
                                        onRemove={(e, value) => { handleRemoveRole(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        options={roles?.data?.data?.map((el) => { return { key: el.roleName, value: el.roleId } })}
                                        selectedValues={selectedColumns}
                                        onChange={(e) => setSelectedColumns(e)}
                                        // selectionLimit={1}
                                        placeholder=' Select Role'
                                        hidePlaceholder={true}
                                        style={{
                                            multiselectContainer: {
                                                zIndex: 888,
                                                "white-space": "nowrap",

                                            },
                                            searchBox: {                                
                                                margin: '10px 10px 0 0',
                                                width: '250px',
                                                height: '55px',
                                                zIndex: 999,
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 150, marginTop: 14, marginLeft: 2 }} size='small'>
                                    Duration
                                    <TextField
                                        required
                                        id="Duration"
                                        name="duration"
                                        type='number'
                                        InputProps={{ inputProps: { min: '0' } }}
                                        variant="filled"
                                        defaultValue={duration}
                                        disabled={selectedStatus==='7bfcc09d-7014-47bd-8bda-984081ddd991' || selectedStatus==='a5392787-669d-43cd-ba75-773c1a8ddc02'}
                                        onChange={(e) => setDuration(e.target.value)}
                                        margin='normal'
                                        multiline fullWidth

                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <Button
                                    variant="contained"

                                    sx={{ marginTop: 19, marginLeft: 3, padding: 1, borderRadius: 3 }}
                                    size='small'
                                    type="submit"
                                    disabled={selectedStatus==='7bfcc09d-7014-47bd-8bda-984081ddd991' || selectedStatus==='a5392787-669d-43cd-ba75-773c1a8ddc02'}
                                    onClick={(e) => handleSetDuration(e)
                                    }
                                > Set Duration </Button>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl sx={{ marginTop: 19, marginLeft: 3,borderRadius: 3}}  >
                                    <Button variant="contained" onClick={(e) => { reset() }}>Reset</Button>
                                </FormControl>
                            </Grid>
                            
                            
                        {/* </> : <></> */}
                {/* } */}
            </Grid>

            <div className='remainder-block'>
                <div className="row-1">
                    <div className='row2-inline'>
                        <div className='textArea'>
                            {
                                (selectedColumns && emailTemplates.isSuccess) && selectedColumns.map((column) => {
                                    if (emailTemplates?.data?.data) {

                                        let template;
                                        if (column.value) {
                                            template = emailTemplates?.data?.data?.filter((template) => template.roleId === column.value);
                                        }
                                        {/* else {
                                            template = emailTemplates?.data?.data.filter((template) => template.roleId === null);
                                        } */}

                                        if (template?.length > 0) {
                                            return (<>
                                                <h2>{column.key}</h2>
                                                <TextEditor template={template[0]} label={column.key} />
                                            </>)
                                        }
                                        else {
                                            if (column) {
                                                let data = {
                                                    status: selectedStatus,
                                                    role: column?.value ? column.value : null,
                                                    templateType: 'isReminder'
                                                }
                                                if (templateUserId.length > 0) {
                                                    data.userId = templateUserId;

                                                }
                                                return (
                                                    <>
                                                        <h3 style={{ margin: '1% 0% 0% 0%' }}>{column?.key}</h3>
                                                        <TextEditor templateData={data} settemplateUserId={templateUserId} label={column?.key} />
                                                    </>
                                                )
                                            }
                                        }
                                    }
                                }
                                )
                            }
                        </div>
                        <div className='placeholders'>
              <label style={{ fontSize: '14px' }}>Placeholders</label>
              <table style={{ marginTop: '10px', border: "1px solid", width: 'auto', fontSize: '14px', background: 'rgb(252, 255, 102)' }}>


                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Name</td>
                  <td style={{ border: "1px solid" }}>{`{candidateName}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Email</td>
                  <td style={{ border: "1px solid" }}>{`{candidateEmail}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Designation</td>
                  <td style={{ border: "1px solid" }}>{`{jobTitle}`}</td>
                </tr>


                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate offer  Date</td>
                  <td style={{ border: "1px solid" }}>{`{offerDate}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate offerCTC</td>
                  <td style={{ border: "1px solid" }}>{`{offerCTC}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Joining Date</td>
                  <td style={{ border: "1px solid" }}>{`{joiningDate}`}</td>
                </tr>


                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Selected/ Rejected Date</td>
                  <td style={{ border: "1px solid" }}>{`{selectedRejectedDate}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Onboarding Candidate Designation</td>
                  <td style={{ border: "1px solid" }}>{`{joiningDetails.offeredDesgination}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Reporting Manager</td>
                  <td style={{ border: "1px solid" }}>{`{reportingManagerName}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Hiring Manager</td>
                  <td style={{ border: "1px solid" }}>{`{hiringManager}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Tentative DOJ</td>
                  <td style={{ border: "1px solid" }}>{`{tentativeDateOfJoining}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Recruiter</td> 
                  <td style={{ border: "1px solid" }}>{`{Recruiter}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Department</td>
                  <td style={{ border: "1px solid" }}>{`{department}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Cost Center</td>
                  <td style={{ border: "1px solid" }}>{`{costCenter}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Division</td>
                  <td style={{ border: "1px solid" }}>{`{division}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Device</td>
                  <td style={{ border: "1px solid" }}>{`{joiningDetails.device}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Simcard</td>
                  <td style={{ border: "1px solid" }}>{`{joiningDetails.simcard}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Ceipal Access</td>
                  <td style={{ border: "1px solid" }}>{`{joiningDetails.ceipalAccess}`}</td>
                </tr>

                <tr style={{ border: "1px solid" }}>
                  <td style={{ border: "1px solid" }}>Candidate Zoom Access</td>
                  <td style={{ border: "1px solid" }}>{`{joiningDetails.zoomAccess}`}</td>
                </tr>

              </table>
            </div>
                    </div>


                </div >
            </div >


        </Box>

    )
}
