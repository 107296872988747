import React, { useState, useRef, useEffect } from 'react';

import { useCandidates } from '../../helpers/hooks/candidatesHooks';

//!--------------------MUI------------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useOfferLetter } from '../../helpers/hooks/adminHooks';
import SpinLoader from '../SpinLoader/SpinLoader';
import { useSalary } from '../../helpers/hooks/salaryViewHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';

const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
};


const SalaryView = ({ close, candidateId, pageType }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const ref = useRef(null);
    const { generateOfferLetter } = useOfferLetter();
    const { useCandidate } = useCandidates();
    const { sendOfferletterviaEmail } = useSalary();
    let candidate = useCandidate(candidateId);
    const [form, setForm] = useState();
    const [open, setOpen] = useState(true);
    const [editValues, setEditValues] = useState(true);
    const { annexureFormulae, variablePayDuration, bonusPayDuration } = useMetaData();
    const [showOfferLetterButton, setShowOfferLetterButton] = useState(false);

    const handleValues = (e) => {

        if (editValues === true) {
            setEditValues(false);

        } else {
            setEditValues(true);
        }
    }

    const handleClose = () => {
        if (typeof close === 'function') {
            close(); // Call the close function passed as a prop
        }
    };

    const handlePreviewOfferLetter = (e) => {

        if (e.target.name === 'preview') {
            generateOfferLetter.mutate(form,
                {
                    onSuccess: (data) => {
                        setForm({ ...form, fileData: data.data })
                        var blob = base64toBlob(data.data)
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, "_blank");
                        setShowOfferLetterButton(true);
                    }
                },
                {
                    onError: (data) => {
                        alert('error')
                    }
                });
        }
    }

    const handleSendOfferLetter = () => {

        if (candidate?.data?.data?.offerLetter === true) {
            alert('Sending New Offer Letter to Candidate')
        }
        sendOfferletterviaEmail.mutate({ id: candidateId, formData: form },
            {
                onSuccess: (data) => {
                    window.location.reload(false);
                }
            },
            {
                onError: (data) => {
                    alert('error')
                }
            }
        )
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    let values = {};

    const jsonData = annexureFormulae?.data?.data?.map((el) => el.displayText)
    let caluculatedValues = {};
    const createFormula = (jsonData) => {
        const keys = Object.keys(caluculatedValues);
        const id = jsonData.id;
        let value1 = jsonData.value1;
        let value2 = jsonData.value2;
        let value3 = jsonData.value3;
        let value4 = jsonData.value4;
        let value5 = jsonData.value5;
        let value6 = jsonData.value6;

        const operator1 = jsonData.Operator1;
        const operator2 = jsonData.Operator2;
        const operator3 = jsonData.Operator3;
        const operator4 = jsonData.Operator4;
        const operator5 = jsonData.Operator5;
        const formula = jsonData.Formula



        if (keys.length && keys.length > 0) {
            if (typeof value1 === 'string') {
                let index = keys.findIndex((key) => key === value1)
                if (index !== -1) {
                    value1 = caluculatedValues[keys[index]]
                }
            }

            if (typeof value2 === 'string') {
                let index = keys.findIndex((key) => key === value2)
                if (index !== -1) {
                    value2 = caluculatedValues[keys[index]]
                }
            }

            if (typeof value3 === 'string') {
                let index = keys.findIndex((key) => key === value3)
                if (index !== -1) {
                    value3 = caluculatedValues[keys[index]]
                }
            }

            if (typeof value4 === 'string') {
                let index = keys.findIndex((key) => key === value4)
                if (index !== -1) {
                    value4 = caluculatedValues[keys[index]]
                }
            }

            if (typeof value5 === 'string') {
                let index = keys.findIndex((key) => key === value5)
                if (index !== -1) {
                    value5 = caluculatedValues[keys[index]]
                }
            }

            if (typeof value6 === 'string') {
                let index = keys.findIndex((key) => key === value6)
                if (index !== -1) {
                    value6 = caluculatedValues[keys[index]]
                }
            }
        }

        if (jsonData.condition) {
            const trueCase = jsonData.true;
            const condition = jsonData.condition;
            const falseCase = jsonData.false;


            if (eval(condition)) {
                caluculatedValues[formula] = Math.round(Number(eval(trueCase)))
            } else {
                caluculatedValues[formula] = Math.round(Number(eval(falseCase)))
            }

        }

        else if (jsonData.condition1) {
            const condition1 = jsonData.condition1;
            const condition2 = jsonData.condition2;
            const condition3 = jsonData.condition3;
            const falseCase = jsonData.false;


            if (eval(condition1)) {
                const trueCase = jsonData.true1;
                const falseCase = jsonData.false;
                console.log('================================')
                console.log(condition1)
                console.log(trueCase)
                console.log(falseCase)
                // caluculatedValues[formula] = Math.round(Number(eval(trueCase)))

                // Split the formula into parts
                const parts = trueCase.split(/\b(\w+)\b/);

                // Replace placeholders with actual values
                const substitutedFormula = parts
                    .map(part => {
                        if (part === "value1") return value1;
                        if (part === "value2") return value2;
                        return part;
                    })
                    .join('');

                // Evaluate the substituted formula
                const result = Math.round(eval(substitutedFormula));

                // Store the result in the calculatedValues object
                console.log(result)
                caluculatedValues[formula] = result;


            }


            else if (eval(condition2)) {
                const trueCase = jsonData.true2;
                const falseCase = jsonData.false;
                console.log('================================')
                console.log(condition2)
                console.log(trueCase)
                console.log(falseCase)
                const parts = trueCase.split(/\b(\w+)\b/);

                // Replace placeholders with actual values
                const substitutedFormula = parts
                    .map(part => {
                        if (part === "value1") return value1;
                        if (part === "value2") return value2;
                        return part;
                    })
                    .join('');

                // Evaluate the substituted formula
                const result = Math.round(eval(substitutedFormula));

                // Store the result in the calculatedValues object
                caluculatedValues[formula] = result;



            }


            else if (eval(condition3)) {
                const trueCase = jsonData.true3;
                const falseCase = jsonData.false;

                console.log('================================')
                console.log(condition3)
                console.log(trueCase)
                console.log(falseCase)
                const parts = trueCase.split(/\b(\w+)\b/);

                // Replace placeholders with actual values
                const substitutedFormula = parts
                    .map(part => {
                        if (part === "value1") return value1;
                        if (part === "value2") return value2;
                        return part;
                    })
                    .join('');

                // Evaluate the substituted formula
                const result = Math.round(eval(substitutedFormula));

                // Store the result in the calculatedValues object
                caluculatedValues[formula] = result;

            }

            else {
                caluculatedValues[formula] = Math.round(Number(eval(falseCase)))
                // caluculatedValues[formula] = Math.round(Number(eval(value1 + operator1 + value2)))
            }
        }

        else if (value6) {
            caluculatedValues[formula] = Math.round(Number(eval(value1 + operator1 + value2 + operator2 + value3 + operator3 + value4 + operator4 + value5 + operator5 + value6)))
        }

        else if (candidate?.data?.data?.variablePayOption === true && value5) {
            caluculatedValues[formula] = Math.round(Number(eval(value1 + operator1 + value2 + operator2 + value3 + operator3 + value4 + operator4 + value5)))
        }
        else if (value4) {
            caluculatedValues[formula] = Math.round(Number(eval(value1 + operator1 + value2 + operator2 + value3 + operator3 + value4)))
        } else if (value3) {
            caluculatedValues[formula] = Math.round(Number(eval(value1 + operator1 + value2 + operator2 + value3)))
        }
        else if (value2) {
            caluculatedValues[formula] = Math.round(Number(eval(value1 + operator1 + value2)))
        }
        else {
            caluculatedValues[formula] = Math.round(Number(eval(value1)))
        }
    };

    const formulas = jsonData?.sort((a, b) => a.id - b.id).forEach(createFormula);

    //list of variables

    values.BasicYearlyWithPF = caluculatedValues.basicYearly;
    values.BasicMonthlyWithPF = caluculatedValues.basicMonthly;

    values.HRAYearlyWithPF = caluculatedValues.HRAYearlyWithPF;
    values.HRAMontlyWithPF = caluculatedValues.HRAMontlyWithPF;


    values.EmployeerContributionMontlyToPF1 = caluculatedValues.EmployeerContributionMontlyToPF1
    values.EmployeerContributionYearlyToPF1 = caluculatedValues.EmployeerContributionYearlyToPF1


    values.OtherAllowanceYearlyWithPF = caluculatedValues.OtherAllowanceYearlyWithPF
    values.OtherAllowanceMonthlyWithPF = caluculatedValues.OtherAllowanceMonthlyWithPF


    values.GrossSalaryYearlyWithPF = caluculatedValues.GrossSalaryYearlyWithPF
    values.GrossSalaryMonthlyWithPF = caluculatedValues.GrossSalaryMonthlyWithPF


    values.EmployeerContributionMontlyToPF2 = caluculatedValues.EmployeerContributionMontlyToPF2
    values.EmployeerContributionYearlyToPF2 = caluculatedValues.EmployeerContributionYearlyToPF2
    values.EmployeerContributionMontlyToPF3 = caluculatedValues.EmployeerContributionMontlyToPF3
    values.EmployeerContributionYearlyToPF3 = caluculatedValues.EmployeerContributionYearlyToPF3


    values.NetPayBeforeTaxesYearly = caluculatedValues.NetPayBeforeTaxesYearly
    values.NetPayBeforeTaxesMonthly = caluculatedValues.NetPayBeforeTaxesMonthly


    if (candidate?.data?.data?.variablePay !== null && candidate?.data?.data?.variablePay > 0) {
        values.variablePayYearly = caluculatedValues.variablePayYearly
        values.variablePayMontly = caluculatedValues.variablePayMontly
    }

    if (candidate?.data?.data?.bonusPay !== null && candidate?.data?.data?.bonusPay > 0) {
        values.bonusPayYearly = caluculatedValues.bonusPayYearly
        values.bonusPayMontly = caluculatedValues.bonusPayMontly
    }

    values.TotalCTCYearly = caluculatedValues.TotalCTCYearly
    values.TotalCTCMontly = caluculatedValues.TotalCTCMontly

    console.log(candidate?.data?.data?.variablePayOption);
    console.log('******************************************************************');
    console.log(values.BasicYearlyWithPF);
    console.log(values.HRAYearlyWithPF);
    console.log(values.EmployeerContributionYearlyToPF1);
    console.log(values.OtherAllowanceYearlyWithPF);
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log(values.variablePayYearly);

    console.log(values.BasicYearlyWithPF + values.HRAYearlyWithPF + values.EmployeerContributionYearlyToPF1 + values.OtherAllowanceYearlyWithPF - values.variablePayYearly)
    console.log(values.GrossSalaryYearlyWithPF)



    useEffect(() => {
        setForm({
            ...form,
            candidateId: candidate?.data?.data?.candidateId,
            values: values
        })
    }, [candidate?.data?.data?.candidateId])


    if (generateOfferLetter.isLoading || candidate.isLoading || candidate.isFetching || annexureFormulae.isFetching || annexureFormulae.isLoading ||
        variablePayDuration.isFetching || variablePayDuration.isLoading || bonusPayDuration.isFetching || bonusPayDuration.isLoading) {
        return <SpinLoader />
    }


    return (
        <>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='xxl'>
                    <DialogContent>
                        <h2 style={{
                            padding: '5px', backgroundColor: "cornflowerblue",
                            fontWeight: 700, fontSize: "20px",
                        }}> Employee Salary (With PF)</h2>
                        <form
                            onSubmit={(e) => handleValues(e)}
                        >
                            <Box sx={{ background: 'white', width: 'auto', display: 'flex', flexDirection: 'column' }}>
                                <TableContainer ref={ref} sx={{ width: '100%', overflow: 'auto' }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead style={{ backgroundColor: "#243c80", background: "white" }}>


                                            <TableRow
                                                sx={{
                                                    "& th": {
                                                        borderBottom: "2px solid black",
                                                        fontSize: "0.9rem",
                                                        color: "white",
                                                        backgroundColor: "#243c80",
                                                        borderLeft: "1px solid #3b4864",
                                                        height: '1px'
                                                    }
                                                }}
                                            >

                                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Earnings</TableCell>
                                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Monthly (INR)</TableCell>
                                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Yearly (INR)</TableCell>
                                                <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableRow hover>
                                            <TableCell>Basic + DA</TableCell>
                                            <TableCell>{values.BasicMonthlyWithPF.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.BasicYearlyWithPF.toLocaleString("en-IN")}
                                                {/* <Input name='BasicYearlyWithPF' defaultValue={values.BasicYearlyWithPF} disabled={editValues} /> */}
                                            </TableCell>

                                        </TableRow>

                                        <TableRow hover>
                                            <TableCell>HRA</TableCell>
                                            <TableCell>{values.HRAMontlyWithPF.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.HRAYearlyWithPF.toLocaleString("en-IN")}
                                                {/* <Input name='HRAMontlyWithPF' defaultValue={values.HRAYearlyWithPF} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>



                                        <TableRow hover>
                                            <TableCell>Employer Contribution to PF</TableCell>
                                            <TableCell>{values.EmployeerContributionMontlyToPF1.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.EmployeerContributionYearlyToPF1.toLocaleString("en-IN")}
                                                {/* <Input name='EmployeerContributionYearlyToPF1' defaultValue={values.EmployeerContributionYearlyToPF1} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow hover>
                                            <TableCell>Other  Allowance</TableCell>
                                            <TableCell>{values.OtherAllowanceMonthlyWithPF.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.OtherAllowanceYearlyWithPF.toLocaleString("en-IN")}
                                                {/* <Input name='OtherAllowanceYearlyWithPF' defaultValue={values.OtherAllowanceYearlyWithPF} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Gross Salary</TableCell>
                                            <TableCell>{values.GrossSalaryMonthlyWithPF.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.GrossSalaryYearlyWithPF.toLocaleString("en-IN")}
                                                {/* <Input name='GrossSalaryYearlyWithPF' defaultValue={values.GrossSalaryYearlyWithPF} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>


                                        <TableRow hover>
                                            <TableCell> <b> Deductions </b></TableCell>
                                        </TableRow>


                                        <TableRow hover>
                                            <TableCell>Employee Contribution to PF</TableCell>
                                            <TableCell>{values.EmployeerContributionMontlyToPF2.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.EmployeerContributionYearlyToPF2.toLocaleString("en-IN")}
                                                {/* <Input name='EmployeerContributionYearlyToPF2' defaultValue={values.EmployeerContributionYearlyToPF2} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                            <TableCell>Employee Contribution to PF</TableCell>
                                            <TableCell>{values.EmployeerContributionMontlyToPF3.toLocaleString("en-IN")}</TableCell>
                                            <TableCell>
                                                {values.EmployeerContributionYearlyToPF3.toLocaleString("en-IN")}
                                                {/* <Input name='EmployeerContributionYearlyToPF3' defaultValue={values.EmployeerContributionYearlyToPF3} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>


                                        <TableRow hover>
                                            <TableCell style={{ fontWeight: 'bold' }}>Net Pay before taxes</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>{values.NetPayBeforeTaxesMonthly.toLocaleString("en-IN")}</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                {values.NetPayBeforeTaxesYearly.toLocaleString("en-IN")}
                                                {/* <Input name='NetPayBeforeTaxesYearly' defaultValue={values.NetPayBeforeTaxesYearly} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>

                                        {candidate?.data?.data?.variablePayOption !== false && candidate?.data?.data?.bonusPayOption !== null && candidate?.data?.data?.candidateType === false &&

                                            <>

                                                <TableRow hover>
                                                    <TableCell>Variable Pay</TableCell>
                                                    <TableCell></TableCell>
                                                    {/* <TableCell>{values.variablePayMontly}</TableCell> */}
                                                    <TableCell>
                                                        {values.variablePayYearly.toLocaleString("en-IN")}
                                                        {/* <Input name='variablePayYearly' defaultValue={values.variablePayYearly} disabled={editValues} /> */}
                                                    </TableCell>
                                                </TableRow>

                                            </>
                                        }



                                        <TableRow hover>
                                            <TableCell style={{ fontWeight: 'bold' }}>Total CTC</TableCell>
                                            <TableCell></TableCell>

                                            {/* <TableCell>{values.TotalCTCMontly.toLocaleString("en-IN")}</TableCell> */}
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                {values.TotalCTCYearly.toLocaleString("en-IN")}
                                                {/* <Input name='TotalCTCYearly' defaultValue={values.TotalCTCYearly} disabled={editValues} /> */}
                                            </TableCell>
                                        </TableRow>




                                        {candidate?.data?.data?.bonusPayOption !== false && candidate?.data?.data?.bonusPayOption !== null && candidate?.data?.data?.candidateType === false &&

                                            <>

                                                <TableRow hover>
                                                    <TableCell>
                                                        <b> Bonus Pay &nbsp;Rs {values.bonusPayYearly.toLocaleString("en-IN")} this will be paid upon your successful completion of  {candidate?.data?.data?.bonusPayDuration?.displayText.bonusPay}
                                                        </b>
                                                    </TableCell>

                                                    {/* <Input name='bonusPayYearly' defaultValue={values.bonusPayYearly} disabled={editValues} /> */}
                                                </TableRow>

                                            </>
                                        }


                                        <TableRow hover>
                                            <TableCell> <b> "*Monthly salary to be disbursed post deduction of taxes subject to the prevailing tax laws" </b></TableCell>
                                        </TableRow>


                                        <TableRow>
                                            <TableCell>
                                                <Button variant="contained" role="link" name='preview' onClick={(e) => handlePreviewOfferLetter(e)}> Preview </Button>
                                            </TableCell>

                                            {pageType === 'editable' &&
                                                <>
                                                    {showOfferLetterButton === true &&
                                                        <>

                                                            <TableCell>
                                                                <Button variant="contained" role="link" onClick={() => handleSendOfferLetter()}> Send </Button>
                                                            </TableCell>
                                                        </>

                                                    }

                                                </>
                                            }

                                            <TableCell>
                                                <Button variant="contained" onClick={() => handleClose()}> Close </Button>

                                            </TableCell>

                                        </TableRow>
                                    </Table>
                                </TableContainer>


                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </>

    )
}

export default SalaryView;