import React, { useState, useEffect } from 'react';
import './AddOrUpdateInterview.css';
import CloseIcon from '@mui/icons-material/Close';
import SpinLoader from '../SpinLoader/SpinLoader';
import { useInterviews } from '../../helpers/hooks/interviewsHooks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//!::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import moment from 'moment';
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
const AddOrUpdateInterview = ({ setIsModalOpen, setEditMode, isModalOpen, candidate, editMode, interview, interviewRound, interviewStatuses }) => {
    const [form, setForm] = useState({});
    const theme = useTheme();
    const [statuses, setStatuses] = useState(interviewStatuses?.data);
    const [showFinalSelected, setShowFinalSelected] = useState(false);
    const { shiftTimings, devices, jobLocations, disqualifiedReasons } = useMetaData();
    const { scheduleInterview, updateInterview, panelFeedbackMail } = useInterviews();
    const [interviewBody, setInterviewBody] = useState(interview?.interview_body);
    const [panelFeedback, setPanelFeedback] = useState(interview?.panelFeedback);
    const [filters, setFilter] = useState({});
    const [selectedtime, setselectedTime] = React.useState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = useState();
    const [open, setOpen] = useState(true);
    const [checked, setChecked] = React.useState(interview?.isFinalSelected === '1' ? true : false);
    const [showTentativeDOJ, setshowTentativeDOJ] = useState(false);
    const [joiningDetails, setJoiningDetails] = useState(candidate?.joiningDetails);
    const [offlineModeLocation, setofflineModeLocation] = useState(null)
    const [interviewMode, setinterviewMode] = useState()
    const [showCancelledReason, setShowCancelledReason] = useState(false)
    const [showRejectedReason, setShowRejectedReason] = useState(false)
    const [showDisqualifiedReason, setShowDisqualifiedReason] = useState(false)


    useEffect(() => {
        if (!editMode) {
            setForm({ jobId: candidate?.jobTitle?.jobId, interviewRound: interviewRound + 1, interviewStatus: 'Scheduled' });
        } else {
            setShowFinalSelected(interview?.interviewStatus === 'Selected');
        }

        if (interviewStatuses?.data) {
            if (!editMode) {
                setStatuses(interviewStatuses?.data?.filter((status) => status?.displayText.status === 'Scheduled'));
            }
        }

        if (scheduleInterview.isSuccess || updateInterview.isSuccess) {
            setEditMode(false);
            setIsModalOpen(false);
        }
    }, [isModalOpen, editMode, candidate, scheduleInterview.isSuccess, updateInterview.isSuccess, interviewStatuses?.data]);

    if (scheduleInterview.isLoading || updateInterview.isLoading) {
        return <SpinLoader />
    }

    function reset() {
        document.getElementById('interview_start_time').value = '';
        document.getElementById('interview_date').value = '';
        document.getElementById('interview_duration').value = '';
        document.getElementById('interview_body').value = '';
    }
    const setFeedbackMail = (e) => {
        form.interview_id = interview.interviewId;
        form.panelEmail = interview.panelEmail;
        form.candidate_id = candidate.candidateId;
        form.feedbackMail = 'feedbackMailSent'
        console.log('form-------', form);
        panelFeedbackMail.mutate({ formData: form, candidate_id: candidate.candidateId, interview_id: interview.interviewId },
            {
                onSuccess: (data) => {
                    alert('FeedBack Mail Sent');
                    setEditMode(false);
                    setIsModalOpen(false)
                    // window.location.reload(false);
                }
            },
            {
                onError: (data) => {
                    alert('error')
                }
            }
        );
    }
    const handleChange = (e) => {
        if (e.target.name === 'isFinalSelected') {
            setChecked(e.target.checked);
            e.target.value = e.target.checked;
            console.log(e.target.checked);
            if (e.target.checked === true) {
                setshowTentativeDOJ(true);
            } else {
                setshowTentativeDOJ(false);
            }
        }
        if (e.target.name === 'interviewStatus' && e.target.value === "Cancelled") {
            setShowCancelledReason(true);
            setShowRejectedReason(false);
            setShowDisqualifiedReason(false);
        } else if (e.target.name === 'interviewStatus' && e.target.value === "Rejected") {
            setShowRejectedReason(true);
            setShowCancelledReason(false);
            setShowDisqualifiedReason(false);
        } else if (e.target.name === 'interviewStatus' && e.target.value === "Disqualified") {
            setShowDisqualifiedReason(true);
            setShowRejectedReason(false);
            setShowCancelledReason(false);
        } else if (e.target.name === 'interviewStatus' && e.target.value === "Selected") {
            setShowRejectedReason(false);
            setShowCancelledReason(false);
            setShowDisqualifiedReason(false);
        }
        if (e.target.name === 'interviewStatus' && e.target.value === 'Selected') {
            setShowFinalSelected(true);
            setShowDisqualifiedReason(false);
            setShowRejectedReason(false);
            setShowCancelledReason(false);
        }
        if (e.target.name === 'interviewStatus' && e.target.value === 'Scheduled') {
            setShowFinalSelected(false);
            setShowDisqualifiedReason(false);
            setShowRejectedReason(false);
            setShowCancelledReason(false);
        }
        if (e.target.name === 'interviewStatus' && e.target.value === 'Rejected') {
            setShowFinalSelected(false);
        }
        if (e.target.name === 'interviewMode') {
            setofflineModeLocation(e.target.value);
        }
        if (e.target.name === 'interviewLocationId') {
            let location = jobLocations.data.data.filter(el => el.metaDataId === e.target.value).map(ele => ele.displayText.location)

            setinterviewMode(location[0])

        }

        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        form.joiningDetails = joiningDetails;
        form.interviewLocations = interviewMode
        setForm({
            ...form,
            interviewDate: moment(filters?.interviewDate?.['$d']).format('YYYY-MM-DD'),
            interviewStartTime: filters?.interviewStartTime?.['$d'].format("YYYY-MM-DDTHH:mm:ss")
        })
        if (interviewBody && !editMode) form.interviewBody = interviewBody;
        if (panelFeedback) form.panelFeedback = panelFeedback;

        if ((interviewBody !== interview?.interviewBody) && editMode) form.interviewBody = interviewBody;
        if (editMode) {
            updateInterview.mutate({ formData: form, candidate_id: candidate.candidateId, interview_id: interview.interviewId },
                {
                    onSuccess: (data) => {
                        alert('success');
                        setEditMode(false);
                        setIsModalOpen(false)
                        window.location.reload(false);
                    }
                },
                {
                    onError: (data) => {
                        alert('error')
                    }
                }
            );

        } else {
            scheduleInterview.mutate({ formData: form, candidate_id: candidate.candidateId },
                {
                    onSuccess: (data) => {
                        alert('success');
                        setEditMode(false);
                        setIsModalOpen(false)
                        window.location.reload(false);
                    }
                },
                {
                    onError: (data) => {
                        alert('error')
                    }
                });
        }

    }

    const handleClose = () => {
        setOpen(false)
    };

    let filteredStatuses;
    if (editMode === true) {
        filteredStatuses = interviewStatuses?.data?.filter(el => el.order !== 1)

    } else {
        if (interview?.interviewStatus != 'Scheduled') {
            filteredStatuses = interviewStatuses?.data?.filter(el => el.order !== 2 && el.order !== 3 && el.order !== 4 && el.order !== 5)
        }
    }
    return (
        <>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                aria-labelledby="responsive-dialog-title"
                maxWidth='xl'
            >
                <DialogContent>
                    <h2 style={{
                        padding: '5px', backgroundColor: "cornflowerblue",
                        fontWeight: 700, fontSize: "20px",
                    }}> Add Candidate Details </h2>
                    <hr />
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Box
                            sx={{ boxShadow: 2 }} >
                            <Grid container m={2} gap={5}>
                                <Grid Item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                        <h3>Candidate Name</h3>
                                        <TextField
                                            labelId="input-candidate-name-label"
                                            id="outlined-required"
                                            label="Candidate Name"
                                            placeholder='Enter Candidate Name'
                                            size='small'
                                            margin='normal'
                                            name='candidateName'
                                            variant="filled"
                                            readOnly
                                            value={candidate?.candidateName}
                                            onChange={(e) => handleChange(e)} />
                                    </FormControl>
                                </Grid>
                                <Grid Item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                        <h3>Candidate Email</h3>
                                        <TextField
                                            labelId="input-candidate-email-label"
                                            id="outlined-required"
                                            label="Candidate Email"
                                            placeholder='Enter Job Title'
                                            size='small'
                                            margin='normal'
                                            name='candidateEmail'
                                            readOnly
                                            type="email"
                                            value={candidate?.candidateEmail}
                                            variant="filled"
                                            onChange={(e) => handleChange(e)} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small" >
                                        <h3>Job Title</h3>

                                        <TextField
                                            labelId="select-job-title-label"
                                            id="jobTitle"
                                            name='jobTitle'
                                            variant="filled"
                                            size='small'
                                            margin='normal'
                                            value={candidate?.jobTitle?.jobTitle ? candidate?.jobTitle?.jobTitle : candidate?.ceipalJob?.jobTitle}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs='auto'>
                                    <h3>Interview Round</h3>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small" >
                                        <TextField
                                            labelId='interview_round'
                                            id='interviewRound'
                                            name='interviewRound'
                                            variant="filled"
                                            size='small'
                                            margin='normal'
                                            value={interview?.interviewRound ? interview?.interviewRound : interviewRound + 1}
                                            readOnly
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs='auto'>
                                    <h3>Interview Duration</h3>
                                    <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                                        <Select
                                            labelId='interviewDuration'
                                            id='interviewDuration'
                                            name="interviewDuration"
                                            variant="filled"
                                            defaultValue={interview?.interviewDuration?.toString()}
                                            onChange={(e) => handleChange(e)}
                                            required
                                            margin='normal'
                                        >
                                            <MenuItem key="" value=""> Select Interview Duration</MenuItem>
                                            <MenuItem key="15" value="15"> 15 Minutes </MenuItem>
                                            <MenuItem key="30" value="30"> 30 Minutes</MenuItem>
                                            <MenuItem key="45" value="45"> 45 Minutes</MenuItem>
                                            <MenuItem key="60" value="60"> 60 Minutes</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container m={2} gap={5}>
                                <Grid item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small" >
                                        <h3>Time</h3>
                                        <input id='interviewStartTime'
                                            type="time"
                                            defaultValue={interview?.interviewStartTime}
                                            name="interviewStartTime"
                                            onChange={(e) => handleChange(e)}
                                            style={{ width: "250px", height: "50px", marginTop: "15px", }}
                                            required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250, mt: 2 }} size="small" >
                                        <h3>Interview Date</h3>
                                        <input
                                            style={{ width: 250, height: 50, fontSize: 15 }}
                                            id='interviewDate'
                                            type="date"
                                            name="interviewDate"
                                            required
                                            defaultValue={
                                                interview?.interviewDate
                                                    ? new Date(interview?.interviewDate).toISOString().split('T')[0]
                                                    : ''
                                            } onChange={(e) => handleChange(e)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid Item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                        <h3>Panel Email</h3>
                                        <TextField
                                            label='panelEmail'
                                            size='small'
                                            margin='normal'
                                            name='panelEmail'
                                            type='text'
                                            required
                                            defaultValue={interview?.panelEmail ? interview?.panelEmail : ''}
                                            value={form?.panelEmail}
                                            variant="filled"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs='auto'>
                                    <h3>Interview Status</h3>
                                    <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                                        <InputLabel id="select-job-title-label">Interview Status</InputLabel>
                                        <Select
                                            labelId='interview_status'
                                            id='interviewStatusId'
                                            name='interviewStatus'
                                            variant="filled"
                                            defaultValue={interview?.interviewStatus}
                                            required
                                            margin='normal'
                                            onChange={(e) => handleChange(e)}
                                        >

                                            {filteredStatuses && filteredStatuses?.map((status) =>

                                                <MenuItem key={status.metaDataId} value={status.displayText.status}>{status.displayText.status}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid Item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                        <h3>Cancelled Reason</h3>
                                        <TextField
                                            labelId="input-cancelledReason-label"
                                            id="outlined-required"
                                            label="Cancelled Reason"
                                            placeholder='Enter Cancelled Reason'
                                            size='small'
                                            margin='normal'
                                            name='cancelledReason'
                                            variant="filled"
                                            readOnly
                                            disabled={!showCancelledReason}
                                            defaultValue={interview?.cancelledReason}
                                            onChange={(e) => handleChange(e)} />
                                    </FormControl>
                                </Grid>

                                <Grid item xs='auto'>
                                    <h3>Disqualified Reason</h3>
                                    <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            id="disqualifiedReasonId"
                                            size='small'
                                            margin='normal'
                                            name='disqualifiedReasonId'
                                            defaultValue={candidate?.disqualifiedReasonId}
                                            variant="filled"
                                            onChange={(e) => handleChange(e)}
                                            disabled={!showDisqualifiedReason}
                                        >
                                            {disqualifiedReasons?.data && disqualifiedReasons?.data?.data?.map((disqualified) =>
                                                <MenuItem key={disqualified?.metaDataId} value={disqualified?.metaDataId}>{disqualified?.displayText?.disqualified}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs='auto'>
                                    <FormControl variant="standard" sx={{ width: 500 }} size="small">
                                        <h3>Rejected Reason</h3>
                                        <TextField
                                            required
                                            autocomplete="off"
                                            name='rejectedReason'
                                            labelId="input-candidate-address"
                                            id="outlined-required"
                                            label="Rejected Reason"
                                            placeholder='Enter Rejected Reason'
                                            size='large'
                                            margin='normal'
                                            defaultValue={candidate?.rejectedReason}
                                            variant="filled"
                                            disabled={!showRejectedReason}
                                            onChange={(e) => handleChange(e)} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs='auto'>

                                <h3>Panel Feedback</h3>
                                        <ReactQuill theme="snow"
                                            id="outlined-required"
                                            defaultValue={interview?.panelFeedback}
                                            name='panelFeedback'
                                            onChange={setPanelFeedback}
                                            placeholder='Enter Panel Feedback'
                                            style={{ height: '120px', width: "600px" }}
                                        />
                                </Grid>

                                <Grid item xs='auto'>
                                    <Button
                                        size="small"
                                        sx={{ mt: 5 }}

                                        variant="contained"
                                        color="primary"
                                        type='button'
                                        disabled={interview?.interviewStatus === 'Scheduled' ? false : true}
                                        onClick={(e) => {
                                            setFeedbackMail()
                                        }}
                                    >Feedback Mail Sent</Button>
                                </Grid>
                                <Grid Item xs='auto'>
                                    <h3>Interview Mode</h3>
                                    <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                                        <RadioGroup
                                            row
                                            required
                                            aria-labelledby="input-radio-buttons-group-label"
                                            name="interviewMode"
                                            variant="filled"
                                            defaultValue={interview?.interviewMode}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <FormControlLabel value="true" control={<Radio required={true} />} label="Online" />
                                            <FormControlLabel value="false" control={<Radio required={true} />} label="Offline" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid container m={2} gap={5}>
                                    {
                                        offlineModeLocation === 'false' || interview?.interviewMode === false ?
                                            <>
                                                <Grid item xs='auto'>
                                                    <FormControl variant="standard" sx={{ width: 350 }} mt={2} size="small">

                                                        <h3>Interview Location</h3>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="input-radio-buttons-group-job-location-label"
                                                            name="interviewLocationId"
                                                            variant="filled"
                                                            defaultValue={interview?.interviewLocationId}
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            {jobLocations?.data && jobLocations.data.data.map((location) =>
                                                                <>
                                                                    <FormControlLabel value={location.metaDataId} control={<Radio required={true} />} label={location.displayText.location} />
                                                                </>
                                                            )}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </Grid>

                                {showTentativeDOJ &&
                                    <>

                                        <Grid container m={2} gap={5}>

                                            <Grid Item xs='auto'>
                                                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                                                    <h3>Tentative Date of Joining</h3>
                                                    <input
                                                        required
                                                        style={{ width: 250, height: 50, fontSize: 15 }}
                                                        type="date"
                                                        id="tentativeDateOfJoining"
                                                        name="tentativeDateOfJoining"
                                                        defaultValue={
                                                            candidate?.tentativeDateOfJoining
                                                                ? new Date(candidate?.tentativeDateOfJoining).toISOString().split('T')[0]
                                                                : ''
                                                        }
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid Item xs='auto'>
                                                <FormControl variant="standard" sx={{ width: 250, marginTop: 1, }} size="small">
                                                    <h3>Reporting Manager Email</h3>
                                                    <TextField
                                                        required
                                                        id="reporting_manager_id"
                                                        placeholder='Enter Reporting Manager'
                                                        size='small'
                                                        margin='normal'
                                                        name='reportingManager'
                                                        variant="filled"
                                                        type="email"
                                                        defaultValue={candidate?.reportingManager}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid Item xs='auto'>

                                                <FormControl variant="standard" sx={{ width: 250, marginTop: 1, }} size="small">
                                                    <h3>Offered Designation</h3>
                                                    <TextField
                                                        required
                                                        id="outlined-required"
                                                        label="offered Designation"
                                                        variant="filled"
                                                        placeholder='Enter designation'
                                                        size='small'
                                                        margin='normal'
                                                        name='offeredDesignation'
                                                        defaultValue={candidate?.joiningDetails?.offeredDesignation}
                                                        onChange={(e) => { setJoiningDetails({ ...joiningDetails, offeredDesignation: e.target.value }) }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid Item xs='auto'>
                                                <h3>Devices</h3>
                                                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                                                    <InputLabel id="demo-simple-select-label">devices</InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="devices"
                                                        label="devices"
                                                        name="devices"
                                                        variant="filled"
                                                        margin='normal'
                                                        defaultValue={candidate?.joiningDetails?.devices}
                                                        onChange={(e) => { setJoiningDetails({ ...joiningDetails, devices: e.target.value }) }}
                                                    >
                                                        {devices?.data && devices.data.data.map(cc =>
                                                            <MenuItem key={cc.metaDataId} value={cc.displayText.device}>{cc.displayText.device}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid Item xs='auto'>
                                                <h3>Shift Timings</h3>
                                                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                                                    <InputLabel id="demo-simple-select-label">Shift Timings</InputLabel>
                                                    <Select
                                                        labelId="shift_Timings"
                                                        label="shift_Timings"
                                                        id="shiftTimings"
                                                        variant="filled"
                                                        name='shiftTimings'
                                                        required
                                                        defaultValue={candidate?.shiftTimings}
                                                        onChange={(e) => handleChange(e)}
                                                    >
                                                        {shiftTimings?.data && shiftTimings?.data?.data.map((shiftTime) => {
                                                            return <MenuItem key={shiftTime.metaDataId} value={shiftTime.metaDataId}>{shiftTime.displayText.shiftTime}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                        </Grid>

                                        <Grid container m={2} gap={5}>
                                            <Grid Item xs='auto'>
                                                <FormControl variant="standard" sx={{ width: 200, marginTop: 2, }} size="small">
                                                    <h3>Zoom Access</h3>
                                                    <RadioGroup
                                                        row
                                                        required
                                                        aria-labelledby="input-radio-buttons-group-fresher-label"
                                                        name="zoomAccess"
                                                        variant="filled"
                                                        defaultValue={candidate?.joiningDetails?.zoomAccess}
                                                        onChange={(e) => { setJoiningDetails({ ...joiningDetails, zoomAccess: e.target.value }) }}
                                                    >
                                                        <FormControlLabel value="yes" control={<Radio required={true} />} label="Yes" />
                                                        <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid Item xs='auto'>
                                                <FormControl variant="standard" sx={{ width: 200, marginTop: 2, }} size="small">
                                                    <h3>Ceipal Access</h3>
                                                    <RadioGroup
                                                        row
                                                        required
                                                        aria-labelledby="input-radio-buttons-group-fresher-label"
                                                        name="ceipalAccess"
                                                        variant="filled"
                                                        defaultValue={candidate?.joiningDetails?.ceipalAccess}
                                                        onChange={(e) => { setJoiningDetails({ ...joiningDetails, ceipalAccess: e.target.value }) }}
                                                    >
                                                        <FormControlLabel value="yes" control={<Radio required={true} />} label="Yes" />
                                                        <FormControlLabel value="no" control={<Radio required={true} />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid Item xs='auto'>
                                                <FormControl variant="standard" sx={{ width: 200, marginTop: 2, }} size="small">
                                                    <h3>Sim Card</h3>
                                                    <RadioGroup
                                                        row
                                                        required
                                                        aria-labelledby="input-radio-buttons-group-fresher-label"
                                                        name="simCard"
                                                        variant="filled"
                                                        defaultValue={candidate?.joiningDetails?.simCard}
                                                        onChange={(e) => { setJoiningDetails({ ...joiningDetails, simCard: e.target.value }) }}
                                                    >
                                                        <FormControlLabel value="yes" control={<Radio required={true} />} label="Yes" />
                                                        <FormControlLabel value="no" control={<Radio required={true} />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                        </Grid>

                                    </>
                                }

                                <Grid item xs='auto'>
                                    <ReactQuill theme="snow" id='interview_body' defaultValue={interview?.interviewBody}
                                        name="remark"
                                        onChange={setInterviewBody} placeholder="Write Something"
                                        style={{ height: '120px', width: "1200px" }}
                                    />
                                </Grid>



                                {showFinalSelected &&
                                    <>
                                        <Grid item xs='auto'>
                                            <FormControl variant="standard" sx={{ width: 250, marginTop: 3, }} size="small">
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={handleChange}
                                                        name="isFinalSelected"
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />} label="is Final Select ?" />
                                            </FormControl>
                                        </Grid>

                                    </>
                                }

                                <Grid container m={2} gap={5}>
                                    <Grid item xs='auto'>
                                        <Button
                                            size="small"

                                            variant="contained"
                                            // sx={{ mr: 4, ml: 2, mb: 1 }}
                                            type='submit'
                                            value={`${editMode ? 'update' : 'Schedule Interview'}`}
                                        > Update </Button>

                                        <Button
                                            size="small"
                                            sx={{ ml: 5 }}
                                            endIcon={<CloseIcon />}
                                            variant="contained"
                                            color="primary"
                                            type='button'
                                            onClick={(e) => {
                                                setEditMode(false);
                                                setIsModalOpen(false)
                                            }}
                                        >Close</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Box>
                    </form>
                </DialogContent>
            </Dialog >
        </>

    )
}

export default AddOrUpdateInterview;







