import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select, Alert } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
const DeleteCandidates = ({ isDeleteCandidate, setDeleteCandidate, candidate }) => {
    console.log(candidate);
    console.log(candidate.data.data.candidateId);
    const { deleteCandidate } = useCandidates();
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setDeleteCandidate(false);
    };
    const handleDeleteconfirmation = (e) => {
        setDeleteConfirmation(e.target.checked);
    };

    const handleDelete = (e) => {
        if (deleteConfirmation === true) {
            deleteCandidate.mutate({ candidateId: candidate?.data?.data?.candidateId },

                {
                    onSuccess: (data) => {
                        alert('Candidate Successfully Deleted');
                        navigate(`/candidates`);
                        window.location.reload(false);
                    }
                },
                {
                    onError: (data) => {
                        alert('error')
                    }
                }
            )
        } else {
            alert('Confirmation Check Box Cannot Be Empty')
        }
    }
    return (
        <Box >
            <Paper>
                <Dialog
                    open={isDeleteCandidate}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='xxl'>
                    <DialogContent>
                        <h2 style={{
                            padding: '10px', backgroundColor: "cornflowerblue",
                            fontWeight: 700, fontSize: "20px",
                        }} >confirmation For Delete Candidate </h2>
                        <form
                        // onSubmit={(e) => handleSubmit(e)}

                        >
                            <FormControl variant="standard" sx={{ width: 250, margin: 3 }} size="small">
                                <h3>Candidate Name</h3>
                                <TextField
                                    labelId="input-candidate-name-label"
                                    id="outlined-required"
                                    label="Candidate Name"
                                    placeholder='Enter Candidate Name'
                                    size='small'
                                    margin='normal'
                                    name='candidateName'
                                    variant="filled"
                                    required
                                    disablePortal
                                    disabled
                                    autoComplete="off"
                                    defaultValue={candidate?.data?.data?.candidateName ? candidate?.data?.data?.candidateName : ''}

                                // onChange={(e) => handleChange(e)} 
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{ width: 250, margin: 3 }} size="small">
                                <h3>Candidate Email</h3>
                                <TextField

                                    labelId="input-candidate-email-label"
                                    id="outlined-required"
                                    label="Candidate Email"
                                    placeholder='Enter Candidate Email'
                                    size='small'
                                    margin='normal'
                                    name='candidateEmail'
                                    required
                                    disabled
                                    defaultValue={candidate?.data?.data.candidateEmail}
                                    variant="filled"


                                // onChange={(e) => handleChange(e)} 
                                />
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: 150, marginTop: 9, marginBottom: 1 }} size="small">
                                <FormControlLabel control={<Checkbox name={'confirmation'}
                                    checked={deleteConfirmation}
                                    onChange={handleDeleteconfirmation}
                                    inputProps={{ 'aria-label': 'controlled' }} />}
                                    label="confirmation" />

                            </FormControl>
                            <Button
                                variant="contained"
                                color="error"
                                endIcon={<DeleteIcon />}
                                sx={{ borderRadius: 3 }}
                                style={{
                                    width: "100px", marginTop: "75px"
                                }}

                                onClick={handleDelete}

                            >Delete</Button>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: 3 }}
                                style={{
                                    width: "100px", marginTop: "75px", marginLeft: "30px"
                                }}
                                onClick={handleClose}
                            >close</Button>
                        </form>
                    </DialogContent>
                </Dialog>
            </Paper>
        </Box>
    )
}
export default DeleteCandidates;