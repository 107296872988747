
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';

import AddOrUpdateJobRequistion from '../../components/AddOrUpdateJobRequisition/AddOrUpdateJobRequisition';
import SpinLoader from '../../components/SpinLoader/SpinLoader';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControl, MenuItem, InputLabel, Select, TextField } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';
import zIndex from '@mui/material/styles/zIndex';
import * as xl from 'xlsx';
import Grid from '@mui/material/Grid';
import * as fileSaver from 'file-saver';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CleaningServices } from '@mui/icons-material';
import { useUsers } from '../../helpers/hooks/userHooks';


const exportToCSV = (apiData, fileName) => {
  let excelData = apiData?.data?.data;
  const excelData1 = excelData.map((obj) => {
    const data = { ...obj };
    for (let i = 0; i < excelData.length; i++) {
      for (const key in data) {
        if ((typeof data[key] === 'object') && (key === 'costCenter')) {
          data[key] = data[key]?.displayText?.costCenter
        }
        if ((typeof data[key] === 'object') && (key === 'department')) {
          data[key] = data[key]?.displayText?.department
        }
        if ((typeof data[key] === 'object') && (key === 'division')) {
          data[key] = data[key]?.displayText?.division
        }
        if ((typeof data[key] === 'object') && (key === 'devices')) {
          data[key] = data[key]?.displayText?.device
        }
        if ((typeof data[key] === 'object') && (key === 'employmentType')) {
          data[key] = data[key]?.displayText
        }
        if ((typeof data[key] === 'object') && (key === 'industrialType')) {
          data[key] = data[key]?.displayText
        }
        if ((typeof data[key] === 'object') && (key === 'industrialType')) {
          data[key] = data[key]?.displayText
        }
        if ((typeof data[key] === 'object') && (key === 'jobType')) {
          data[key] = data[key]?.displayText
        }
        if ((typeof data[key] === 'object') && (key === 'jobRequisitionStatus')) {
          data[key] = data[key]?.displayText
        }
        if ((typeof data[key] === 'object') && (key === 'document')) {
          data[key] = data[key]?.downloadLink
        }
        if ((typeof data[key] === 'object') && (key === 'document')) {
          data[key] = data[key]?.downloadLink
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'createdDate')) {
          // delete data[key];
          let date = Date.parse(data[key]);
          data[key] = new Date(date).toLocaleDateString('en-US')
        }

        if ((typeof data[key] === 'object') && (key === 'jobAssignments')) {
          // const jobAssignUser = data[key].map((ele) => ele?.user?.displayName)
          // // data[key] = [...jobAssignUser]
          // console.log(...jobAssignUser)
          delete data[key];

        }

        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'lastModifiedDate')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'costCenterId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'departmentId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'divisionId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'jobTypeId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'jobRequisitionStatusId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'employmentTypeId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'industrialTypeId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'createdById')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'jobId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'devicesId')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'lastModifiedById')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object' || typeof data[key] === "string" || typeof data[key] === "boolean") && (key === 'newJob')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object') && (key === 'users')) {
          delete data[key];
        }

        if ((typeof data[key] === 'object' || typeof data[key] === "string") && (key === 'jobDescription')) {
          delete data[key];
        }
        if ((typeof data[key] === 'object') && (key === 'canEngageExternalConsultants')) {
          delete data[key];
        }
      }
    }
    return data;
    // console.log(data)
  });
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = xl.utils.json_to_sheet(excelData1);
  const wb = {
    SheetNames: ["data"],
    Sheets: { data: ws },
  };
  const excelBuffer = xl.write(wb, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  fileSaver.saveAs(fileData, fileName + fileExtension);
}
const JobRequisition = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const [form, setForm] = useState({});
  const [search, setSearch] = useState({});
  const [job, setJob] = useState(null);
  // const { jobRequisitionStatuses, costCenter, department, division } = useMetaData();
  const { jobRequisitionStatuses, costCenter, department, division, costCentersMulti, useDepartmentsMulti, useDivisionsMulti } = useMetaData();
  const { useGetJobTitle, useGetJobs } = useJobs();
  const jobs = useGetJobTitle();
  const jobRequisitions = useGetJobs(Object.keys(search)?.length > 0 ? search : {});
  const navigate = useNavigate();
  const [costCenterIds, setCostCenterIds] = useState([]);
  const [departmentIds, setDepartmentIds] = useState([]);
  const departments = useDepartmentsMulti(costCenterIds, enabled);
  const divisions = useDivisionsMulti(costCenterIds, departmentIds, enabled);

  const { useHiringManagers, useTaManager } = useUsers()
  const hiringManagers = useHiringManagers();
  const TaManager = useTaManager();
  const [jobTitle, setJobTitle] = useState([]);
  const [costCenter1, setcostCenter] = useState([]);
  const [department1, setdepartment] = useState([]);
  const [division1, setdivision] = useState([]);

  let hiringManagerList = hiringManagers?.data?.data.map((el) => el?.displayName)
  let taManagerList = TaManager?.data?.data.map((el) => el?.displayName)

  function useHookWithRefCallback() {
    let fetching = false;
    const onScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (!fetching && ((scrollHeight - scrollTop) <= (clientHeight * 15))) {
        fetching = true;
        if (jobRequisitions.hasNextPage) await jobRequisitions.fetchNextPage();
        fetching = false;
      }
    };
    const ref = useRef(null);
    const setRef = useCallback(node => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", onScroll);
      }

      if (node) {
        node.addEventListener("scroll", onScroll);

      }

      ref.current = node
    }, [jobRequisitions.hasNextPage]);
    return [setRef];
  }
  const [ref] = useHookWithRefCallback();


  useEffect(() => {

    if (costCenterIds?.length && costCenterIds?.length > 0) {

      departments.refetch()
    }
    if (departmentIds?.length && departmentIds?.length > 0) {
      divisions.refetch()
    }
  }, [costCenterIds, departmentIds, jobRequisitions?.data?.data]);

  if (jobs.isLoading || jobRequisitions?.isFetching && !jobRequisitions?.isFetchingNextPage
    // || jobs.isFetching
  ) {
    return <SpinLoader />
  }


  const downloadDocument = (e, documents) => {
    e.preventDefault();
    if (documents) {
      window.open(documents?.downloadLink, '_blank', 'noopener,noreferrer')
    } else {
      alert('No document')
    }
  }
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }


  // const handleSelectcostCenterId = (e) => {
  //   let userid = e.map((a) => a.value)
  //   setSearch({ ...search, costCenter: userid })
  // }
  // const handleSelectdepartmentId = (e) => {
  //   let userid = e.map((a) => a.value)
  //   setSearch({ ...search, department: userid })
  // }
  // const handleSelectdivisionId = (e) => {
  //   let userid = e.map((a) => a.value)
  //   console.log(userid)
  //   setSearch({ ...search, division: userid })
  // }
  // const handleRemovecostCenterId = (e) => {
  //   let userid = e.map((a) => a.value)
  //   setSearch({ ...search, costCenter: userid })
  // }
  // const handleRemovedepartmentId = (e) => {
  //   let userid = e.map((a) => a.value)
  //   setSearch({ ...search, department: userid })
  // }
  // const handleRemovedivisionId = (e) => {
  //   let userid = e.map((a) => a.value)
  //   console.log(userid)
  //   setSearch({ ...search, division: userid })
  // }
  const handleSelectJobsColumns = (e) => {
    let key = e.map((a) => a.key)
    setSearch({ ...search, jobTitle: key }) //! job: value,
    setJobTitle(e)
  }

  const handleRemoveJobsColumns = (e) => {
    let key = e.map((a) => a.key)
    setSearch({ ...search, jobTitle: key }) //! job: value,
    setJobTitle(e)
  }

  const handleSelectcostCenterId = (e) => {
    let userid = e.map((a) => a.value)
    setCostCenterIds(userid)
    setSearch({ ...search, costCenter: userid });
    setcostCenter(e)
  }

  const handleSelectdepartmentId = (e) => {
    let userid = e.map((a) => a.value)
    setDepartmentIds(userid);
    setSearch({ ...search, department: userid })
    setdepartment(e)
  }

  const handleSelectdivisionId = (e) => {
    let userid = e.map((a) => a.value)
    setSearch({ ...search, division: userid })
    setdivision(e)
  }

  const handleRemovecostCenterId = (e) => {
    let userid = e.map((a) => a.value)
    setCostCenterIds(userid)
    setSearch({ ...search, costCenter: userid })
    setcostCenter(e)
  }

  const handleRemovedepartmentId = (e) => {
    let userid = e.map((a) => a.value)
    setSearch({ ...search, department: userid })
    setdepartment(e)
  }

  const handleRemovedivisionId = (e) => {
    let userid = e.map((a) => a.value)
    setSearch({ ...search, division: userid })
    setdivision(e)
  }


  function Reset() {
    setSearch({});
    window.location.reload(false);
  }
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      jobRequisitions.refetch();
    }
  }

  return (
    <>
      <div style={{ height: 'auto', margin: '50px', marginTop: '10px' }}>
        {isModalOpen &&
          <AddOrUpdateJobRequistion setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} job={job} />
        }
      </div>
      <Box ml={10} mt={15} sx={{ boxShadow: 1 }} >

        <Grid item xs='auto' sx={{ mb: 3 }}>
          <FormControl>
            <TextField
              type="text"
              name="keyword"
              label=""
              placeholder='search'
              style={{ marginRight: "20px", height: 50, marginTop: 8 }}
              value={search?.keyword ? search?.keyword : ''}
              onKeyDown={(e) => handleKeyDown(e)}
              variant='outlined'
              onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })} />
          </FormControl>

          <FormControl sx={{ width: 200 }}>
            <InputLabel id="demo-simple-label">Status</InputLabel>
            <Select
              labelId="demo-simple-label"
              id="candidate_status_id"
              label="status"
              name='status'
              value={search.status ? search.status : ''}
              style={{ marginRight: "20px", height: 50, marginTop: 8 }}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}>
              <MenuItem key={"All"} value={"All"}>{"All"}</MenuItem>
              {jobRequisitionStatuses?.data?.data && jobRequisitionStatuses?.data?.data.map(status =>
                <MenuItem key={status?.metaDataId} value={status?.metaDataId}>{status?.displayText}</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 200 }} >
            <InputLabel id="demo-simple-label">Priority</InputLabel>
            <Select
              labelId="demo-simple-label"
              id="candidate_status_id"
              label="Priority"
              name='priority'
              value={search?.priority}
              style={{ marginRight: "20px", height: 50, marginTop: 5 }}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </Select>
          </FormControl>

          <FormControl >
            <Multiselect
              placeholder='Job Title'
              hidePlaceholder={true}
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSelect={(e) => { handleSelectJobsColumns(e) }}
              onRemove={(e) => { handleRemoveJobsColumns(e) }}
              onSearch={function noRefCheck() { }}
              options={jobs?.data?.data?.map((el) => { return { key: el.jobTitle, value: el.jobId } })}
              selectedValues={jobTitle}
              style={{
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '5px 15px 0 0',
                  width: '250px',
                  height: '50px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>

          <FormControl>
            <Multiselect
              displayValue="key"
              placeholder='Cost Center'
              onKeyPressFn={function noRefCheck() { }}
              onSearch={function noRefCheck() { }}
              onSelect={(e) => { handleSelectcostCenterId(e) }}
              onRemove={(e) => { handleRemovecostCenterId(e) }}
              options={costCentersMulti?.data?.data?.map((data) => { return { value: data?.metaDataId, key: data?.displayText } })}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e, data) => {
                setSearch({ ...search, costCenter: data?.value })
              }}
              selectedValues={costCenter1}
              style={{
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '5px 5px 0 5px',
                  width: '210px',
                  height: '50px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />

          </FormControl>
          <FormControl>
            <Multiselect
              placeholder='Department'
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSearch={function noRefCheck() { }}
              onSelect={(e) => { handleSelectdepartmentId(e) }}
              onRemove={(e) => { handleRemovedepartmentId(e) }}
              options={departments?.data?.data?.map((data) => { return { value: data?.metaDataId, key: data?.displayText } })}

              disable={costCenter1.length === 0 ? true : false}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e, data) => {
                setSearch({ ...search, department: data?.value })
              }}
              selectedValues={department1}
              style={{
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '5px 5px 0 5px',
                  width: '210px',
                  height: '50px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}

              showCheckbox

            />

          </FormControl>
          <FormControl >
            <Multiselect
              placeholder='Division'
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSearch={function noRefCheck() { }}
              onSelect={(e) => { handleSelectdivisionId(e) }}
              onRemove={(e) => { handleRemovedivisionId(e) }}
              options={divisions?.data?.data?.map((data) => { return { value: data?.metaDataId, key: data?.displayText } })}
              onKeyDown={(e) => handleKeyDown(e)}
              disable={department1.length === 0 ? true : false || costCenter1.length === 0 ? true : false}
              onChange={(e, data) => {
                setSearch({ ...search, division: data?.value })
              }}
              selectedValues={division1}
              style={{
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '5px 10px 0 5px',
                  width: '210px',
                  height: '50px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox

            />

          </FormControl>

        </Grid>

        <Grid item xs='auto' sx={{ mb: 3 }}>
          <FormControl>
            <Button
              variant="contained"
              endIcon={<PersonSearchTwoToneIcon />}
              style={{ marginRight: "20px", height: 45, marginTop: 10 }}
              sx={{ width: 120, borderRadius: 3 }}
              onClick={(e) => { jobRequisitions.refetch() }}
            >Search</Button>
          </FormControl>
          <FormControl>
            <Button variant="contained"
              sx={{ width: 110, borderRadius: 3 }}
              style={{ marginRight: "20px", height: 45, marginTop: 10 }}
              onClick={(e) => { Reset(e) }}> Reset </Button>
          </FormControl>
          {(window.localStorage.getItem('role') === 'TA Manager' ||
            window.localStorage.getItem('role') === 'Admin' ||
            window.localStorage.getItem('role') === 'Hiring Manager') &&
            <>
              <FormControl>
                <Button variant="contained"
                  endIcon={<AddIcon />}
                  sx={{ borderRadius: 3 }}
                  style={{ marginRight: "20px", height: 45, marginTop: 10 }}
                  onClick={() => { setIsModalOpen(true); setJob(null) }}
                > ADD JOBS </Button>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  sx={{ borderRadius: 3 }}
                  style={{ marginRight: "20px", height: 45, marginTop: 10 }}
                  onClick={(e) => { exportToCSV(jobRequisitions, 'new') }}>Export Excel</Button>
              </FormControl>
            </>
          }
        </Grid>




        <Box sx={{ background: 'white', width: 'auto', height: '90vh', display: 'flex', flexDirection: 'column' }}>
          <TableContainer ref={ref} sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      bgcolor: 'background.paper',
                      backgroundColor: "#243c80",
                      color: 'white',
                    }
                  }}>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Job Code</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Job Title</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Priority</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Positions</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Experience</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Salary </TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Job Location</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Cost Center</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Department</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Division</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Hiring Manager</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>TA Manager</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Status </TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>JD</TableCell>
                  {(window.localStorage.getItem('role') === 'TA Manager' ||
                    window.localStorage.getItem('role') === 'Admin' ||
                    window.localStorage.getItem('role') === 'Hiring Manager') ?
                    <>
                      <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Edit </TableCell>
                      <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Duplicate</TableCell>
                    </>
                    :
                    <>
                      <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>View</TableCell>
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {jobRequisitions?.data && jobRequisitions?.data?.pages.map((page) => page.data.rows.map((job, i) => {
                  return <TableRow
                    hover
                    key={job?.jobId}>
                    <TableCell>{job?.jobCode}</TableCell>
                    <TableCell>{job?.jobTitle}</TableCell>
                    <TableCell>{job?.priority}</TableCell>
                    <TableCell>{job?.noOfPositions}</TableCell>
                    <TableCell>{job?.experience}</TableCell>
                    <TableCell>{JSON.parse(job?.payRates)[0]?.pay_rate}</TableCell>
                    <TableCell>{job?.city}</TableCell>
                    <TableCell>{job?.costCenter?.displayText?.costCenter}</TableCell>
                    <TableCell>{job?.department?.displayText?.department}</TableCell>
                    <TableCell>{job?.division?.displayText?.division}</TableCell>
                    {/* <TableCell>{job.jobAssignments.map((user) => user?.user?.displayName).filter((el) => hiringManagerList?.includes(el))}</TableCell> */}
                    <TableCell>{job?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
                      (el) => !taManagerList?.includes(el))?.filter((el) => hiringManagerList?.includes(el)).length === 0
                      ?
                      job?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
                        (el) => hiringManagerList?.includes(el))
                      :
                      job?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
                        (el) => !taManagerList?.includes(el))?.filter((el) => hiringManagerList?.includes(el))}</TableCell>
                    <TableCell>{
                      job?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
                        (el) => taManagerList?.includes(el)).filter((el) => !hiringManagerList?.includes(el)).length === 0
                        ?
                        job?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
                          (el) => taManagerList?.includes(el))
                        :
                        job?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
                          (el) => !hiringManagerList?.includes(el))?.filter((el) => taManagerList?.includes(el))
                    }</TableCell>
                    <TableCell>{job?.jobRequisitionStatus?.displayText}</TableCell>
                    <TableCell> <ArchiveIcon variant='contained' name='document'
                      onClick={(e) => downloadDocument(e, job?.document)} >Download</ArchiveIcon></TableCell>
                    {(window.localStorage.getItem('role') === 'TA Manager' ||
                      window.localStorage.getItem('role') === 'Admin' ||
                      window.localStorage.getItem('role') === 'Hiring Manager') ?
                      <>
                        {job?.newJob === true ?
                          <>
                            <TableCell>
                              <FiberNewIcon color="error" onClick={(e) => { navigate(`/jobRequisition/${job?.jobId}`) }} />
                            </TableCell>
                          </>
                          :
                          <TableCell>
                            <EditIcon onClick={(e) => { navigate(`/jobRequisition/${job?.jobId}`) }} />
                          </TableCell>
                        }
                        <TableCell onClick={(e) => {
                          setIsModalOpen(true);
                          setJob(job)
                        }}> <FileCopyIcon />
                        </TableCell>
                      </>
                      :
                      <>
                        <TableCell onClick={(e) => { navigate(`/jobRequisition/${job?.jobId}`) }}> <VisibilityIcon /></TableCell>
                      </>
                    }

                  </TableRow>
                }
                )
                )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
};
export default JobRequisition;