import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { UserContext } from '../../components/Routes/Routes';
//!----------------MuI-----------------------------------
import Box from '@mui/material/Box';
import SpinLoader from '../../components/SpinLoader/SpinLoader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useUsers } from '../../helpers/hooks/userHooks';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import Link from '@mui/material/Link';
import AddOrUpdateJobRequisition from '../../components/AddOrUpdateJobRequisition/AddOrUpdateJobRequisition';
import Multiselect from 'multiselect-react-dropdown';
const UpdateJob = () => {
    const value = useContext(UserContext);
    const location = useLocation();
    const jobId = location.pathname.split('/')[2];
    const { jobRequisitionStatuses, jobTypes, costCenter, department, division, devices, useDepartments, useDivisions, costCenters } = useMetaData();
    const [value1, setValue1] = useState();
    const [payRates, setpayRates] = useState();
    const [experience, setexperience] = useState();
    const { GetjobToUpdate, updateJobRequisition, uploadJobDocuments, deleteJobDocument, } = useJobs();
    const getJobToUpdated = GetjobToUpdate(jobId);
    const { useHiringManagers, useRecruiters, useTaManager } = useUsers(value?.data?.role);


    const hiringManagers = useHiringManagers()
    const recruiters = useRecruiters()
    const TaManager = useTaManager()
    const [jobDescription, setjobDescription] = useState();
    const [form, setForm] = useState({});
    const [recruiterUserId, setRecruiterUserId] = useState([]);
    const [removeUserId, setRemoveUserId] = useState([]);
    const [taManagersUserId, settaManagersUserId] = useState([]);
    const [isJobRepost, setIsJobRepost] = useState(false);
    const [costCenterId, setCostCenterId] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const departments = useDepartments(costCenterId);
    const divisions = useDivisions(costCenterId, departmentId);
 
    const filteredRecruiters = recruiters?.data?.data?.filter(recruiter => {
        return recruiter.parentId === value.data.userId;
    });

    // Mapping the filtered recruiters to the required format
    const recruiterslist = filteredRecruiters?.map(x => ({
        value: x?.userId,
        key: x?.displayName
    }));
    // const recruiterslist = recruiters?.data?.data?.map((x) => { return { value: x?.userId, key: x?.displayName } })
    let taManagerList = TaManager?.data?.data.map((el) => el?.displayName)
    let hiringManagerList = hiringManagers?.data?.data.map((el) => el?.displayName)

    useEffect(() => {
        setjobDescription(getJobToUpdated?.data?.data[0]?.jobDescription);

        if (!costCenterId && getJobToUpdated?.data?.data[0]?.costCenterId) {
            setCostCenterId(getJobToUpdated?.data?.data[0]?.costCenterId)
        }
        if (!departmentId && getJobToUpdated?.data?.data[0]?.departmentId) {
            setDepartmentId(getJobToUpdated?.data?.data[0]?.departmentId);
        }

        if (costCenterId) {
            departments.refetch();
        }

        if (departmentId) {
            divisions.refetch();
        }

        if (!payRates && getJobToUpdated?.data?.data[0]?.payRates) {

            let payRates = {};
            payRates.minPayRates = JSON.parse(getJobToUpdated?.data?.data[0]?.payRates)[0]?.pay_rate?.split('-')[0]
            payRates.maxPayRates = JSON.parse(getJobToUpdated?.data?.data[0]?.payRates)[0]?.pay_rate?.split('-')[1]
            setpayRates(payRates)
        }

        if (!experience && getJobToUpdated?.data?.data[0]?.experience) {

            let experience = {};
            experience.minExperience = JSON.parse(getJobToUpdated?.data?.data[0]?.experience?.split('-')[0])
            experience.maxExperience = JSON.parse(getJobToUpdated?.data?.data[0]?.experience?.split('-')[1]?.split(' ')[0])
            setexperience(experience)
        }

    },
        [
            recruiters?.data?.data,
            getJobToUpdated?.data?.data,
            costCenterId,
            departmentId,
            getJobToUpdated?.data?.data[0]?.costCenterId,
            getJobToUpdated?.data?.data[0]?.departmentId,
            // departments?.data?.data,    
            // divisions?.data?.data   
        ]
    )
    const handleChange = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'costCenterId') {
            setCostCenterId(e.target.value);
        } else if (e.target.name === 'departmentId') {
            setDepartmentId(e.target.value);
        }
    }
    const assignrecRecruitersList = getJobToUpdated?.data?.data[0]?.jobAssignments?.filter((assignment) =>
        assignment?.user?.roleAssignments?.some((assignment) => assignment?.role?.roleName === 'Recruiter'))


    const assignTaManagerList = getJobToUpdated?.data?.data[0]?.jobAssignments?.filter((assignment) =>
        assignment?.user?.roleAssignments?.some((assignment) => assignment?.role?.roleName === 'TA Manager'))

    const taManagerName = getJobToUpdated?.data?.data[0]?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
        (el) => taManagerList?.includes(el)).filter((el) => !hiringManagerList?.includes(el)).length === 0
        ?
        getJobToUpdated?.data?.data[0]?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
            (el) => taManagerList?.includes(el))
        :
        getJobToUpdated?.data?.data[0]?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
            (el) => !hiringManagerList?.includes(el))?.filter((el) => taManagerList?.includes(el))
    // const assignTaManagerList = getJobToUpdated?.data?.data[0]?.jobAssignments?.filter((assignment) =>
    //     assignment?.user?.roleAssignments?.some((assignment) => assignment?.role?.roleName === 'TA Manager'))


    const assignhiringManagersList = getJobToUpdated?.data?.data[0]?.jobAssignments?.filter((assignment) =>
        assignment?.user?.roleAssignments?.some((assignment) => assignment?.role?.roleName === 'Hiring Manager'))

    const hirignManagerName = getJobToUpdated?.data?.data[0]?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
        (el) => !taManagerList?.includes(el))?.filter((el) => hiringManagerList?.includes(el)).length === 0
        ?
        getJobToUpdated?.data?.data[0]?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
            (el) => taManagerList?.includes(el))
        :
        getJobToUpdated?.data?.data[0]?.jobAssignments?.map((el) => el?.user?.displayName)?.filter(
            (el) => !taManagerList?.includes(el)).filter((el) => hiringManagerList?.includes(el))
    // const assignhiringManagersList = getJobToUpdated?.data?.data[0]?.jobAssignments?.filter((assignment) =>
    //     assignment?.user?.roleAssignments?.some((assignment) => assignment?.role?.roleName === 'Hiring Manager'))

    const handleSelectRecruiterUserId = (e) => {
        let userid = e.map((a) => a?.value)
        setRecruiterUserId(userid)
    }
    const handleRemoveRecruiterUserId = (e, value) => {
        if (!removeUserId.some(el => el === value?.value)) {
            setRemoveUserId(prev => [...prev, value?.value]);
        }
    }
    const handleSelectTaManagersUserId = (e) => {
        let userid = e.map((a) => a.value)
        setRecruiterUserId(userid)

    }
    const handleRemoveTaManagersUserId = (e, value) => {
        let userid = e.map((a) => a?.value)
        if (!removeUserId.some(el => el === value?.value)) {
            setRemoveUserId(prev => [...prev, value?.value]);
        }
    }
    const handleSelecthiringManagersUserId = (e) => {
        let userid = e.map((a) => a?.value)
        setRecruiterUserId(userid)

    }
    const handleRemovehiringManagersUserId = (e, value) => {
        let userid = e.map((a) => a?.value)
        if (!removeUserId.some(el => el === value?.value)) {
            setRemoveUserId(prev => [...prev, value?.value]);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (parseInt(payRates?.minPayRates) >= parseInt(payRates?.maxPayRates)) {
            alert('Minimum  pay rates CTC should be less than Maximum pay rates CTC');
            return;
        }
        if (parseInt(experience?.minExperience) >= parseInt(experience?.maxExperience)) {
            alert('Minimum experience should be less than Maximum experience');
            return;
        }
        if (payRates) {
            form.payRates = JSON.stringify([{ pay_rate: `${payRates?.minPayRates}-${payRates?.maxPayRates}` }]);
        }

        if (experience) {
            form.experience = `${experience?.minExperience}-${experience?.maxExperience} years`;
        }
        if (jobDescription) {
            form.jobDescription = jobDescription;
        }
        // if (form.status === "Active") {
        //     form.jobRequisitionStatusId = jobRequisitionStatuses?.data?.data?.filter((status) => status?.displayText === "Active")[0]?.metaDataId;

        // }
        // if (form.status === "On Hold") {
        //     form.jobRequisitionStatusId = jobRequisitionStatuses?.data?.data?.filter((status) => status?.displayText === "On Hold")[0]?.metaDataId;

        // }
        // if (form.status === "Filled") {
        //     form.jobRequisitionStatusId = jobRequisitionStatuses?.data?.data?.filter((status) => status?.displayText === "Filled")[0]?.metaDataId;

        // }
        // if (form.status === "Closed") {
        //     form.jobRequisitionStatusId = jobRequisitionStatuses?.data?.data?.filter((status) => status?.displayText === "Closed")[0]?.metaDataId;

        // }

        if (form.jobRequisitionStatusId) {
            let status = jobRequisitionStatuses?.data?.data.filter((status) => status?.metaDataId === form.jobRequisitionStatusId)[0]?.displayText;

            if (status === 'Closed') {
                form.status = 'Inactive';
            } else if (status === 'On Hold') {//!------------------
                form.status = 'Hold';
            } else {
                form.status = 'Active';
            }
        }

        let userIds = [];
        let removeId = []
        const formData = new FormData();

        if (recruiterUserId.length > 0) {
            userIds.push(...recruiterUserId);
            form.newJob = false
        }
        if (taManagersUserId.length > 0) {
            userIds.push(...taManagersUserId);
        }
        if (removeUserId.length > 0) {
            removeId.push(...removeUserId)
        }
        if (form.hiringManagerId) {
            userIds.push(form.hiringManagerId);
            delete form.hiringManagerId;
        }
        console.log(form)
        Object.keys(form).forEach(key => {
            formData.append(key, form[key]);
        });

        formData.append('userIds', JSON.stringify(userIds));
        formData.append('removeId', JSON.stringify(removeId));
        updateJobRequisition.mutate({ id: jobId, formData: formData },
            {
                onSuccess: (data) => {
                    if (form.file) {
                        formData.append('jobId', data?.data?.jobId);
                        formData.append('documentName', 'JobDescription');
                        uploadJobDocuments.mutate({ formData },
                            {
                                onError: (e) => {
                                    alert(e.response?.data?.message);
                                }
                            });
                    }
                    alert('success')
                    // window.location.reload(false);
                }
            }, {
            onError: (data) => {
                alert('error')
            }
        }
        );
    }
    const handleDelete = (e) => {
        const formData = new FormData();
        formData.append('jobId', getJobToUpdated?.data?.data[0]?.jobId);
        formData.append('documentName', e.target.name
        );
        formData.append('delete', true);
        deleteJobDocument.mutate({ formData });
    }
    const downloadDocument = (e) => {
        e.preventDefault();
        if (getJobToUpdated?.data?.data[0]?.document) {
            let document = getJobToUpdated?.data?.data[0]?.document;
            if (document.downloadLink) {
                window.open(document.downloadLink, '_blank', 'noopener,noreferrer')
            }
        }
    }

    if (getJobToUpdated.isLoading || getJobToUpdated.isFetching || updateJobRequisition.isLoading || updateJobRequisition.isFetching) {
        return <SpinLoader />
    }

    return (
        <>
            {isJobRepost &&
                <AddOrUpdateJobRequisition setIsModalOpen={setIsJobRepost} isModalOpen={isJobRepost} job={getJobToUpdated?.data?.data[0]} />
            }
            {(!getJobToUpdated.isLoading && payRates && experience) &&
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box m={2} mt={15} ml={10} sx={{ boxShadow: 1, backgroundColor: 'white', overflow: 'hidden', border: 'none' }} >
                        <h2 style={{
                            padding: '10px', backgroundColor: "#243c80",
                            color: "white", fontWeight: 500, fontSize: "18px",
                        }} >Job Details </h2>
                        <Grid container m={5} gap={4}>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Job Code
                                    <TextField
                                        labelId="input-candidate-name-label"
                                        id="outlined-required"
                                        label="Job Code"

                                        placeholder='Enter Job Code'
                                        size='small'
                                        margin='normal'
                                        name='jobCode'
                                        variant="filled"
                                        disabled
                                        defaultValue={getJobToUpdated?.data?.data[0]?.jobCode}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Job Title
                                    <TextField
                                        labelId="input-candidate-name-label"
                                        id="outlined-required"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        label="Job Title"
                                        placeholder='Enter Job Title'
                                        size='small'
                                        margin='normal'
                                        name='jobTitle'
                                        variant="filled"
                                        defaultValue={getJobToUpdated?.data?.data[0]?.jobTitle}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Job State
                                    <TextField
                                        labelId="input-job-name-label"
                                        id="outlined-required"
                                        name="state"
                                        variant="filled"
                                        placeholder='Enter Job state'
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        size='small'
                                        margin='normal'
                                        defaultValue={getJobToUpdated?.data?.data[0]?.state}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Job City
                                    <TextField
                                        labelId="input-Job-name-label"
                                        id="outlined-required"
                                        label="Job City"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        placeholder='Enter Job City'
                                        size='small'
                                        margin='normal'
                                        name='city'
                                        variant="filled"
                                        defaultValue={getJobToUpdated?.data?.data[0]?.city}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Min Pay Rate
                                    <TextField
                                        required
                                        id='payRates'
                                        label="Min Pay Rate"
                                        placeholder='Min'
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        size='small'
                                        margin='normal'
                                        InputProps={{ inputProps: { min: "100000" } }}
                                        type='number'
                                        variant="filled"
                                        name='payRates'
                                        defaultValue={payRates?.minPayRates}

                                        onChange={(e) => { setpayRates({ ...payRates, minPayRates: e.target.value }) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Max Pay Rate
                                    <TextField
                                        required
                                        id='payRates'
                                        label="Max Pay Rate"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        placeholder='Max'
                                        size='small'
                                        margin='normal'
                                        InputProps={{ inputProps: { min: "100000" } }}
                                        type='number'
                                        variant="filled"
                                        name='payRates'
                                        defaultValue={payRates?.maxPayRates}
                                        onChange={(e) => { setpayRates({ ...payRates, maxPayRates: e.target.value }) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Min Experience
                                    <TextField
                                        required
                                        id='experience'
                                        label=" Min Experience"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        placeholder='Min'
                                        size='small'
                                        margin='normal'
                                        type='number'
                                        variant="filled"
                                        name='experience'
                                        defaultValue={
                                            experience?.minExperience}
                                        onChange={(e) => { setexperience({ ...experience, minExperience: e.target.value }) }}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Max Experience
                                    <TextField
                                        required
                                        id='experience'
                                        label=" Max Experience"
                                        placeholder='Max'
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        size='small'
                                        margin='normal'
                                        type='number'
                                        variant="filled"
                                        name='experience'
                                        defaultValue={
                                            experience?.maxExperience}
                                        onChange={(e) => { setexperience({ ...experience, maxExperience: e.target.value }) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Company Name
                                    <TextField
                                        labelId="input-candidate-name-label"
                                        id="outlined-required"
                                        label="Company Name"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        placeholder='Enter Company Name'
                                        size='small'
                                        margin='normal'
                                        name='company'
                                        variant="filled"
                                        defaultValue={getJobToUpdated?.data?.data[0]?.company}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    No of Positions
                                    <TextField
                                        required
                                        id='noOfPositions'
                                        label="No of Positions"
                                        placeholder='Enter No of Positions'
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        size='small'
                                        variant="filled"
                                        margin='normal'
                                        type='number'
                                        name='noOfPositions'
                                        defaultValue={getJobToUpdated?.data?.data[0]?.noOfPositions}
                                        onChange={(e) => handleChange(e)} />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                                    Priority
                                    <Select
                                        labelId="select-priority-label"
                                        id="select-job-label"
                                        variant="filled"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        name='priority'
                                        required
                                        defaultValue={getJobToUpdated?.data?.data[0]?.priority}
                                        onChange={(e) => handleChange(e)}
                                        margin='normal'
                                    >
                                        <MenuItem key="High" value="High">High</MenuItem>
                                        <MenuItem key="Medium" value="Medium">Medium</MenuItem>
                                        <MenuItem key="Low" value="Low">Low</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                                    Job Status
                                    <Select
                                        id="candidateStatusId"
                                        size='small'
                                        margin='normal'
                                        required
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        name='jobRequisitionStatusId'
                                        variant="filled"
                                        defaultValue={getJobToUpdated?.data?.data[0]?.jobRequisitionStatusId}
                                        onChange={(e) => handleChange(e)}
                                    >

                                        {jobRequisitionStatuses?.data && jobRequisitionStatuses?.data?.data.map((status) =>
                                            <MenuItem key={status?.metaDataId} value={status?.metaDataId}>{status?.displayText}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250, }} size="small">
                                    Cost Center
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="costCenterId"
                                        name="costCenterId"
                                        size='small'
                                        variant="filled"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        margin='normal'
                                        defaultValue={getJobToUpdated?.data?.data[0]?.costCenter?.metaDataId}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {costCenters?.data && costCenters?.data?.data.map(cc =>
                                            <MenuItem key={cc?.metaDataId} value={cc?.metaDataId}>{cc?.displayText}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <>
                                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                        Department
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            id="departmentId"
                                            label="department"
                                            name="departmentId"
                                            size='small'
                                            disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                            variant="filled"
                                            margin='normal'
                                            defaultValue={getJobToUpdated?.data?.data[0]?.department?.metaDataId}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            {
                                                departments?.data && departments?.data?.data.map(cc =>
                                                    <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </>
                            </Grid>
                            <Grid item xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Division
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        name="divisionId"
                                        label="division"
                                        id="divisionId"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        size='small'
                                        variant="filled"
                                        margin='normal'
                                        defaultValue={getJobToUpdated?.data?.data[0]?.division?.metaDataId}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {divisions?.data && divisions?.data?.data.map(cc =>
                                            <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText}</MenuItem>
                                        )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Devices
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="devicesId"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        label="devices"
                                        name="devicesId"
                                        size='small'
                                        variant="filled"
                                        margin='normal'
                                        defaultValue={getJobToUpdated?.data?.data[0]?.devicesId}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {devices?.data && devices?.data?.data?.map(cc =>
                                            <MenuItem key={cc?.metaDataId} value={cc.metaDataId}>{cc?.displayText?.device}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                                    Job Type
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="jobTypeId"
                                        label="jobTypeId"
                                        name="jobTypeId"
                                        disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                        size='small'
                                        variant="filled"
                                        margin='normal'
                                        defaultValue={getJobToUpdated?.data?.data[0]?.jobTypeId}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {jobTypes?.data && jobTypes?.data?.data.map((jobType) =>
                                            <MenuItem key={jobType?.metaDataId} value={jobType?.metaDataId}>{jobType?.displayText}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250, marginTop: "10px" }} size="small">
                                    Job Created Date
                                    <TextField
                                        required
                                        inputFormat="DD/MM/YYYY"
                                        type='date'
                                        size='small'
                                        disabled
                                        name="candidateLastWorkingDate"
                                        defaultValue={
                                            getJobToUpdated?.data?.data[0]?.createdDate
                                                ? new Date(getJobToUpdated?.data?.data[0]?.createdDate)?.toISOString()?.split('T')[0]
                                                : ''
                                        }
                                        value={value1}
                                        onChange={(e) => handleChange(e)}
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>
                                <FormControl variant="standard" sx={{ width: 250, }} size="small">

                                    {
                                        (getJobToUpdated?.data?.data[0]?.document)
                                            ?
                                            <>
                                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }} variant='contained' name='document'
                                                    onClick={(e) => downloadDocument(e)} >Job Description Document</Link>
                                                {(window.localStorage.getItem('role') === 'TA Manager' ||
                                                    window.localStorage.getItem('role') === 'Admin' ||
                                                    window.localStorage.getItem('role') === 'Hiring Manager') ?
                                                    <>
                                                        <button variant='contained' name='resume' onClick={(e) => handleDelete(e)} >Delete</button>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <TextField
                                                    id='attachment_path'
                                                    name='file'
                                                    type="file"
                                                    accept=".doc,.docx,application/pdf"
                                                    required
                                                    onChange={(e) => {
                                                        if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
                                                            setForm({ ...form, [e.target.name]: e.target.files[0] });
                                                        } else {
                                                            alert('Document type should be PDf or Word only');
                                                            e.target.value = ''
                                                        }
                                                    }}
                                                />
                                            </>
                                    }
                                </FormControl>
                            </Grid>
                            {(window.localStorage.getItem('role') === 'TA Manager' ||
                                window.localStorage.getItem('role') === 'Admin') ?
                                <>
                                    <Grid xs='auto'>
                                        <FormControl style={{ marginTop: "20px", border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "5px", }} >
                                            Recruiters

                                            <Multiselect
                                                displayValue="key"
                                                onKeyPressFn={function noRefCheck() { }}
                                                onSelect={(e) => { handleSelectRecruiterUserId(e) }}
                                                onRemove={(e, value) => { handleRemoveRecruiterUserId(e, value) }}
                                                onSearch={function noRefCheck() { }}
                                                disabled={(window.localStorage.getItem('role') === 'Recruiter') ? true : false}
                                                options={recruiterslist}
                                                selectedValues={assignrecRecruitersList?.map((x) => { return { value: x?.user?.userId, key: x?.user?.displayName } })}
                                                onChange={(e) => handleChange(e)}
                                                style={{
                                                    chips: {
                                                        background: '1px solid blue',

                                                    },
                                                    multiselectContainer: {
                                                        color: '1px solid blue',
                                                        zIndex: "999",
                                                        "white-space": "nowrap",

                                                    },
                                                    searchBox: {
                                                        border: 'none',
                                                        'border-radius': '0px',
                                                        width: '250px',
                                                        overflow: "hidden"
                                                    },
                                                }}
                                                showCheckbox
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid xs='auto'>
                                        <FormControl style={{ marginTop: "20px", border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "5px", }} >
                                            Recruiters

                                            <Multiselect
                                                displayValue="key"
                                                onKeyPressFn={function noRefCheck() { }}
                                                onRemove={(e) => { handleRemoveRecruiterUserId(e) }}
                                                onSearch={function noRefCheck() { }}
                                                onSelect={(e) => { handleSelectRecruiterUserId(e) }}
                                                disable={true}
                                                options={recruiterslist}
                                                selectedValues={assignrecRecruitersList?.map((x) => { return { value: x?.user?.userId, key: x?.user?.displayName } })}
                                                onChange={(e) => handleChange(e)}
                                                style={{
                                                    chips: {
                                                        background: '1px solid blue',

                                                    },
                                                    multiselectContainer: {
                                                        color: '1px solid blue',
                                                        zIndex: "999",
                                                        "white-space": "nowrap",

                                                    },
                                                    searchBox: {
                                                        border: 'none',
                                                        'border-radius': '0px',
                                                        width: '250px',
                                                        overflow: "hidden"
                                                    },
                                                }}
                                                showCheckbox
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            }
                            <Grid xs='auto'>

                                <FormControl style={{ marginTop: "20px", border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "5px", }} >
                                    TA Manager
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(e, value) => { handleRemoveTaManagersUserId(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        // disable={true}
                                        onSelect={(e) => { handleSelectTaManagersUserId(e) }}
                                        options={TaManager?.data?.data?.map((x) => { return { value: x?.userId, key: x?.displayName } })}
                                        selectedValues={assignTaManagerList?.filter((ele) => taManagerName?.includes(ele?.user?.displayName))?.map((x) => { return { value: x?.user?.userId, key: x?.user?.displayName } })}
                                        onChange={(e) => handleChange(e)}
                                        style={{
                                            chips: {
                                                background: '1px solid blue',
                                            },
                                            multiselectContainer: {
                                                color: '1px solid blue',
                                                zIndex: "999",
                                                "white-space": "nowrap",
                                            },
                                            searchBox: {
                                                border: 'none',
                                                'border-radius': '0px',
                                                width: '250px',
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs='auto'>


                                <FormControl style={{ marginTop: "20px", border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "5px", }} >
                                    Hiring Managers
                                    <Multiselect
                                        displayValue="key"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(e, value) => { handleRemovehiringManagersUserId(e, value) }}
                                        onSearch={function noRefCheck() { }}
                                        disable={true}
                                        selectionLimit={1}
                                        onSelect={(e) => { handleSelecthiringManagersUserId(e) }}
                                        options={hiringManagers?.data?.data?.map((x) => { return { value: x?.userId, key: x?.displayName } })}
                                        selectedValues={assignhiringManagersList?.filter((ele) => hirignManagerName?.includes(ele?.user?.displayName))?.map((x) => { return { value: x?.user?.userId, key: x?.user?.displayName } })}
                                        onChange={(e) => handleChange(e)}
                                        style={{
                                            chips: {
                                                background: '1px solid blue',
                                            },
                                            multiselectContainer: {
                                                color: '1px solid blue',
                                                zIndex: "999",
                                                "white-space": "nowrap"
                                            },

                                            searchBox: {
                                                border: 'none',
                                                'border-radius': '0px',
                                                width: '250px',
                                                overflow: "hidden"
                                            },
                                        }}
                                        showCheckbox
                                    />

                                </FormControl>
                            </Grid>


                        </Grid>
                        <Grid container gap={2} m={5}>
                            <FormControl variant="standard" sx={{ width: 650 }} size="small">
                                <TextField
                                    required
                                    name="primarySkills"
                                    label="Primary Skills"
                                    placeholder='Enter Primary Skills'
                                    variant="filled"
                                    multiline fullWidth
                                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                    defaultValue={getJobToUpdated?.data?.data[0]?.primarySkills}
                                    margin='normal'
                                    onChange={(e) => handleChange(e)} />
                            </FormControl>

                            <FormControl variant="standard" sx={{ width: 650 }} size="small">
                                <TextField
                                    required
                                    name="remark"
                                    label="Remark"
                                    variant="filled"
                                    defaultValue={getJobToUpdated?.data?.data[0]?.remark}
                                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                    margin='normal'
                                    multiline fullWidth
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container ml={5}>
                            <Grid item xs='auto'>
                                {(window.localStorage.getItem('role') === 'TA Manager' ||
                                    window.localStorage.getItem('role') === 'Hiring Manager' ||
                                    window.localStorage.getItem('role') === 'Admin') &&
                                    <Button
                                        variant="contained"
                                        type="submit"
                                    > Update </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </form >
            }
        </>
    )
}
export default UpdateJob;

