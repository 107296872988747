


import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select, Alert } from '@mui/material';
import TextField from '@mui/material/TextField';

const AddOnboardingDetails = ({ isOnboardingForm, setOnboardingForm, candidate }) => {

  const [open, setOpen] = useState(isOnboardingForm);
  const [form, setForm] = useState();
  const { shiftTimings, devices, candidateStatuses, backoutReasons } = useMetaData();
  const [joiningDetails, setJoiningDetails] = useState(candidate?.joiningDetails);
  const { updateCandidate } = useCandidates();
  const [showOnBoarding, setShowOnBoarding] = useState(true);
  const [backoutOnBoarding, setbackoutOnBoarding] = useState(true);
  const [onboardingStatus, setOnboardingStatus] = useState(candidateStatuses?.data?.data?.filter(el => el.order === 7 || el.order === 10))
  const handleChange = (e) => {

    if (e.target.name === 'candidateStatusId') {
      if (e.target.value === 'a5392787-669d-43cd-ba75-773c1a8ddc02') {

        setShowOnBoarding(false);
      } else {
        setShowOnBoarding(true);
      }
    }
    if (e.target.name === 'candidateStatusId') {
      if (e.target.value === 'c162cc5a-e812-43d3-967a-a6c2d913e5e5') {

        setbackoutOnBoarding(false);
      } else {
        setbackoutOnBoarding(true);
      }
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    form.joiningDetails = joiningDetails;
    updateCandidate.mutate({ id: candidate.candidateId, formData: form },
      {
        onSuccess: (data) => {
          alert('success')
          window.location.reload(false);
        }
      },
      {
        onError: (data) => {
          alert('error')
        }
      }
    )

  }

  return (
    <Box m>
      <Paper>
        <Dialog
          open={isOnboardingForm}
          aria-labelledby="responsive-dialog-title"
          maxWidth='xxl'>
          <DialogContent>
            <h2 style={{
              padding: '10px', backgroundColor: "cornflowerblue",
              fontWeight: 700, fontSize: "20px",
            }} > Add Onboarding Details </h2>
            <form
              onSubmit={(e) => handleSubmit(e)}

            >
              <Box sx={{
                padding: "10px",
                display: 'grid',
                gap: 4,
                gridTemplateColumns: 'repeat(5, 4fr)',
                border: '#4774ce'
              }}>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 3.5 }} size="small">
                  <InputLabel id="select-status-label">Status</InputLabel>
                  <Select
                    id="candidateStatusId"
                    size='small'
                    margin='normal'
                    required
                    name='candidateStatusId'
                    variant="filled"
                    defaultValue={candidate?.candidateStatusId}
                    onChange={(e) => handleChange(e)}
                  >
                    {onboardingStatus && onboardingStatus?.map((status) =>
                      <MenuItem key={status?.metaDataId} value={status?.metaDataId}>{status?.displayText?.status}</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <TextField
                    id="outlined-required"
                    label="office Mail"
                    variant="filled"
                    placeholder='Enter Office 365 Mail'
                    size='small'
                    margin='normal'
                    name='officeMail'
                    disabled={showOnBoarding}
                    defaultValue={candidate?.joiningDetails?.officeMail}
                    onChange={(e) => { setJoiningDetails({ ...joiningDetails, officeMail: e.target.value }) }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <InputLabel id="demo-simple-select-label">devices</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="devices"
                    label="devices"
                    name="devices"
                    sx={{ width: 250, height: 50 }}
                    style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                    defaultValue={candidate?.joiningDetails?.devices}
                    disabled={showOnBoarding}
                    onChange={(e) => { setJoiningDetails({ ...joiningDetails, devices: e.target.value }) }}
                  >
                    {devices?.data && devices.data.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.displayText.device}>{cc.displayText.device}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <TextField
                    required
                    id="outlined-required"
                    label="offered Designation"
                    variant="filled"
                    placeholder='Enter designation'
                    size='small'
                    margin='normal'
                    name='offeredDesignation'
                    disabled={showOnBoarding}
                    defaultValue={candidate?.joiningDetails?.offeredDesignation}
                    // onChange={(e) => handleChange(e)}
                    onChange={(e) => { setJoiningDetails({ ...joiningDetails, offeredDesignation: e.target.value }) }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 3.5 }} size="small">
                  <InputLabel id="demo-simple-select-label">shift_Timings</InputLabel>
                  <Select
                    labelId="shift_Timings"
                    label="shift_Timings"
                    id="shiftTimings"
                    variant="filled"
                    margin='normal'
                    name='shiftTimings'
                    required
                    defaultValue={candidate?.shiftTimings}
                    disabled={showOnBoarding}
                    onChange={(e) => handleChange(e)}
                  >
                    {shiftTimings?.data && shiftTimings?.data?.data.map((shiftTime) => {
                      return <MenuItem key={shiftTime.metaDataId} value={shiftTime.metaDataId}>{shiftTime.displayText.shiftTime}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                {
                  showOnBoarding === false ?
                    <>
                      <FormControl variant="standard" sx={{ width: 150, marginTop: 2, }} size="small">
                        <h3>Zoom Access</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="zoomAccess"
                          variant="filled"
                          defaultValue={candidate?.joiningDetails?.zoomAccess}
                          disabled={showOnBoarding}
                          // onChange={(e) => handleChange(e)}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, zoomAccess: e.target.value }) }}
                        >
                          <FormControlLabel value="true" control={<Radio required={true} />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                      </FormControl>
                      <FormControl variant="standard" sx={{ width: 150, marginTop: 2, }} size="small">
                        <h3>Ceipal Access</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="ceipalAccess"
                          variant="filled"
                          defaultValue={candidate?.joiningDetails?.ceipalAccess}
                          disabled={showOnBoarding}
                          // onChange={(e) => handleChange(e)}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, ceipalAccess: e.target.value }) }}
                        >
                          <FormControlLabel value="true" control={<Radio required={true} />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                      </FormControl>
                      <FormControl variant="standard" sx={{ width: 150, marginTop: 2, }} size="small">
                        <h3>Sim Card</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="simCard"
                          variant="filled"
                          defaultValue={candidate?.joiningDetails?.simCard}
                          disabled={showOnBoarding}
                          // onChange={(e) => handleChange(e)}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, simCard: e.target.value }) }}
                        >
                          <FormControlLabel value="true" control={<Radio required={true} />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </>
                    :
                    <>
                
                    </>
                }
                {
                  backoutOnBoarding === false ? 
                  <>
                        <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                        <InputLabel id="select-status-label">BackOut / Other Reason</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="backout_reason_id"
                          size='small'
                          margin='normal'
                          name='backoutReasonId'
                          defaultValue={candidate?.data?.data.backoutReasonId}
                          variant="filled"
                          onChange={(e) => handleChange(e)}
                       
                        >
                          {backoutReasons?.data && backoutReasons.data.data.map((backoutReason) =>
                            <MenuItem key={backoutReason.metaDataId} value={backoutReason.metaDataId}>{backoutReason.displayText.backout}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                  </>
                  :
                  <>
                  </>
                }

                <Box>
                  {(window.localStorage.getItem('role') === 'TA Manager' ||
                    window.localStorage.getItem('role') === 'Admin' ||
                    window.localStorage.getItem('role') === 'Recruiter') &&
                    <Button
                      variant="contained"
                      sx={{ marginRight: 2, marginTop: 2 }}
                      type="submit"
                    > Submit </Button>
                  }
                  <Button
                    variant="contained"
                    sx={{ marginRight: 2, marginTop: 2 }}
                    onClick={(e) => { setOnboardingForm(false) }}
                  >close</Button>
                </Box>
              </Box>

            </form>
          </DialogContent>
        </Dialog>
      </Paper>
    </Box>


  )
}

export default AddOnboardingDetails;