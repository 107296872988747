


import React, { useState, useContext } from 'react';
import './AddUser.css';
import { useMsal } from '@azure/msal-react';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from 'react-query';
import { createAppUser, updateAppUser } from '../../backendApis';
import SpinLoader from '../SpinLoader/SpinLoader';
import { useAdmin } from '../../helpers/hooks/adminHooks';
import { UserContext } from '../Routes/Routes';
import { Button, Card, CardContent, Dialog, DialogContent, Grid, TableCell, TextField, Typography, Box, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { Form } from 'react-router-dom';
import { useUsers } from '../../helpers/hooks/userHooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { width } from '@mui/system';

import Multiselect from 'multiselect-react-dropdown';
import Autocomplete from '@mui/material/Autocomplete';
import { displayName } from 'react-quill';




const AddUser = ({ setIsModalOpen, user, editMode, setEditMode, isModalOpen }) => {

  setIsModalOpen(true);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const value = useContext(UserContext);
  const { instance, accounts } = useMsal();
  const [form, setForm] = useState({});
  const queryClient = useQueryClient();
  const { roles } = useAdmin(value?.data?.role);

  const { useAppUsers } = useUsers();

  const appUsers = useAppUsers()
  const [taManagersUserId, settaManagersUserId] = useState([]);

  const [recruiterUserId, setRecruiterUserId] = useState([]);
  const [removeUserId, setRemoveUserId] = useState([]);
  const [search, setSearch] = useState([]);




  const { mutate, isLoading } = useMutation('createAppUser', createAppUser, {
    retry: false, onSuccess: () => {
      queryClient.invalidateQueries('fetchTenantUsers');
    },
    onError: (err) => {
      alert(err);
    },
  });

  const { mutate: updateUser, isLoading: updateLoading } = useMutation('updateAppUser', updateAppUser, {
    retry: false, onSuccess: () => {
      queryClient.invalidateQueries('fetchAppUsers');
    }, onError: (err) => {
      alert(err);
    }
  });

  if (isLoading || updateLoading) {
    return <SpinLoader />
  }




  const handleSelectTaManagersUserId = (e) => {
    let userid = e.map((a) => a.value)
    setRecruiterUserId(userid)

  }
  const handleRemoveTaManagersUserId = (e, value) => {
    let userid = e.map((a) => a.value)
    if (!removeUserId.some(el => el === value.value)) {
      setRemoveUserId(prev => [...prev, value.value]);
    }
  }


  const handleChange = (e) => {

    console.log(user.userId);
    console.log(editMode);



    if (editMode) {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      });
    } else {
      setForm({
        ...form,
        id: user.userId,
        displayName: user.displayName,
        mail: user.email,
        [e.target.name]: e.target.value
      });
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    let userIds = [];
    let removeId = [];


    const formData = new FormData();

    if (recruiterUserId.length > 0) {
      userIds.push(...recruiterUserId);
    }
    if (taManagersUserId.length > 0) {
      userIds.push(...taManagersUserId);
    }
    if (removeUserId.length > 0) {
      removeId.push(...removeUserId)
    }


    if (userIds.length && userIds.length > 0) {
      form.userIds = userIds;
    }

    if (removeId.length && removeId.length > 0) {
      form.removeId = removeId;
    }

    form.parentId = search.parentId;


    Object.keys(form).forEach(key => {
      formData.append(key, form[key]);
    });

    formData.append('userIds', JSON.stringify(userIds));
    formData.append('removeId', JSON.stringify(removeId));

    console.log(form);

    if (editMode) {


      if (user?.userId) {
        updateUser({ instance, accounts, formData: form, id: user.userId }, {
          onSuccess: (data) => {
            alert('success')
          }
        },);

        setIsModalOpen(false);
        setEditMode(false);
      }
      else {
        form.id = user.id;
        form.email = user.mail;
        form.displayName = user.displayName;
        if (form?.userIds?.length > 0 && form.parentId !== undefined) {
          mutate({ instance, accounts, formData: form }, {
            onSuccess: (data) => {
              alert('success')
            }
          });

          setIsModalOpen(false);
          setEditMode(false);

        } else {
          alert('Please add Role and Parent');
        }
      }

    }
  }

  console.log(user);
  return (

    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="responsive-dialog-title"
      maxWidth='xxl'
      open={isModalOpen}
      onClose={handleClose}>

      <DialogContent
        sx={{ width: 1200 }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Box
            sx={{
              padding: "10px",
              display: 'grid',
              border: '#4774ce'
            }}>

            <Grid item xs='auto' padding='1%'>
              <h3> User Details</h3>
            </Grid>

            <Grid item xs='auto' padding='1%'>
              <FormControl variant="standard" sx={{ width: 300, marginTop: 0 }} size="small">
                <InputLabel>Name</InputLabel>
                <TextField
                  sx={{ marginTop: '-10px', marginLeft: '250px', width: '750px' }}
                  variant='filled'
                  value={user.displayName ? user.displayName : user.displayName}
                  readOnly>
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs='auto' padding='1%'>
              <FormControl variant="standard" sx={{ width: 300, marginTop: 0 }} size="small">
                <InputLabel>Email</InputLabel>
                <TextField size='sm' sx={{ marginTop: '1px', marginLeft: '250px', width: '750px' }}
                  variant='filled'
                  value={user.mail ? user.mail : user.email}
                  readOnly>

                </TextField>
              </FormControl>
            </Grid>


            {/* {user?.roleAssignments &&

              <> */}

            {/* <Grid item xs='auto' padding='1%'>
                  <FormControl variant="standard" sx={{ width: 300, marginTop: 0 }} size="small">
                    <InputLabel>Role</InputLabel>
                    <Select size='sm' sx={{ marginTop: '1px', marginLeft: '250px', width: '750px' }}
                      name='role'
                      id="role"
                      variant='filled'
                      defaultValue={user?.roleAssignments[0]?.role?.roleId}
                      onChange={(e) => handleChange(e)}
                    >
                      {
                        roles?.data && roles?.data?.data?.map((role) => <MenuItem key={role?.roleId} value={role?.roleId}>{role?.roleName}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid> */}

            <Grid item xs='auto' padding='1%'>
              <FormControl variant="standard" sx={{ width: 300, marginTop: 0 }} size="small">
                <InputLabel>Role</InputLabel>
                <Multiselect
                  displayValue="key"
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e, value) => { handleRemoveTaManagersUserId(e, value) }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => { handleSelectTaManagersUserId(e) }}
                  options={roles?.data?.data?.map((x) => { return { value: x?.roleId, key: x?.roleName } })}
                  selectedValues={user?.roleAssignments?.map((x) => { return { value: x?.role?.roleId, key: x?.role?.roleName } })}
                  onChange={(e) => handleChange(e)}
                  style={{
                    chips: {
                      background: '1px solid blue',
                      // 'marginLeft': '250px'
                    },
                    multiselectContainer: {
                      color: '1px solid blue',
                      marginLeft: '250px'
                    },
                    searchBox: {
                      border: 'none',
                      'border-bottom': '1px solid blue',
                      'border-radius': '0px',
                      'width': '500px',
                      // 'marginLeft': '250px'
                    }
                  }}
                  showCheckbox
                />
              </FormControl>
            </Grid>
            {/* </>
            } */}

            <Grid item xs='auto' padding='1%'>
              {/* <FormControl variant="standard" sx={{ width: 300, marginTop: 0 }} size="small">

                <InputLabel>Parent</InputLabel>
                <Select size='sm' sx={{ marginTop: '1px', marginLeft: '250px', width: '750px' }}
                  name='parentId'
                  id="parent"
                  variant='filled'
                  defaultValue={user?.parentId}
                  onChange={(e) => handleChange(e)}
                >
                  {

                    appUsers?.data && appUsers.data.data.map((ta) => <MenuItem key={ta.userId} value={ta.userId}>{ta.displayName}</MenuItem>)
                  }
                </Select>
              </FormControl> */}

              <FormControl variant="standard" sx={{ width: 300, marginTop: 0 }} size="small">
                <InputLabel>Parent</InputLabel>
                <Autocomplete
                  name='parentId'
                  id="parent"
                  variant='filled'
                  // value={appUsers?.data?.data.filter((user) => user.userId === user?.parentId)? user.displayName : ''}
                  defaultValue={user?.parent?.displayName}
                  options={appUsers?.data?.data.map((user) => ({ label: user?.displayName, value: user?.userId }))}
                  sx={{ width: 300 }}
                  style={{ marginBottom: '20px', marginRight: "20px", marginLeft: '250px' }}
                  renderInput={(params) => <TextField {...params} label="Users" />}
                  onChange={(e, parent) => {
                    setSearch({ ...search, parentId: parent?.value })
                  }}

                />
              </FormControl>



            </Grid>

            <Grid item xs='auto' padding='1%'>
              <Button style={{ textAlign: "center" }} variant="contained" sx={{
                borderRadius: 3, width: 100, height: 40,
                color: 'primary', marginLeft: '250px', marginTop: '20px'

              }} type='submit' name='userAdd' value={editMode ? 'Update' : 'Add User'} >Save</Button>


              <Button style={{ textAlign: "center" }} variant="contained" sx={{
                borderRadius: 3, width: 100, height: 40,
                color: 'primary', marginLeft: '250px', marginTop: '20px'
              }}
                type='button' onClick={() => {
                  setIsModalOpen(false);
                  setEditMode(false);
                }} >Close</Button>
            </Grid>

          </Box>
        </form>
      </DialogContent>
    </Dialog>

  )
}

export default AddUser;