import { useMsal } from '@azure/msal-react';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { acquireToken } from '../acquireToken';

let baseURL;

if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseURL = 'https://berec.futransolutions.com';
} else if(process.env.REACT_APP_NODE_ENV === 'test'){
    baseURL = 'https://hr-app-test-be.futransolutions.com';
}
else {
    baseURL = 'http://localhost:5000';
}

export const useSalary = () => {
    const { instance, accounts } = useMsal();
    const role = window.localStorage.getItem('role');
    
    const generateOffer = useMutation(
        async (generateOffer) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post('/download', generateOffer);
            return data;
        },
        {
            onError: (e) => {
                return alert(e.response.data.message);
            }
        }
    )


    // !  uploading offerLetter Template on sharepoint id 
    const shareOffertosharepoint = useMutation(
        async (uploadOfferLetterToSharePoint) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post('/uploadOfferLetter', uploadOfferLetterToSharePoint);
            return data;
        }
    )


    const sendOfferletterviaEmail = useMutation(
        async (reqInfo) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post(`/sendOfferLetter`, reqInfo.formData);
            return data;
        }, {
        onSuccess: () => {
            alert('Sent Successfully')
        }
    }
    )

    // send custom offer letter to the candidate by an email 

    const sendcustomOfferletterviaEmail = useMutation(
        async (formData) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post(`/sendcustomOfferletter`, formData.formData);
            return data;
        }, {
        onSuccess: () => {
            alert('Sent Successfully')
        }
    }
    )

// offerLetter percentage calcultor
     const offerLetterpercentagecalcultorHook = useMutation(
        async (offerLetterpercentagecalculator) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post('/OfferLetterCalcultor', offerLetterpercentagecalculator);
            return data;
        }
     )
    
    //  update the percentage of offerLetter calculator 

    const updateofferLetterpercentagecalcultorHook = useMutation(
        async (offerLetterpercentagecalculator) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post('/updateOfferLetterCalcultor', offerLetterpercentagecalculator);
            return data;
        }
    )

    // get the percentage of offerletter calculator
    const GetpercentagecalulatebyId = (candidateId) => useQuery([candidateId], async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
    
            }
        });
        const { data } = await client.get(`/OfferLetterCalculator/${candidateId}`);
        return data;
    });

    return {
        generateOffer,
        shareOffertosharepoint,
        sendOfferletterviaEmail,
        sendcustomOfferletterviaEmail,
        offerLetterpercentagecalcultorHook,
        updateofferLetterpercentagecalcultorHook,
        GetpercentagecalulatebyId

    }

}