import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import { useMsal, useIsAuthenticated, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const ProtectedRoutes = ({isAuthenticated, children, isAuthorized, redirectPath}) => {

    // if(!isAuthenticated) {
    //     return (
        
    //     );
    // }



    // return 

    return <>
        <UnauthenticatedTemplate>
            <Navigate to='/login' replace />;
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
            {children ? children : <Outlet />}
        </AuthenticatedTemplate>
    </>
}

export default ProtectedRoutes;