import React, {useContext, useEffect} from 'react';
import './Login.css';
import { useMsal, useIsAuthenticated, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../components/Routes/Routes';
import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';    

const Login = () => {
    const value = useContext(UserContext);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    const { login, result, error } = useMsalAuthentication(InteractionType.Silent.loginRequest);
    console.log('Login Initialted', isAuthenticated);
    // if(isAuthenticated && instance.inProgress !== InteractionStatus.Startup) {
    //     // if (value.data.role) {
    //     //     return <Navigate to='/candidates' /> 
    //     // } else {
    //         return <Navigate to='/' />
    //     // }
    // }
    


    function handleLogin(instance) {
        // instance.loginPopup(loginRequest)
        login(InteractionType.Redirect, loginRequest)
        .then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
    <div className='login-container'>
        <p className='login-container__content'>Proceed with login</p>
        <button className='login-block__button' onClick={() => handleLogin(instance)}>
            Login
        </button>
        <AuthenticatedTemplate><Navigate to='/' /></AuthenticatedTemplate>
    </div>
    )
}

export default Login;