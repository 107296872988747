import { useMsal } from '@azure/msal-react';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useAlert, positions } from 'react-alert'
import axios from 'axios';
import { acquireToken } from '../acquireToken';
import moment from 'moment';
let baseURL;

if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseURL = 'https://berec.futransolutions.com';
} else if(process.env.REACT_APP_NODE_ENV === 'test'){
    baseURL = 'https://hr-app-test-be.futransolutions.com';
}
else {
    baseURL = 'http://localhost:5000';
}

export const useJobs = (filter) => {
    const alert1 = useAlert()
    const {instance, accounts} = useMsal();
    const queryClient = useQueryClient();
    const role = window.localStorage.getItem('role');

    const useGetJobDetails = () => useQuery('jobsdata', async() => {
        let query;
        const token  = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
            'Authorization': `Bearer ${token}`,
            'user-info': role
 
 
            }
        });
        const { data } = await client.get(`/getAllJobRequisitions${query ? query : ''}`);
        return data;
    });

    // const useGetJobs = (filter) => useQuery('jobs', async () => {
    //     let query;
    //     let keys = Object.keys(filter);

    //     if (keys.length > 0 && keys.length === 1) {
    //         if (keys[0] === 'startDate' || keys[0] === 'endDate') {
    //             query = `?${keys[0]}=${moment(filter[keys[0]]['$d']).format('YYYY-MM-DD')}`;

    //             console.log(query);
    //         } else {
    //             query = `?${keys[0]}=${filter[keys[0]]}`;
    //         }
    //     } else if (keys.length > 0 && keys.length > 1) {
    //         query = '?';
    //         for (let i = 0; i < keys.length; i++) {
    //             if (keys[i] === 'startDate' || keys[i] === 'endDate') {
    //                 query += `${keys[i]}=${moment(filter[keys[i]]['$d']).format('YYYY-MM-DD')}&`
    //             } else {
    //                 query += `${keys[i]}=${filter[keys[i]]}&`
    //             }
    //         }
    //     }


    //     const token = await acquireToken(instance, accounts);

    //     const client = axios.create({
    //         baseURL: baseURL,
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'user-info': role

    //         }
    //     });
    //     const { data } = await client.get(`/jobRequisitions${query ? query : ''}`);

    //     return data;
    // }, {
    //     getNextPageParam: (lastPage, allPages) => {
    //         const maxPages = lastPage.data.count / 30;
    //         const nextPage = allPages.length + 1;
    //         return nextPage <= maxPages ? nextPage : undefined;
    //     },
    // });

    const useGetJobs = (filter) => useInfiniteQuery('jobsList', async ({ pageParam = 1 }) => {
        let query;
        filter.page = pageParam;
        let keys = Object.keys(filter);
        if (keys.length > 0 && keys.length === 1) {
            query = `?${keys[0]}=${filter[keys[0]]}`;
        } else if (keys.length > 0 && keys.length > 1) {
            query = '?';
            for (let i = 0; i < keys.length; i++) {
                query += `${keys[i]}=${filter[keys[i]]}&`
            }
        }
        const token = await acquireToken(instance, accounts);
 
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get(`/jobRequisitions${query ? query : ''}`);
 
        return data;
    }, {
        getNextPageParam: (lastPage, allPages) => {
            const maxPages = lastPage.data.count / 20;
            const nextPage = allPages.length + 1;
            return nextPage <= maxPages ? nextPage : undefined;
        },
    });


    const useJobsWithRecruiter = () => useQuery('jobs', async () => {
        const token  = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
            'Authorization': `Bearer ${token}`,
            'user-info': role

            }
        });
        const { data } = await client.get('/getAllJobRequisitions');
        
        return data;
    });

    const createJobRequisition = useMutation(
        async (jobRequisition) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

                }
            });
            const { data } = await client.post('/jobRequisition', jobRequisition);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
            },
            // onSuccess: (addedJobRequisiton) => {
            //     queryClient.setQueryData('jobs', (currentJobRequisitions) => {
            //         return {
            //             ...currentJobRequisitions,
            //             pages: currentJobRequisitions.pages.map((page, i) => {
            //                 if (i === 0) {
            //                     return {
            //                         status: 'success', 
            //                         statusCode: 200, 
            //                         message: 'fetched successfully', 
            //                         // data: [addedJobRequisiton.data, ...currentJobRequisitions.data]
            //                         data: {
            //                             count: page.data.count,
            //                             rows: [addedJobRequisiton.data, ...page.data.rows]
            //                         }
            //                     }
            //                 }
            //                 return page;
            //             })
            //         };
            //     });
            // },
            onError: (e) => {
                alert(e.response.data.message);
            }
        },
        // {
        //     onSuccess: () => {
        //         queryClient.invalidateQueries(['jobs']);
        //     }
        // },
        // {
        //     onSuccess: (addedJobRequisiton) => {
        //         queryClient.setQueryData('jobs', (currentJobRequisitions) => {
        //                 return {
        //                     status: 'success', 
        //                     statusCode: 200, 
        //                     message: 'fetched successfully', 
        //                     data: [addedJobRequisiton.data, ...currentJobRequisitions.data]
        //                 }
        //             }
        //         );
        //     },
        // }
    );
//!----------------------------------------------------------------------------------------------------


    const GetjobToUpdate = (jobId) => useQuery(['jobs', jobId], async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get(`/jobRequisition/${jobId}`);
        return data;
    });


//!----------------------------------------------------------------------------------------------------
    const updateJobRequisition = useMutation(
        async (reqInfo) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

                }
            });
            const { data } = await client.put(`/jobRequisition/${reqInfo.id}`, reqInfo.formData);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
            },
        }
    );
    const uploadJobDocuments = useMutation(
        async (reqInfo) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post(`/uploadJobDocuments`, reqInfo.formData);
            return data;
        },
        {
            onSuccess: (updatedJobDocument) => {
                alert1.removeAll();
                queryClient.setQueryData(['jobs', updatedJobDocument.data.jobId], (currentJob) => {
                    return {
                        status: 'success',
                        statusCode: 200,
                        message: 'job fetched successfully',
                        data: updatedJobDocument.data
                    }
                });
            }
        }
    );

    const deleteJobDocument = useMutation(
        async (reqInfo) => {
            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role

                }
            });
            const { data } = await client.post(`/uploadJobDocuments`, reqInfo.formData);
            return data;
        },
        {
            onSuccess: (updatedJobDocument) => {
                queryClient.setQueryData(['jobs', updatedJobDocument.data.jobId], (currentCandidate) => {
                    return {
                        status: 'success',
                        statusCode: 200,
                        message: 'Job fetched successfully',
                        data: updatedJobDocument.data
                    }
                });
            }
        }
    );


    const useJobRequisitions = () => useQuery('recruitersJob', async() => {
        const token  = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
            'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await client.get(`/getJobAssignedRecruiter`);
        return data;
    })

    const useGetJobTitle = () => useQuery('jobTitles', async() => {
        let query;
        query = '?projection=jobTitle,jobCode,status,jobId,company'
        const token  = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
            'Authorization': `Bearer ${token}`,
            'user-info': role


            }
        });
        const { data } = await client.get(`/getAllJobRequisitions${query ? query : ''}`);
        return data;
    });

    
    return {
        useGetJobDetails,
        createJobRequisition,
        updateJobRequisition,
        useJobsWithRecruiter,

        useJobRequisitions,
        GetjobToUpdate,
        useGetJobs,
        uploadJobDocuments,
        deleteJobDocument,
        // assignedRecruiters,
        useGetJobTitle

    }
}