import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SpinLoader from '../../components/SpinLoader/SpinLoader';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useUsers } from '../../helpers/hooks/userHooks';
import { UserContext } from '../../components/Routes/Routes';


import ReactQuill from 'react-quill';
import { Box } from '@mui/system';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import { useAlert, positions } from 'react-alert'
import TableContainer from '@mui/material/TableContainer';
import Multiselect from 'multiselect-react-dropdown';

const Candidates = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [afterSelectStatuses, setAfterSelectStatuses] = useState(null);
  const value = useContext(UserContext);
  const [search, setSearch] = useState({});
  const navigate = useNavigate();
  const { useGetCandidates, createCandidate, updateCandidate, uploadDocuments } = useCandidates();
  const candidates = useGetCandidates(search);
  const { sources, jobLocations, backoutReasons, gender, candidateStatuses } = useMetaData();
  const { useRecruiters } = useUsers(value.data.role);

  const recruiters = useRecruiters()
  const [form, setForm] = useState();
  const [company, setCompany] = useState(null);
  const [remark, setRemark] = useState(null);
  const [filter, setFilter] = useState(null);
  const [jobTitle, setJobTitle] = useState([]);
  const [jobCode, setjobCode] = useState(null);
  const { useGetJobTitle } = useJobs();
  const getJobTitle = useGetJobTitle();


  const [open, setOpen] = useState();
  const [value1, setValue1] = useState();
  const alert1 = useAlert()
  const [currentStatus, setCurrentStatus] = useState();
  const [showOthers, setShowOthers] = useState(false)


  let job = getJobTitle?.data?.data?.filter((el) => el?.status === 'Active');


 function handleKeyDown(e) {
    if (e.key === 'Enter') {
      candidates.refetch();
    }
  }

  function Reset() {
    setSearch({});
    window.location.reload(false);
  }

  function getCandidate(pages, id) {
    for (let i = 0; i < pages.length; i++) {
      for (let j = 0; j < pages[i].data.rows.length; j++) {
        if (pages[i].data.rows[j].candidate_id === id) {
          return pages[i].data.rows[j];
        }
      }
    }
  }

  function useHookWithRefCallback() {
    let fetching = false;
    const onScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && ((scrollHeight - scrollTop) <= (clientHeight * 2.5))) {
        fetching = true;
        if (candidates.hasNextPage) await candidates.fetchNextPage();
        fetching = false;
      }
    };
    const ref = useRef(null);
    const setRef = useCallback(node => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", onScroll);
      }

      if (node) {
        node.addEventListener("scroll", onScroll);

      }

      ref.current = node
    }, [candidates.hasNextPage]);

    return [setRef];
  }

  const [ref] = useHookWithRefCallback();

  useEffect(() => {

  }, [candidateStatuses?.data?.data, showOthers]);


  if (candidates.isFetching && !candidates.isFetchingNextPage) {
    return <SpinLoader />
  }

  if (candidates.isLoading || candidateStatuses.isLoading || getJobTitle.isLoading || updateCandidate.isLoading, recruiters.isLoading) {
    return <SpinLoader />
  }

  if (uploadDocuments.isLoading) {
    alert1.show(form?.file?.name, { position: positions.BOTTOM_RIGHT });
  }


  // function Reset() {
  //   setSearch({});
  // }

  const handleSelectJobsColumns = (e) => {
    let value = e.map((a) => a.value)
    setSearch({ ...search, job: value })
    setJobTitle(e)
  }
  const handleRemoveJobsColumns = (e) => {
    let value = e.map((a) => a.value)
    setSearch({ ...search, job: value })
    setJobTitle(e)
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setjobCode(null);
    setCompany(null);
    setRemark(null);
  };

  const handleChange = (e) => {


    if (e.target.name === 'sourceId') {
      console.log('hit')
      if (e.target.value === sources?.data?.data.filter((source) => source?.displayText?.source === 'Other')[0]?.metaDataId) {
        setShowOthers(true)
      } else {
        setShowOthers(false)
      }
    }


    if (e.target.name === 'candidateStatusId') {
      setCurrentStatus(e.target.value);
    }


    if (e.target.name === 'jobId') {
      if (e.target.value === '') {
      } else {
        let job = getJobTitle?.data?.data?.filter((job) => job.jobId === e.target.value)[0]
        setCompany(job?.company);
        setjobCode(job?.jobCode);
      }
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handlesubmit = (e) => {
    e.preventDefault();
    form.company = company;
    form.remark = remark;

    const formData = new FormData();
    Object.keys(form).forEach(key => {
      formData.append(key, form[key]);
    });


    if (parseInt(form.expected_ctc) <= parseInt(form.current_ctc)) {
      alert('Current CTC should be less than Expected CTC');
      return;
    }
    if (!form.remark) {
      alert('Please add candidate remark');
      return;
    }



    console.log(form);

    if (form?.file) {
      createCandidate.mutate(form,
        {
          onSuccess: (data) => {
            console.log(data?.data.candidateId)
            formData.append('candidateId', data.data.candidateId);
            formData.append('documentName', 'resume');
            uploadDocuments.mutate({ formData }, {
              onError: (e) => {
                alert(e.response.data.message);
              }
            });

            setOpen(false)

          }

        },
        {
          onError: (data) => {
            alert('error')
          }
        }
      )


    } else {
      alert("Please add attachment")
    }

  }


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  if (getJobTitle.isLoading || getJobTitle.isFetching) {
    return <SpinLoader />
}


  return (
    <>
      <Box ml={12} mt={17} sx={{ boxShadow: 1 }} >

      <FormControl sx={{ml:2}}>
          <Multiselect
            placeholder='Job Title'
            hidePlaceholder={true}
            displayValue="key"
            onKeyPressFn={function noRefCheck() { }}
            onSelect={(e) => { handleSelectJobsColumns(e) }}
            onRemove={(e) => { handleRemoveJobsColumns(e) }}
            onKeyDown={(e) => handleKeyDown(e)}
            onSearch={function noRefCheck() { }}
            options={getJobTitle?.data?.data?.map((el) => { return { key: el.jobTitle, value: el.jobId } })}
            selectedValues={jobTitle}
            style={{
              multiselectContainer: {
                zIndex: 888,
                "white-space": "nowrap",
              },
              searchBox: {
                margin: '5px 15px 0 0',
                width: '300px',
                height: '50px',
                zIndex: 999,
                overflow: "hidden"
              },
            }}
            showCheckbox
          />
        </FormControl>


        <FormControl>
          <InputLabel id="demo-simple-label">Status</InputLabel>
          <Select
            labelId="demo-simple-label"
            id="candidate_status_id"
            label="status"
            name='status'
            value={search.status ? search.status : ''}
            onKeyDown={(e) => handleKeyDown(e)}
            sx={{ width: 200, height: 55 }}
            style={{ marginBottom: '20px', marginRight: "20px" }}
            onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}>
            {candidateStatuses?.data && candidateStatuses.data.data.map(status =>
              <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.status}</MenuItem>
            )}
          </Select>
        </FormControl>


        <TextField
          type="text"
          name="keyword"
          label=""
          placeholder='search'
          onKeyDown={(e) => handleKeyDown(e)}
          sx={{ width: 200, height: 55 }}
          value={search?.keyword ? search?.keyword : ''}
          variant='outlined'
          onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })} />

        <Button
          variant="contained"
          endIcon={<PersonSearchTwoToneIcon />}
          sx={{ borderRadius: 3 }}
          style={{
            padding: "10px",
            marginBottom: '10px',
            marginLeft: '50px'
          }}
          onClick={(e) => { candidates.refetch() }}>Search</Button>

        <Button variant="contained"
          sx={{ borderRadius: 3 }}
          style={{ padding: "10px", marginBottom: '10px', marginLeft: '20px' }}
          onClick={(e) => { Reset({}) }} > Reset </Button>



        { (window.localStorage.getItem('role') !== 'HR' && window.localStorage.getItem('role') !== 'HR Manager') &&
          <>
            <Button variant="contained"
              endIcon={<AddIcon />}
              sx={{ borderRadius: 3 }}
              style={{
                padding: "10px",
                marginBottom: '10px',
                marginLeft: '20px'
              }}
              onClick={handleClickOpen}> Add Candidate </Button>
          </>}


        <Box sx={{ background: 'white', width: 'auto', height: '90vh', display: 'flex', flexDirection: 'column',mt:6 }}>
          <TableContainer ref={ref} sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead style={{ backgroundColor: "#243c80", background: "white" }}>

                <TableRow
                  sx={{
                    "& th": {
                      borderBottom: "2px solid black",
                      fontSize: "0.9rem",
                      color: "white",
                      backgroundColor: "#243c80",
                      borderLeft: "1px solid #3b4864",
                      height: '1px'
                    }
                  }}
                >
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Email</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Job Title</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Candidate Status</TableCell>

                  {(window.localStorage.getItem('role') === 'TA Manager' || window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') === 'HR Manager') &&
                    <>
                      <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>HR</TableCell>
                    </>}
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Edit</TableCell>
                  {(window.localStorage.getItem('role') !== 'HR' || window.localStorage.getItem('role') !== 'HR Manager' || window.localStorage.getItem('role') !== 'Hiring Manager') &&
                    <>
                      <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Scheduling</TableCell>
                    </>}

                </TableRow>
              </TableHead>

              <TableBody>
                {candidates?.data && candidates?.data.pages.map((page) => page.data.rows.map((candidate) => {
                  return <TableRow hover
                    key={candidate.candidateId}>
                    <TableCell>{candidate.candidateName}</TableCell>
                    <TableCell>{candidate.candidateEmail}</TableCell>
                    <TableCell>{candidate.jobTitle?.jobCode} - {candidate.jobTitle?.jobTitle}</TableCell>
                    <TableCell>{candidate.candidateStatus?.displayText?.status}</TableCell>


                    {(window.localStorage.getItem('role') === 'TA Manager' || window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') === 'HR Manager') &&
                      <>
                        <TableCell>{candidate?.hr?.displayName}</TableCell>
                      </>}

                    <TableCell>  <EditIcon onClick={(e) => {
                      navigate(`/candidate/${candidate.candidateId}`);
                    }} /></TableCell>


                    {(window.localStorage.getItem('role') !== 'HR' || window.localStorage.getItem('role') !== 'HR Manager' || window.localStorage.getItem('role') !== 'Hiring Manager') &&
                      <>
                        <TableCell>  <AccountCircleIcon onClick={(e) => {
                          navigate(`/candidate/${candidate.candidateId}/interview`)
                        }} /></TableCell>
                      </>}


                  </TableRow>
                }
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth='xxl'>
            <DialogContent>
              <h2 style={{
                padding: '5px', backgroundColor: "cornflowerblue",
                fontWeight: 700, fontSize: "20px",
              }} > Add Candidate Details </h2>
              <form onSubmit={(e) => handlesubmit(e)}>
                <Box sx={{
                  padding: "10px",
                  display: 'grid',
                  gap: 3,
                  gridTemplateColumns: 'repeat(4, 3fr)',
                  border: '#4774ce'
                }}>
                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField

                      labelId="input-candidate-name-label"
                      id="outlined-required"
                      label="Candidate Name"
                      placeholder='Enter Candidate Name'
                      size='small'
                      margin='normal'
                      name='candidateName'
                      variant="filled"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-candidate-email-label"
                      id="outlined-required"
                      label="Candidate Email"
                      variant="filled"
                      placeholder='Enter email'
                      size='small'
                      margin='normal'
                      name='candidateEmail'
                      type="email"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-candidate-phone-number"
                      id="outlined-required"
                      label="Candidate Phone Number"
                      placeholder='Enter Candidate Phone Number'
                      size='small'
                      margin='normal'
                      type='number'
                      InputProps={{ inputProps: { min: "999999999", max: "9999999999", step: "1" } }}
                      name='candidatePhone'
                      variant="filled"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    Is Candidate Fresher?
                    <RadioGroup
                      row
                      aria-labelledby="input-radio-buttons-group-fresher-label"
                      name="candidateType"
                      required
                      onChange={(e) => handleChange(e)}

                    >
                      <FormControlLabel value="true" control={<Radio required={true} />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio required={true} />} label="No" />
                    </RadioGroup>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300, mt: 2 }} size="small">

                    <InputLabel id="select-gender-label">Gender</InputLabel>
                    <Select
                      labelId="select-gender-label"
                      id="demo-simple-select"
                      label="Gender"
                      size='small'
                      margin='normal'

                      variant="filled"
                      name='gender'

                      required
                      onChange={(e) => handleChange(e)}
                    >
                      {gender?.data && gender?.data.data.map((gen) =>
                        <MenuItem key={gen.metaDataId} value={gen.metaDataId}>{gen.displayText.gender}</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300}} size="small">
                    <Autocomplete
                      disablePortal
                      id="jobFilter"
                      name="jobId"
                      required
                      size='small'
                      margin='normal'
                      options={job?.map((job) =>
                        ({ key: 'jobId', value: job?.jobId, label: job?.jobTitle })
                      )}

                      // options={jobs.data?.data.map((job) =>

                      //   job.status === 'Active' ?

                      //     ({ key: 'jobId', value: job.jobId, label: job.jobTitle })
                      //     :
                      //     null
                      // )}
                      sx={{ width: 300 }}
                      style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                      renderInput={(params) => <TextField {...params} variant="filled" size='small' margin='normal' label="Job-Title" />}

                      onChange={(e, value) => {
                        setForm({ ...form, jobId: value.value })
                        let job = getJobTitle?.data?.data?.filter((job) => job.jobId === value.value)[0]
                        setCompany(job?.company);
                        setjobCode(job?.jobCode);
                      }}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300}} size="small">
                    <TextField
                      labelId="input-candidate-name-label"
                      id="outlined-required"
                      label="Job Code"
 
                      placeholder='Enter Job Code'
                      size='small'
                      margin='normal'
                      name='jobCode'
                      variant="filled"
                      value={jobCode ? jobCode : ''}
                      disabled
                      // defaultValue={getJobToUpdated?.data?.data[0]?.jobCode}
                      // onChange={(e) => handleChange(e)}
                      // required
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-candidate-name-label"
                      id="outlined-required"
                      label="Company Name"
                      placeholder='Enter Company Name'
                      size='small'
                      margin='normal'
                      variant="filled"
                      name='company'
                      value={company ? company : ''}
                      onChange={(e) => handleChange(e)}
                      disabled={true}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-candidate-current_ctc-label"
                      id="outlined-required"
                      label="Current CTC"
                      placeholder='Enter Current CTC'
                      InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }} v
                      size='small'
                      margin='normal'
                      type='number'
                      variant="filled"
                      name='currentCTC'

                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">

                    <TextField
                      required
                      labelId="input-candidate-expected-label"
                      id="outlined-required"
                      label="Expected CTC"
                      placeholder='Enter Expected CTC'
                      InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }}
                      size='small'
                      margin='normal'
                      variant="filled"
                      type='number'
                      name='expectedCTC'

                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-notice-days-label"
                      id="outlined-required"
                      label="Notice period in Days"
                      placeholder='Enter Notice period in days'
                      size='small'
                      margin='normal'
                      variant="filled"
                      type='number'
                      name='noticePeriodInDays'
                      // defaultValue={candidate?.data?.data.notice_period_in_days}
                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-total-experience-label"
                      id="outlined-required"
                      label="Total Experience"
                      placeholder='Enter Total Experience'
                      size='small'
                      margin='normal'
                      variant="filled"
                      name='totalExperience'
                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField
                      required
                      labelId="input-total-experience-label"
                      id="outlined-required"
                      label="Relevant Experience"
                      placeholder='Enter Total Experience'
                      size='small'
                      margin='normal'
                      variant="filled"
                      name='relevantExperience'
                      onChange={(e) => handleChange(e)}
                    />

                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300, mt: 2}} size="small">
                    <InputLabel id="select-status-label">Status</InputLabel>
                    <Select
                      labelId="select-status-label"
                      id="select-status-label"
                      size='small'
                      margin='normal'
                      variant="filled"
                      name='candidateStatusId'
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      {candidateStatuses?.data && candidateStatuses?.data?.data.slice(0, 2).map((status) =>
                        <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.status}</MenuItem>

                      )}
                    </Select>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    Candidate LWD
                    <TextField
                      inputFormat="DD/MM/YYYY"
                      type='date'
                      size='small'
                      name="candidateLastWorkingDate"
                      value={value1}
                      disabled={currentStatus !== 'c3db3358-9c57-47b5-9e53-967d0a151541'}
                      onChange={(e) => handleChange(e)}
                    >
                    </TextField>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300, }} size="small">
                    Identified date
                    <TextField
                      required
                      inputFormat="DD/MM/YYYY"
                      type='date'
                      name="identifiedDate"
                      value={value1}
                      disabled={currentStatus !== 'c3db3358-9c57-47b5-9e53-967d0a151541'}
                      size='small'
                      onChange={(e) => handleChange(e)}
                    >
                    </TextField>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    Calling Date
                    <TextField
                      inputFormat="DD/MM/YYYY"
                      type='date'
                      name="candidateCallingDate"
                      value={value1}
                      size='small'
                      onChange={(e) => handleChange(e)}
                    >

                    </TextField>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300, mt: 3 }} size="small">

                    Upload Resume
                    <TextField
                      id='attachment_path'
                      name='file'
                      required type="file"
                      size='small'
                      accept=".doc,.docx,application/pdf"
                      onChange={(e) => {
                        if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
                          setForm({ ...form, [e.target.name]: e.target.files[0] });
                        } else {
                          alert('Document type should be PDf or Word only');
                          e.target.value = ''
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 320, marginTop: 2, }} size="small">
                    Job Location
                    <RadioGroup
                      row
                      aria-labelledby="input-radio-buttons-group-job-location-label"
                      name="jobLocationId"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      {jobLocations?.data && jobLocations.data.data.map((location) =>
                        <>
                          <FormControlLabel value={location.metaDataId} control={<Radio required={true} />} label={location.displayText.location} />
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    sx={{ width: 300, marginTop: 2, }}
                    size="small">
                    Source

                    <RadioGroup
                      row
                      aria-labelledby="input-radio-buttons-group-source-label"
                      name="sourceId"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      {sources?.data && sources.data.data.map((source) =>
                        <>

                          <FormControlLabel value={source.metaDataId} control={<Radio required />} label={source.displayText.source} />
                        </>
                      )}
                      {/* <FormControlLabel value='other' control={<Radio name="source_id" type="radio" onClick={(e) => setShowOthers(true)} />} label='Other' /> */}
                    </RadioGroup>
                    {
                      showOthers === true &&

                      <>
                        <TextField id='otherSource'
                          name='otherSource'
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Other Source"
                          required
                        />
                      </>}
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <TextField

                      labelId="input-candidate-Linkedin-label"
                      id="outlined-required"
                      label="Candidate Linkedin Profile Link"
                      placeholder='Enter Linkedin Profile Link'
                      size='small'
                      margin='normal'
                      name='linkedinLink'
                      variant="filled"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  <TextField
                      required
                      labelId="input-candidate-Linkedin-label"
                      id="outlined-required"
                      placeholder='Distance from Office in Kms'
                      size='small'
                      margin='normal'
                      name='distanceFromOffice'
                      variant="filled"
                      onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                </Box>
              
                <>

                  <label>Remark</label>

                  <ReactQuill theme="snow" value={remark} onChange={setRemark} placeholder="Write Something"
                    style={{ height: '100px', marginTop: "10px", marginBottom: '40px' }} />
                </>

                <Button sx={{ ml: '20px', mt: '20px' }} size="small" variant="contained" color="success" type='submit'>
                  Submit
                </Button>
                <Button size="small" sx={{ ml: '20px', mt: '20px' }} endIcon={<CloseIcon />} variant="contained" color="primary" type='button'
                  onClick={handleClose}
                >Close</Button>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </Box>

    </>
  )
}

export default Candidates;