import React, { useState, useEffect, useRef, useContext } from 'react';

import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { useUsers } from '../../helpers/hooks/userHooks';

import SpinLoader from '../../components/SpinLoader/SpinLoader';

//!--------------------MUI------------
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useSalary } from '../../helpers/hooks/salaryViewHooks';
import SalaryView from '../../components/SalaryView/SalaryView';



const UpdateCandidateSalaryView = () => {
  const theme = useTheme();
  const location = useLocation();
  const candidateId = location.pathname.split('/')[2];
  const { useCandidate } = useCandidates();
  const candidate = useCandidate(candidateId);
  const [form, setForm] = useState();
  const { useGetJobTitle } = useJobs();
  const getJobTitle = useGetJobTitle();
  const { useHR, useHiringManagers, useTaManager } = useUsers()
  const HR = useHR()
  const [showSalaryStructure, setShowSalaryStructure] = useState(false)
  const [generateOfferLetterType, setGenerateOfferLetterType] = useState("true");
  const { annexureFormulae, variablePayDuration, bonusPayDuration, probationPeriod } = useMetaData();
  const { sendOfferletterviaEmail } = useSalary();
  const jsonData = annexureFormulae?.data?.data?.map((el) => el.displayText)
  const [isFresher, setIsFresher] = useState(candidate?.data?.data?.candidateType);
  const [manualOfferLetterReason, handleManualOfferLetterReason] = useState(null);

  const handleChange = (e) => {

    setForm({
      ...form,
      [e.target.name]: e.target.value
    })

  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  useEffect(() => {
  }, [candidate?.data?.data, generateOfferLetterType]);



  const toggleSalaryView = () => {
    setShowSalaryStructure(!showSalaryStructure);
  };


  //list of variables
  let values = {};

  let caluculatedValues = {};
  const createFormula = (jsonData) => {
    const keys = Object.keys(caluculatedValues);
    const id = jsonData.id;
    let value1 = jsonData.value1;
    let value2 = jsonData.value2;
    let value3 = jsonData.value3;
    let value4 = jsonData.value4;
    const operator1 = jsonData.Operator1;
    const operator2 = jsonData.Operator2;
    const operator3 = jsonData.Operator3;
    const formula = jsonData.Formula

    if (keys.length && keys.length > 0) {
      if (typeof value1 === 'string') {
        let index = keys.findIndex((key) => key === value1)
        if (index !== -1) {
          value1 = caluculatedValues[keys[index]]
        }
      }

      if (typeof value2 === 'string') {
        let index = keys.findIndex((key) => key === value2)
        if (index !== -1) {
          value2 = caluculatedValues[keys[index]]
        }
      }

      if (typeof value3 === 'string') {
        let index = keys.findIndex((key) => key === value3)
        if (index !== -1) {
          value3 = caluculatedValues[keys[index]]
        }
      }

      if (typeof value4 === 'string') {
        let index = keys.findIndex((key) => key === value4)
        if (index !== -1) {
          value4 = caluculatedValues[keys[index]]
        }
      }

    }

    if (jsonData.condition) {
      const condition = jsonData.condition;
      const trueCase = jsonData.true;
      const falseCase = jsonData.false;

      // console.log('================================')
      // console.log(condition)
      // console.log(trueCase)
      // console.log(falseCase)

      if (eval(condition)) {
        caluculatedValues[formula] = Math.trunc(Number(eval(trueCase)))
      } else {
        caluculatedValues[formula] = Math.trunc(Number(eval(falseCase)))
      }
    } else if (value3) {
      caluculatedValues[formula] = Math.trunc(Number(eval(value1 + operator1 + value2 + operator2 + value3)))
    }
    else if (value3 && value4) {
      caluculatedValues[formula] = Math.trunc(Number(eval(value1 + operator1 + value2 + operator2 + value3 + operator3 + value4)))
    }
    else {
      caluculatedValues[formula] = Math.trunc(Number(eval(value1 + operator1 + value2)))
    }
  };

  const formulas = jsonData?.sort((a, b) => a.id - b.id).forEach(createFormula);

  //list of variables

  values.BasicYearlyWithPF = caluculatedValues.basicYearly;
  values.BasicMonthlyWithPF = caluculatedValues.basicMonthly;

  values.HRAYearlyWithPF = caluculatedValues.HRAYearlyWithPF;
  values.HRAMontlyWithPF = caluculatedValues.HRAMontlyWithPF;


  values.EmployeerContributionMontlyToPF1 = caluculatedValues.EmployeerContributionMontlyToPF1
  values.EmployeerContributionYearlyToPF1 = caluculatedValues.EmployeerContributionMontlyToPF1


  values.OtherAllowanceYearlyWithPF = caluculatedValues.OtherAllowanceYearlyWithPF
  values.OtherAllowanceMonthlyWithPF = caluculatedValues.OtherAllowanceMonthlyWithPF


  values.GrossSalaryYearlyWithPF = caluculatedValues.GrossSalaryYearlyWithPF
  values.GrossSalaryMonthlyWithPF = caluculatedValues.GrossSalaryMonthlyWithPF


  values.EmployeerContributionMontlyToPF2 = caluculatedValues.EmployeerContributionMontlyToPF2
  values.EmployeerContributionYearlyToPF2 = caluculatedValues.EmployeerContributionYearlyToPF2
  values.EmployeerContributionMontlyToPF3 = caluculatedValues.EmployeerContributionMontlyToPF3
  values.EmployeerContributionYearlyToPF3 = caluculatedValues.EmployeerContributionYearlyToPF3


  values.NetPayBeforeTaxesYearly = caluculatedValues.NetPayBeforeTaxesYearly
  values.NetPayBeforeTaxesMonthly = caluculatedValues.NetPayBeforeTaxesMonthly


  if (candidate?.data?.data?.variablePay !== null && candidate?.data?.data?.variablePay > 0) {
    values.variablePayYearly = caluculatedValues.variablePayYearly
    values.variablePayMontly = caluculatedValues.variablePayMontly
  }

  values.TotalCTCYearly = caluculatedValues.TotalCTCYearly
  values.TotalCTCMontly = caluculatedValues.TotalCTCMontly


  const handleSendManualOfferLetter = (e) => {
    if (candidate?.data?.data?.offerLetter === true) {
      alert('Sending 2nd New Offer Letter to Candidate')
    }

    if (manualOfferLetterReason !== null) {
      if (form?.file) {
        form.manualofferletterReason = manualOfferLetterReason
        form.id = candidate?.data?.data?.candidateId;
        form.candidateId = candidate?.data?.data?.candidateId;
        form.sendType = 'manualOfferLetter'
        form.letterType = generateOfferLetterType
        const formData = new FormData();

        Object.keys(form).forEach(key => {
          formData.append(key, form[key]);
        });

        sendOfferletterviaEmail.mutate({ formData },
          {
            onSuccess: (data) => {
              alert('success');
              window.location.reload(false);
            }
          },

          {
            onError: (data) => {
              alert('error')
            }
          }
        )

      } else {
        alert('please Upload Offer Letter PDF')
      }

    } else {
      alert('please write Reason')
    }

  }


  if (annexureFormulae.isLoading || annexureFormulae.isFetching) {
    return <SpinLoader />
  }


  const filteredStatusHistory = candidate?.data?.data?.statusHistory?.filter(
    (el) => el.candidateStatusId === '7bfcc09d-7014-47bd-8bda-984081ddd991'
  );

  const displayNames = filteredStatusHistory?.map((el) => el.lastModifiedBy?.displayName);


  return (
    <>


      {!candidate.isLoading &&

        <>
          <form>
            <Box m={8} mt={20} ml={10} sx={{ boxShadow: 1, backgroundColor: 'white' }}>

              {showSalaryStructure && <SalaryView close={toggleSalaryView} candidateId={candidateId} pageType={'editable'} />}



              <h2 style={{
                marginTop: '0px', padding: '10px', fontWeight: 500, fontSize: "18px", background: candidate?.data?.data?.offerLetter === null || candidate?.data?.data?.offerLetter === false ? 'red' : 'green'
              }}> Candidate is {candidate?.data?.data?.offerLetter === null || candidate?.data?.data?.offerLetter === false ? 'Not Offered' : 'Offered'}</h2>


              <hr />
              <Grid container m={5} gap={5}>
                <Grid Item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 250 }} size="small">
                    <h3>Candidate Name</h3>
                    <TextField
                      labelId="input-candidate-name-label"
                      id="outlined-required"
                      label="Candidate Name"
                      placeholder='Enter Candidate Name'
                      size='small'
                      margin='normal'
                      name='candidateName'
                      variant="filled"
                      required
                      disabled={true}
                      defaultValue={candidate?.data?.data?.candidateName ? candidate?.data?.data?.candidateName : ''}
                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>
                <Grid Item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 250 }} size="small">
                    <h3>Candidate Email</h3>
                    <TextField

                      labelId="input-candidate-email-label"
                      id="outlined-required"
                      label="Candidate Email"
                      placeholder='Enter Candidate Email'
                      size='small'
                      margin='normal'
                      name='candidateEmail'
                      required
                      disabled={true}
                      defaultValue={candidate?.data?.data?.candidateEmail}
                      variant="filled"


                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>
                <Grid item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 250 }} size="small">

                    <h3>Candidate Phone Number</h3>
                    <TextField
                      required
                      labelId="input-candidate-phone-number"
                      id="outlined-required"
                      label="Candidate Phone Number"
                      placeholder='Enter Candidate Phone Number'
                      size='small'
                      margin='normal'
                      type='number'
                      name='candidatePhone'
                      disabled={true}

                      defaultValue={candidate?.data?.data?.candidatePhone}
                      variant="filled"

                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>
                <Grid item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 750 }} size="small">

                    <h3>Candidate Address</h3>
                    <TextField
                      required
                      labelId="input-candidate-address"
                      id="outlined-required"
                      label="Candidate Address"
                      placeholder='Enter Candidate Address'
                      size='small'
                      margin='normal'
                      disabled={true}


                      defaultValue={candidate?.data?.data?.candidateAddress}
                      variant="filled"

                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>

              </Grid>



              <Grid container m={5} gap={5}>
                <Grid item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 250 }} size="small">
                    <h3>Job Code</h3>
                    <TextField
                      required
                      labelId="input-Job-Code"
                      id="outlined-required"
                      label="Job Code"
                      placeholder='Enter Job Code'
                      size='small'
                      disabled={true}
                      margin='normal'
                      defaultValue={candidate?.data?.data.jobTitle.jobCode}
                      variant="filled"
                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>

                <Grid item xs='auto'>
                  <h3>Job Title</h3>
                  <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                    <Select
                      labelId="select-job-title-label"
                      id="select-job-label"
                      variant="filled"
                      margin='normal'
                      name='jobId'
                      required
                      defaultValue={candidate?.data?.data?.jobId}
                      disabled={true}
                      MenuProps={MenuProps}
                      onChange={(e) => handleChange(e)}
                    >
                      {getJobTitle?.data && getJobTitle?.data?.data.map((job) => {

                        // if (job?.status === 'Active') {
                        return <MenuItem key={job.jobId} value={job.jobId}>{job.jobTitle}</MenuItem>;
                        // }
                      })}
                    </Select>
                  </FormControl>


                </Grid>

                <Grid item xs='auto'>
                  <h3>Designation</h3>
                  <FormControl variant="standard" sx={{ width: 250 }} size="small">
                    <TextField
                      required
                      id="outlined-required"
                      label="Designation"
                      placeholder='Enter Designation'
                      size='medium'
                      variant="outlined"
                      margin='normal'
                      name='designation'
                      disabled={true}
                      defaultValue={candidate?.data?.data?.designation}
                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>


                <Grid item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 250 }} size="small">
                    <h3>Company Name</h3>
                    <TextField
                      required
                      labelId="input-candidate-name-label"
                      id="outlined-required"
                      placeholder='Enter Company Name'

                      size='medium'
                      variant="outlined"
                      margin='normal'
                      name='company'
                      value={candidate?.data?.data?.company}

                      onChange={(e) => handleChange(e)}
                      disabled={true} />
                  </FormControl>
                </Grid>


                <Grid item xs='auto'>

                  <FormControl variant="standard" sx={{ width: 250 }} size="small">
                    <h3>Reporting Manager Name</h3>
                    <TextField
                      required
                      size='medium'
                      margin='normal'
                      name='reportingManagerName'
                      variant="filled"
                      disabled={true}
                      value={candidate?.data?.data?.reportingManagerName ? candidate?.data?.data?.reportingManagerName : ""}
                      onChange={(e) => handleChange(e)} />
                  </FormControl>

                </Grid>


                <Grid item xs='auto'>
                  <h3>HR</h3>
                  <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                    <Select
                      required
                      size='medium'
                      margin='normal'
                      variant="filled"
                      placeholder='Select HR'
                      onChange={(e) => handleChange(e)}
                      disabled={true}
                      defaultValue={candidate?.data?.data?.hrId}>
                      {HR?.data?.data?.map((h) =>
                        <MenuItem key={h.userId} value={h?.userId}>{h?.displayName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container m={5} gap={5}>


                <Grid item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 250, marginTop: 5 }} size="small">
                    <h3>Offered Salary</h3>
                    <TextField
                      required
                      id="outlined-required"
                      label="Offered Salary"
                      placeholder='Enter Offered Salary'
                      InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }}
                      size='medium'
                      variant="outlined"
                      margin='normal'
                      type='number'
                      name='offeredSalary'
                      disabled={true}
                      defaultValue={candidate?.data?.data?.offeredSalary}
                      onChange={(e) => handleChange(e)} />
                  </FormControl>
                </Grid>


                {/US\sIT/ig.test(candidate.data.data.jobTitle.jobTitle) === true && isFresher === true ?

                  <>
                  </>
                  :
                  <>


                    <Grid Item xs='auto'>
                      <FormControl variant="standard" sx={{ width: 250 }} size="small">
                        <h3>Variable Pay</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="variablePay"
                          variant="filled"
                          disabled={true}
                          defaultValue={candidate?.data?.data?.variablePayOption === false || candidate?.data?.data?.variablePayOption === null ? false : true}
                          onChange={(e) => handleChange(e)}
                        >

                          <FormControlLabel value="true" control={<Radio required={true} disabled={true} />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio required={true} disabled={true} />} label="No" />

                        </RadioGroup>

                        <TextField
                          labelId="input-candidate-name-label"
                          id="outlined-required"
                          label="Candidate Variable Pay"
                          placeholder='Enter Candidate Variable Pay'
                          size='small'
                          margin='normal'
                          name='candidateVariablePay'
                          variant="filled"
                          required
                          type='number'
                          disabled={true}
                          defaultValue={candidate?.data?.data?.variablePay ? candidate?.data?.data?.variablePay : ''}
                          onChange={(e) => handleChange(e)} />
                      </FormControl>
                    </Grid>

                    <Grid item xs='auto'>
                      <FormControl variant="standard" sx={{ width: 250, marginTop: 10 }} size="small">
                        <InputLabel id="select-status-label">Select Variable Pay</InputLabel>
                        <Select
                          size='small'
                          margin='normal'
                          required
                          name='variablePayDurationId'
                          variant="filled"
                          disabled={true}
                          defaultValue={candidate?.data?.data?.variablePayDurationId}
                          onChange={(e) => handleChange(e)}
                        >
                          {variablePayDuration && variablePayDuration?.data?.data.map((status) =>
                            <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.variablePay}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>



                    <Grid Item xs='auto'>
                      <FormControl variant="standard" sx={{ width: 250 }} size="small">
                        <h3>Bonus Pay</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="bonusPay"
                          variant="filled"
                          disabled={true}
                          defaultValue={candidate?.data?.data?.bonusPayOption === false || candidate?.data?.data?.bonusPayOption === null ? false : true}
                          onChange={(e) => handleChange(e)}
                        >

                          <FormControlLabel value="true" control={<Radio required={true} disabled={true} />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio required={true} disabled={true} />} label="No" />

                        </RadioGroup>
                        <TextField
                          labelId="input-candidate-name-label"
                          id="outlined-required"
                          label="Candidate Bonus Pay"
                          placeholder='Enter Candidate Bonus Pay'
                          size='small'
                          margin='normal'
                          name='candidateBonusPay'
                          variant="filled"
                          type='number'
                          required
                          disabled={true}
                          defaultValue={candidate?.data?.data?.bonusPay ? candidate?.data?.data?.bonusPay : ''}

                          onChange={(e) => handleChange(e)} />
                      </FormControl>
                    </Grid>


                    <Grid item xs='auto'>
                      <FormControl variant="standard" sx={{ width: 250, marginTop: 10 }} size="small">
                        <InputLabel id="select-status-label">Select Bonus Pay</InputLabel>
                        <Select
                          size='small'
                          margin='normal'
                          required
                          name='bonusPayDurationId'
                          variant="filled"
                          disabled={true}
                          defaultValue={candidate?.data?.data?.bonusPayDurationId}
                          onChange={(e) => handleChange(e)}
                        >
                          {bonusPayDuration && bonusPayDuration?.data?.data.map((status) =>
                            <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.bonusPay}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                  </>
                }

                <Grid item xs='auto' >
                  <FormControl variant="standard" sx={{ width: 250, marginTop: 7 }} size="small">
                    <h3>Probation Period</h3>
                    <Select
                      labelId="select-job-title-label"
                      id="select-job-label"
                      variant="filled"
                      margin='normal'
                      name='probationPeriod'
                      required
                      defaultValue={candidate?.data?.data?.probationPeriodId}
                      MenuProps={MenuProps}
                      disabled={true}
                    >
                      {probationPeriod?.data && probationPeriod?.data?.data.map((probation) => {

                        // if (job?.status === 'Active') {
                        return <MenuItem key={probation.metaDataId} value={probation.metaDataId}>{probation.displayText.probation}</MenuItem>
                        // }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>


              {window.localStorage.getItem('role') === 'HR' &&
                <>
                  {candidate?.data?.data?.offerLetter !== true &&

                    <>
                      <Grid item xs='auto' sx={{ ml: 5, mb: 3 }}>
                        <h3>Auto Generate Offer Letter</h3>
                        <Button variant="contained" onClick={(e) => { toggleSalaryView() }}> View Annexure </Button>
                      </Grid>

                    </>

                  }
                </>}


              {window.localStorage.getItem('role') !== 'HR' &&
                <>


                  <Grid container m={5} gap={5}>

                    <Grid Item xs='auto'>
                      <FormControl variant="standard" sx={{ width: 250 }} size="small">
                        <h3>Generate Offer Letter</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="generateLetterType"
                          variant="filled"
                          onChange={(e) => setGenerateOfferLetterType(e.target.value)}
                          defaultValue={"true"}
                        >
                          <FormControlLabel value="true" control={<Radio required={true} />} label="AutoGenerate" />
                          <FormControlLabel value="false" control={<Radio required={true} />} label="Manual" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {
                      (window.localStorage.getItem('role') == 'Admin' || window.localStorage.getItem('role') == 'HR Manager') &&
                      <>
                        {generateOfferLetterType === "true" &&
                          <Grid item xs='auto' sx={{ ml: 5, mb: 3 }}>
                            <h3>Generate Offer Letter</h3>
                            <Button variant="contained" onClick={(e) => { toggleSalaryView() }}> View Annexure </Button>
                          </Grid>
                        }
                      </>

                    }


                    {
                      (window.localStorage.getItem('role') == 'Admin' || window.localStorage.getItem('role') == 'HR Manager') &&
                      <>
                        {generateOfferLetterType === 'false' &&
                          <>
                            <Grid item xs='auto' >
                              <FormControl variant="standard" sx={{ width: 300, }} size="small">
                                <TextField required
                                  name="manualofferletterReason"
                                  label="Reason Manual Offer Letter"
                                  placeholder="Enter Reason"
                                  variant="filled"
                                  // disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                                  defaultValue={candidate?.data?.data?.manualofferletterReason}
                                  // sx={{ width: 600 }}
                                  margin='dense' multiline fullWidth
                                  onChange={(e) => handleManualOfferLetterReason(e.target.value)}
                                />ManualOfferLetterReason
                              </FormControl>
                            </Grid>



                            <Grid item xs='auto'>
                              <TableCell>
                                <TextField
                                  id='attachment_path'
                                  name='file'
                                  required type="file"
                                  size='small'
                                  accept=".doc,.docx,application/pdf"
                                  onChange={(e) => {
                                    if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
                                      setForm({ ...form, [e.target.name]: e.target.files[0] });
                                    } else {
                                      alert('Document type should be PDf or Word only');
                                      e.target.value = ''
                                    }
                                  }}
                                />
                              </TableCell>

                            </Grid>
                            <Grid item xs='auto' >
                              <Button sx={{ ml: 3, mt: 2 }} variant="contained" onClick={(e) => { handleSendManualOfferLetter(e) }}> Send Offer Letter </Button>
                            </Grid>
                          </>
                        }

                      </>}


                  </Grid>
                </>

              }



              {candidate?.data?.data?.offerLetter === true &&
                <>


                  <Grid container m={5} gap={5}>

                    <Grid Item xs='auto'>
                      <h3>Previously Offered By : {displayNames} </h3>
                    </Grid>

                  </Grid>
                  <Grid container m={5} gap={5}>
                    <h3> Offer Letter Type : {candidate?.data?.data?.letterType === true ? ' Auto Generated' : ' Manual'} </h3>

                  </Grid>
                  {candidate?.data?.data?.manualofferletterReason &&

                    <Grid container m={5} gap={5}>
                      <Grid Item xs='auto'>
                        <h3>Reason: {candidate?.data?.data?.manualofferletterReason}</h3>
                      </Grid>


                    </Grid>
                  }
                </>

              }
            </Box>
          </form>

        </>
      }
    </>
  )
}

export default UpdateCandidateSalaryView;