import React, { useState, useEffect, useContext } from 'react';
import './AddOrUpdateJobRequisition.css';
import SpinLoader from '../SpinLoader/SpinLoader';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useUsers } from '../../helpers/hooks/userHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { UserContext } from '../Routes/Routes';
import Multiselect from 'multiselect-react-dropdown';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { FormControl, MenuItem, Select } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


const AddOrUpdateJobRequisition = ({ isModalOpen, setIsModalOpen, job }) => {
  const theme = useTheme();
  const value = useContext(UserContext);
  const [form, setForm] = useState({});
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(isModalOpen);
  const [hiringManagerId, setHiringManagerId] = useState('');
  const { createJobRequisition, uploadJobDocuments, deleteJobDocument, } = useJobs();

  // const { hiringManagers, recruiters, TaManager } = useUsers(value?.data?.role);

  const { useHiringManagers,useTaManager } = useUsers(value?.data?.role)
  const hiringManagers = useHiringManagers();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { jobRequisitionStatuses, jobTypes, costCenter, employmentType, industrialType, department, division, devices, costCenters, useDepartments, useDivisions } = useMetaData();
  const [payRates, setpayRates] = useState();
  const [experience, setexperience] = useState();
  const TaManager = useTaManager()
  const [tAManagerUserId, setTAManagerUserId] = useState();
  const [costCenterId, setCostCenterId] = useState(job?.costCenterId ? job?.costCenterId : null);
  const [departmentId, setDepartmentId] = useState(job?.departmentId ? job?.departmentId : null);
  const departments = useDepartments(costCenterId);
  const divisions = useDivisions(costCenterId, departmentId);
  let jobStatusActiveId = jobRequisitionStatuses?.data?.data.map((x) => {
    if (x?.displayText === "Active") { return x?.metaDataId } else { return "" }
  })
  let hiringManage = value?.data?.roleAssignments.filter((x) => { return x.role?.roleName == "Hiring Manager" })


  useEffect(() => {

    if (job) {
      setForm({
        jobTitle: job?.jobTitle,
        state: job?.state,
        city: job?.city,
        payRates: job?.payRates,
        experience: job?.experience,
        company: job?.company,
        noOfPositions: job?.noOfPositions,
        priority: job?.priority,
        status: job?.status,
        departmentId: job?.departmentId,
        costCenterId: job?.costCenterId,
        divisionId: job?.divisionId,
        devicesId: job?.devicesId,
        jobTypeId: job?.jobTypeId,
        industrialTypeId: job?.industrialTypeId,
        employmentTypeId: job?.employmentTypeId,
        // document: job?.document,
        hiringManagerId: job?.jobAssignments?.filter((assignment) =>
          assignment?.user?.roleAssignments?.some((assignment) =>
            assignment?.role?.roleName === 'Hiring Manager'))[0]?.user?.userId,
        primarySkills: job?.primarySkills,
        remark: job?.remark,
        ...form
      });
    }


    if (!experience && job?.experience) {
      let experience = {};
      experience.minExperience = job.experience.split('-')[0];
      experience.maxExperience = job.experience.split('-')[1].split(' ')[0];
      setexperience(experience)

    }

    if (!payRates && job?.payRates) {
      let payRates = {};
      payRates.minPayRates = JSON.parse(job?.payRates)[0]?.pay_rate?.split('-')[0]
      payRates.maxPayRates = JSON.parse(job?.payRates)[0]?.pay_rate?.split('-')[1]
      setpayRates(payRates)
    }

    if (costCenterId) {
      departments.refetch();
    }


    if (departmentId) {
      divisions.refetch();
    }
  }, [job, costCenterId, departmentId]);


  const downloadDocument = (e) => {
    e.preventDefault();
    if (job?.document) {
      let document = job?.document;
      if (document.downloadLink) {
        window.open(document.downloadLink, '_blank', 'noopener,noreferrer')
      }
    }
  }
  const handleSelectTaManagersUserId = (e) => {
    let userid = e.map((a) => a.value)
    setTAManagerUserId(userid)
    console.log(userid);

}
const handleRemoveTaManagersUserId = (e, value) => {
  let userid = e.map((a) => a?.value)

  setTAManagerUserId(userid)
 
  console.log(userid);
}
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });

    if (e.target.name === 'costCenterId') {
      setCostCenterId(e.target.value);
    } else if (e.target.name === 'departmentId') {
      setDepartmentId(e.target.value);
    }
  }

  const handleDelete = (e) => {
    const formData = new FormData();
    formData.append('jobId', job.jobId);
    formData.append('documentName', e.target.name);
    formData.append('delete', true);
    deleteJobDocument.mutate({ formData });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parseInt(payRates?.minPayRates) >= parseInt(payRates?.maxPayRates)) {
      alert('Minimum  pay rates CTC should be less than Maximum pay rates CTC');
      return;
    }
    if (parseInt(experience?.minExperience) >= parseInt(experience?.maxExperience)) {
      alert('Minimum experience should be less than Maximum experience');
      return;
    }
    if (payRates) {
      form.payRates = JSON.stringify([{ pay_rate: `${payRates?.minPayRates}-${payRates?.maxPayRates}` }]);
    }
    if (experience) {
      form.experience = `${experience?.minExperience}-${experience?.maxExperience} years`;
    }

    if (window.localStorage.getItem('role') === 'Hiring Manager') { form.hiringManagerId = hiringManage[0]?.userId }

    form.jobRequisitionStatusId = jobStatusActiveId[0];
    form.tAManagerUserIds = tAManagerUserId;
    const formData = new FormData();
    console.log(form)
    Object.keys(form).forEach(key => {
      formData.append(key, form[key]);
    });
    if (form?.file) {
      createJobRequisition.mutate(formData,
        {
          onSuccess: (data) => {
            formData.append('jobId', data.data.jobId);
            formData.append('documentName', 'JobDescription');
            uploadJobDocuments.mutate({ formData }, {
              onError: (e) => {
                alert(e.response.data.message);
              }
            });
          }
        },
        { onError: (data) => { alert('error') } }
      )
      alert('success')
      setIsModalOpen(false)

    } else {
      alert("Please add attachment")
    }
  }

  return (
    <Box m>
      <Paper>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title"
          maxWidth='xxl'>
          <DialogContent>
            <h2 style={{
              padding: '10px', backgroundColor: "cornflowerblue",
              fontWeight: 700, fontSize: "20px",
            }} > Add Job Details </h2>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box sx={{
                padding: "10px",
                display: 'grid',
                gap: 3,
                gridTemplateColumns: 'repeat(4, 3fr)',
                border: '#4774ce'
              }}>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Job Title
                  <TextField
                    labelId="input-candidate-name-label"
                    id="outlined-required"
                    label="Job Title"
                    placeholder='Enter Job Title'
                    size='small'
                    // autoComplete='off'
                    margin='normal'
                    name='jobTitle'
                    variant="filled"
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={job?.jobTitle}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Job State
                  <TextField
                    labelId="input-job-name-label"
                    id="outlined-required"
                    name="state"
                    variant="filled"
                    placeholder='Enter Job state'
                    size='small'
                    margin='normal'
                    defaultValue={job?.state}
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Job City
                  <TextField
                    labelId="input-candidate-name-label"
                    id="outlined-required"
                    label="Job City"
                    placeholder='Enter Job City'
                    size='small'
                    margin='normal'
                    name='city'
                    variant="filled"
                    defaultValue={job?.city}
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Min Pay Rate
                  <TextField
                    required
                    id='payRates'
                    label="Min Pay Rate"
                    placeholder='Min'
                    size='small'
                    margin='normal'
                    type='number'
                    variant="filled"
                    InputProps={{ inputProps: { min: "100000" } }}
                    name='payRates'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={payRates?.minPayRates
                      // job?.payRates[0]?.pay_rate?.split('-')[0]
                    }
                    value={payRates?.minPayRates}
                    onChange={(e) => { setpayRates({ ...payRates, minPayRates: e.target.value }) }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Max Pay Rate
                  <TextField
                    required
                    id='payRates'
                    label="Max Pay Rate"
                    placeholder='Max'
                    size='small'
                    margin='normal'
                    type='number'
                    InputProps={{ inputProps: { min: "100000" } }}
                    variant="filled"
                    name='payRates'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={payRates?.maxPayRates
                      // job?.payRates[0]?.pay_rate?.split('-')[1]
                    }
                    value={payRates?.maxPayRates}
                    onChange={(e) => { setpayRates({ ...payRates, maxPayRates: e.target.value }) }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Min Experience
                  <TextField
                    required
                    id='experience'
                    label=" Min Experience"
                    placeholder='Min'
                    size='small'
                    margin='normal'
                    type='number'
                    variant="filled"
                    name='experience'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={
                      experience?.minExperience
                      // job?.experience?.split('-')[0]
                    }
                    value={experience?.minExperience}
                    onChange={(e) => { setexperience({ ...experience, minExperience: e.target.value }) }}

                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Max Experience
                  <TextField
                    required
                    id='experience'
                    label=" Max Experience"
                    placeholder='Max'
                    size='small'
                    margin='normal'
                    type='number'
                    variant="filled"
                    name='experience'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={
                      experience?.maxExperience
                      // job?.experience?.split('-')[1][0]
                    }
                    value={experience?.maxExperience}
                    onChange={(e) => { setexperience({ ...experience, maxExperience: e.target.value }) }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  Company Name
                  <TextField
                    labelId="input-candidate-name-label"
                    id="outlined-required"
                    label="Company Name"
                    placeholder='Enter Company Name'
                    size='small'
                    margin='normal'
                    name='company'
                    variant="filled"
                    defaultValue={job?.company}
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  No of Positions
                  <TextField
                    required
                    id='noOfPositions'
                    label="No of Positions"
                    placeholder='Enter No of Positions'
                    size='small'
                    variant="filled"
                    margin='normal'
                    type='number'
                    name='noOfPositions'
                    defaultValue={job?.noOfPositions}
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    onChange={(e) => handleChange(e)} />
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                  Priority
                  <Select
                    labelId="select-priority-label"
                    id="select-job-label"
                    variant="filled"
                    name='priority'
                    required
                    defaultValue={job?.priority}
                    onChange={(e) => handleChange(e)}
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    margin='normal'
                  >
                    <MenuItem key="High" value="High">High</MenuItem>
                    <MenuItem key="Medium" value="Medium">Medium</MenuItem>
                    <MenuItem key="Low" value="Low">Low</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                  Cost Center
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="costCenter"
                    name="costCenterId"
                    size='small'
                    variant="filled"
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    margin='normal'
                    defaultValue={job?.costCenterId}
                    onChange={(e) => handleChange(e)}
                  >
                    {costCenters?.data && costCenters?.data?.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, marginTop: 2 }} size="small">
                  Department
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="department"
                    label="department"
                    name="departmentId"
                    size='small'
                    variant="filled"
                    defaultValue={job?.departmentId}
                    margin='normal'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    onChange={(e) => handleChange(e)}
                  >
                    {(!(departments.isFetching && department.isLoading) && departments?.data) && departments?.data?.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, }} size="small">
                  Division
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="division"
                    label="division"
                    name="divisionId"
                    size='small'
                    variant="filled"
                    margin='normal'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={job?.divisionId}
                    onChange={(e) => handleChange(e)}
                  >
                    {(!(divisions.isFetching && divisions.isLoading) && divisions?.data) && divisions?.data?.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, }} size="small">
                  Devices
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="devices"
                    label="devices"
                    name="devicesId"
                    size='small'
                    variant="filled"
                    margin='normal'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={job?.devicesId}
                    onChange={(e) => handleChange(e)}
                  >
                    {devices?.data && devices?.data?.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText.device}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, }} size="small">
                  Job Type
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="jobTypeId"
                    label="jobTypeId"
                    name="jobTypeId"
                    size='small'
                    variant="filled"
                    margin='normal'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={job?.jobTypeId}
                    onChange={(e) => handleChange(e)}
                  >
                    {jobTypes?.data && jobTypes?.data?.data.map((jobType) =>
                      <MenuItem key={jobType.metaDataId} value={jobType.metaDataId}>{jobType.displayText}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, }} size="small">
                  Employment Type
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="employmentTypeId"
                    label="employmentType"
                    name="employmentTypeId"
                    size='small'
                    variant="filled"
                    margin='normal'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={job?.employmentTypeId}
                    onChange={(e) => handleChange(e)}
                  >
                    {employmentType?.data && employmentType?.data?.data.map((employment) =>
                      <MenuItem key={employment.metaDataId} value={employment.metaDataId}>{employment.displayText}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, }} size="small">
                  Industrial Type
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="industrialTypeId"
                    label="industrialType"
                    name="industrialTypeId"
                    size='small'
                    variant="filled"
                    margin='normal'
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    defaultValue={job?.industrialTypeId}
                    onChange={(e) => handleChange(e)}
                  >
                    {industrialType?.data && industrialType?.data?.data?.map((industrial) =>
                      <MenuItem key={industrial.metaDataId} value={industrial.metaDataId}>{industrial.displayText}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: 300, }} size="small">
                  <TextField
                    style={{ marginTop: '15px' }}
                    id='attachment_path'
                    name='file'
                    type="file"
                    disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                    accept=".doc,.docx,application/pdf"
                    onChange={(e) => {
                      if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
                        setForm({ ...form, [e.target.name]: e.target.files[0] });
                      } else {
                        alert('Document type should be PDf or Word only');
                        e.target.value = ''
                      }
                    }}
                  />

                </FormControl>
            

                  <FormControl style={{ marginTop: "20px", border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "5px", }} >
                    TA Manager
                    <Multiselect
                      displayValue="key"
                      onKeyPressFn={function noRefCheck() { }}
                      onRemove={(e, value) => { handleRemoveTaManagersUserId(e, value) }}
                      onSearch={function noRefCheck() { }}
                      // disable={true}
                      onSelect={(e) => { handleSelectTaManagersUserId(e) }}
                      options={TaManager?.data?.data?.map((x) => { return { value: x?.userId, key: x?.displayName } })}
                      // selectedValues={assignTaManagerList?.filter((ele) => taManagerName?.includes(ele?.user?.displayName))?.map((x) => { return { value: x?.user?.userId, key: x?.user?.displayName } })}
                      onChange={(e) => handleChange(e)}
                      style={{
                        chips: {
                          background: '1px solid blue',
                        },
                        multiselectContainer: {
                          color: '1px solid blue',
                          zIndex: "999",
                          "white-space": "nowrap",
                        },
                        searchBox: {
                          border: 'none',
                          'border-radius': '0px',
                          width: '250px',
                          overflow: "hidden"
                        },
                      }}
                      showCheckbox
                    />
                  </FormControl>
              
                {
                  window.localStorage.getItem('role') === 'Hiring Manager' ?
                    <>
                      <FormControl variant="standard" sx={{ width: 300, }} size="small">
                        Hiring Managers
                        <Select
                          labelId="demo-simple-select-label"
                          name="hiringManagerId"
                          label="hiringManager"
                          id="hiringManagerId"
                          disabled={(window.localStorage.getItem('role') === 'Recruiter' || window.localStorage.getItem('role') === 'Hiring Manager') ? true : false}
                          required
                          size='small'
                          variant="filled"
                          margin='normal'
                          defaultValue={hiringManage[0]?.userId}
                          // value={hiringManage[0]?.userId}
                          onChange={(e) => handleChange(e)}
                        >
                          {hiringManagers?.data && hiringManagers?.data?.data.map(cc =>
                            <MenuItem key={cc?.userId} value={cc?.userId}>{cc?.displayName}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </>
                    :
                    <>
                      <FormControl variant="standard" sx={{ width: 300, }} size="small">
                        Hiring Managers
                        <Select
                          labelId="demo-simple-select-label"
                          name="hiringManagerId"
                          label="hiringManager"
                          id="hiringManagerId"
                          disabled={(window.localStorage.getItem('role') === 'Recruiter' || window.localStorage.getItem('role') === 'Hiring Manager') ? true : false}
                          required
                          size='small'
                          variant="filled"
                          margin='normal'
                          MenuProps={MenuProps}
                          defaultValue={job?.jobAssignments?.filter((assignment) =>
                            assignment?.user?.roleAssignments?.some((assignment) =>
                              assignment?.role?.roleName === 'Hiring Manager'))[0]?.user?.userId}
                          onChange={(e) => handleChange(e)}
                        >
                          {hiringManagers?.data && hiringManagers?.data?.data.map(cc =>
                            <MenuItem key={cc.userId} value={cc.userId}>{cc.displayName}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </>
                }
              </Box>
              <FormControl variant="standard" sx={{ width: 300, }} size="small">
                <TextField required
                  name="primarySkills"
                  label="Primary Skills"
                  placeholder='Enter Primary Skills'
                  variant="filled"
                  disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                  defaultValue={job?.primarySkills}
                  sx={{ width: 600 }}
                  margin='dense' multiline fullWidth
                  onChange={(e) => handleChange(e)} />
              </FormControl>
              <FormControl variant="standard" sx={{ width: 300, }} size="small">
                <TextField required
                  name="remark"
                  label="Remark"
                  variant="filled"
                  defaultValue={job?.remark}
                  sx={{ width: 600, marginLeft: 42 }}
                  disabled={window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                  margin='dense' multiline fullWidth
                  onChange={(e) => handleChange(e)}

                />
              </FormControl>
              <Box>
                {(window.localStorage.getItem('role') === 'TA Manager' ||
                  window.localStorage.getItem('role') === 'Admin' ||
                  window.localStorage.getItem('role') === 'Hiring Manager' ||
                  window.localStorage.getItem('role') === 'HR Manager') &&
                  <Button
                    variant="contained"
                    sx={{ marginRight: 2, marginTop: 2 }}
                    type="submit"
                  > Submit </Button>
                }
                <Button
                  variant="contained"
                  sx={{ marginRight: 2, marginTop: 2 }}
                  onClick={(e) => { setIsModalOpen(false) }}
                >close</Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Paper>
    </Box>


  )
}

export default AddOrUpdateJobRequisition;


