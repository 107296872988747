



import React, { useState, useEffect, useRef, useContext } from 'react';

import { UserContext } from '../../components/Routes/Routes';



import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { useUsers } from '../../helpers/hooks/userHooks';
import ReactQuill from 'react-quill';

import SpinLoader from '../../components/SpinLoader/SpinLoader';
import DocumentUpload from '../../components/DocumentUpload/DocumentUpload';

//!--------------------MUI------------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


import Link from '@mui/material/Link'
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select, Alert, Input } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAlert, positions } from 'react-alert'
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useOfferLetter } from '../../helpers/hooks/adminHooks';
import { ArticleOutlined } from '@mui/icons-material';

import SalaryView from '../../components/SalaryView/SalaryView';



const MetaData = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { metaData, annexureFormulae, candidateStatuses, updateMetadata, variablePayDuration, bonusPayDuration } = useMetaData();
    const [metadataType, setMetadataType] = useState(false);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState();
    const [showSalaryStructure, setShowSalaryStructure] = useState(false)
    const [candidate, setSampleCandidate] = useState({

        data: {
            data: {
                offeredSalary: null,
                variablePayDurationId: null,
                variablePay: null,
            }
        },
        // Other properties within 'candidate' if any
        // Other top-level properties if any
    });

    const [sampleVariablePay, setSampleVariablePay] = useState(null)
    const [sampleVariablePayDuration, setSampleVariablePayDuration] = useState(null)

    const [showNewValueOperator, setShowNewValueOperator] = useState(false);
    const [order, setOrder] = useState();


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const handleChange = (e, order) => {


        if (e.target.name === 'metadataType') {
            setMetadataType(e.target.value)
        }

        console.log(e.target.value);
        if (e.target.value.length === 0) {

            console.log('+++++++++++++++++++++++++++++++++++++++==')
            setForm({
                ...form,
                [e.target.name]: null,
                id: order,
            });
        } else {

            setForm({
                ...form,
                [e.target.name]: e.target.value,
                id: order,
            })
        }
    }


    const handleAdd = (e, order) => {
        setShowNewValueOperator(true)
        setOrder(order)
    }



    const handleClose = () => {
        setOpen(false); // Call the close function passed as a prop

    };



    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('submitting', form);

        updateMetadata.mutate({ formData: form },

            {
                onSuccess: (data) => {
                    alert('success')
                }
            },
            {
                onError: (data) => {
                    alert('error')
                }
            }
        )
    }


    const toggleSalaryView = () => {
        setShowSalaryStructure(!showSalaryStructure);
    };



    return (
        <>


            <Box ml={10} mt={20} sx={{ boxShadow: 1 }} >
                {/*
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                    <Select
                        labelId="select-metadata-label"
                        id="select-metadata-label"
                        variant="filled"
                        margin='normal'
                        name='metadataType'
                        required
                        MenuProps={MenuProps}
                        onChange={(e) => handleChange(e)}
                    >
                        {metaData?.data && metaData?.data?.data.map((metadata) => {

                            return <MenuItem key={metadata.metaDataId} value={metadata.metaDataType}>{metadata.metaDataType}</MenuItem>;

                        })}
                    </Select>
                </FormControl> */}


                <form
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <Box sx={{ background: 'white', width: 'auto', height: '80vh', display: 'flex', flexDirection: 'column' }} m={3}>

                        <h2 style={{
                            padding: '5px', backgroundColor: "cornflowerblue",
                            fontWeight: 700, fontSize: "20px",
                        }}> Employee Salary (With PF)</h2>
                        <TableContainer
                            //  ref={ref}
                            sx={{ width: '100%', overflow: 'auto' }}>

                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: "#243c80", background: "white" }}>


                                    <TableRow
                                        sx={{
                                            "& th": {
                                                borderBottom: "2px solid black",
                                                fontSize: "0.9rem",
                                                color: "white",
                                                backgroundColor: "#243c80",
                                                borderLeft: "1px solid #3b4864",
                                                height: '1px'
                                            }
                                        }}
                                    >

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white", width: '20px' }}>Earnings</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white", width: '20px' }}>Type</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>value1</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Operator1</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Value2</TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Operator2</TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>value3</TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Operator3</TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Value4</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Operator4</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Value5</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Operator5</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>

                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Value6</TableCell>
                                        <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}></TableCell>


                                    </TableRow>
                                </TableHead>


                                {annexureFormulae?.data && annexureFormulae?.data?.data?.filter((el) => !el.displayText.condition && !el.displayText.condition1).map((el) => {


                                    return <TableRow
                                        key={el.order}
                                        hover>


                                        <TableCell>

                                            <Button sx={{ ml: '20px', mt: '20px' }} size="small" variant="contained" color="success" type='submit'>
                                                Save
                                            </Button>

                                        </TableCell>
                                        <TableCell style={{ fontSize: 20 }}>{el.displayText.Formula}</TableCell>
                                        <TableCell style={{ fontSize: 20 }}>Normal</TableCell>
                                        <TableCell style={{ fontSize: 20 }}></TableCell>
                                        <TableCell>

                                            <Input
                                                name='value1'
                                                style={{ width: 300, height: 50, fontSize: 15, border: "1px solid black", padding: 10 }}
                                                onChange={(e) => { handleChange(e, el.order) }}
                                                defaultValue={el.displayText.value1}
                                            />


                                        </TableCell>
                                        <TableCell style={{ fontSize: 20 }}></TableCell>

                                        {el.displayText?.Operator1 &&
                                            <>

                                                <TableCell>
                                                    <Input
                                                        name='Operator1'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator1}
                                                    />

                                                </TableCell>
                                            </>

                                        }

                                        {!el.displayText.Operator1 && showNewValueOperator === true && el.order === order &&
                                            <>


                                                <TableCell>
                                                    <Input
                                                        name='Operator1'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator1}
                                                    />
                                                </TableCell>

                                            </>

                                        }


                                        <TableCell style={{ fontSize: 20 }}></TableCell>

                                        {el.displayText?.value2 &&
                                            <>

                                                <TableCell>
                                                    <Input
                                                        name='value2'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value2}
                                                    />
                                                </TableCell>
                                            </>
                                        }

                                        {!el.displayText.value2 && showNewValueOperator === true && el.order === order &&
                                            <>


                                                <TableCell>
                                                    <Input
                                                        name='value2'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}

                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value2}
                                                    />
                                                </TableCell>

                                            </>

                                        }


                                        {/* <TableCell style={{ fontSize: 20 }}></TableCell> */}

                                        {el.displayText.Operator2 &&

                                            <>

                                                <TableCell>
                                                </TableCell>
                                                <TableCell>

                                                    <Input
                                                        name='Operator2'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator2}
                                                    />
                                                </TableCell>
                                            </>
                                        }

                                        {!el.displayText.Operator2 && showNewValueOperator === true && el.order === order &&
                                            <>


                                                <TableCell>
                                                    <Input
                                                        name='Operator2'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator2}
                                                    />
                                                </TableCell>

                                            </>

                                        }

                                        {el.displayText.value3 &&
                                            <>

                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='value3'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}

                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value3}
                                                    />
                                                </TableCell>

                                            </>

                                        }

                                        {!el.displayText.value3 && showNewValueOperator === true && el.order === order &&
                                            <>


                                                <TableCell>
                                                    <Input
                                                        name='value3'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}

                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value3}
                                                    />
                                                </TableCell>

                                            </>

                                        }


                                        {el.displayText.Operator3 &&
                                            <>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='Operator3'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator3}
                                                    />
                                                </TableCell>
                                            </>
                                        }



                                        {el.displayText.value4 &&
                                            <>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='value4'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}

                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value4}
                                                    />

                                                </TableCell>
                                            </>
                                        }


                                        {el.displayText.Operator4 &&

                                            <>

                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='Operator4'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator4}
                                                    />
                                                </TableCell>
                                            </>
                                        }



                                        {el.displayText.value5 &&
                                            <>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='value5'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}

                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value5}
                                                    />

                                                </TableCell>
                                            </>

                                        }

                                        {el.displayText.Operator5 &&

                                            <>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='Operator5'
                                                        style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.Operator5}
                                                    />
                                                </TableCell>

                                            </>
                                        }


                                        {el.displayText.value6 &&
                                            <>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        name='value6'
                                                        style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                        onChange={(e) => { handleChange(e, el.order) }}
                                                        defaultValue={el.displayText.value6}
                                                    />

                                                </TableCell>

                                            </>

                                        }


                                        <Button onClick={(e) => handleAdd(e, el.order)}>+</Button>
                                        <TableCell>
                                        </TableCell>

                                    </TableRow>

                                })

                                }



                                {annexureFormulae?.data && annexureFormulae?.data?.data?.filter((el) => el.displayText.condition).map((el) => {


                                    return <TableRow
                                        key={el.order}
                                        hover>


                                        <TableCell>

                                            <Button sx={{ ml: '20px', mt: '20px' }} size="small" variant="contained" color="success" type='submit'>
                                                Save
                                            </Button>

                                        </TableCell>
                                        <TableCell style={{ fontSize: 20 }}>{el.displayText.Formula}</TableCell>
                                        <TableCell style={{ fontSize: 20 }}>conditional</TableCell>
                                        <TableCell style={{ fontSize: 20 }}>If</TableCell>

                                        <TableCell>

                                            <Input
                                                name='value1'
                                                style={{ width: 300, height: 50, fontSize: 15, border: "1px solid black", padding: 10 }}
                                                onChange={(e) => { handleChange(e, el.order) }}
                                                defaultValue={el.displayText.value1}
                                            />


                                        </TableCell>

                                        <TableCell style={{ fontSize: 20 }}></TableCell>
                                        <TableCell>
                                            <Input
                                                name='Operator1'
                                                style={{ width: 50, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}
                                                onChange={(e) => { handleChange(e, el.order) }}
                                                defaultValue={el.displayText.Operator1}
                                            />

                                        </TableCell>
                                        <TableCell style={{ fontSize: 20 }}></TableCell>

                                        <TableCell>
                                            <Input
                                                name='value2'
                                                style={{ width: 300, height: 50, fontSize: 15, border: "1px solid black", padding: 10 }}
                                                onChange={(e) => { handleChange(e, el.order) }}
                                                defaultValue={el.displayText.value2}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontSize: 20 }}></TableCell>
                                        <TableCell style={{ fontSize: 20 }}>equals</TableCell>

                                        <TableCell style={{ fontSize: 20 }}></TableCell>
                                        <TableCell>
                                            <Input
                                                name='condition'
                                                style={{ width: 200, height: 50, fontSize: 15, border: "1px solid black", padding: 20, }}

                                                onChange={(e) => { handleChange(e, el.order) }}
                                                defaultValue={el.displayText.true}
                                            />
                                        </TableCell>

                                        <TableCell style={{ fontSize: 20 }}></TableCell>
                                        <TableCell style={{ fontSize: 20 }}>else</TableCell>

                                        <TableCell style={{ fontSize: 20 }}></TableCell>

                                        <TableCell>
                                            <Input
                                                name='value2'
                                                style={{ width: 300, height: 50, fontSize: 15, border: "1px solid black", padding: 10 }}
                                                onChange={(e) => { handleChange(e, el.order) }}
                                                defaultValue={el.displayText.false}
                                            />
                                        </TableCell>

                                    </TableRow>
                                })

                                }

                            </Table>
                        </TableContainer>
                    </Box>



                    <Box sx={{ background: 'white', width: 'auto', display: 'flex', flexDirection: 'column' }} m={3}>
                        {showSalaryStructure && <SalaryView close={toggleSalaryView} pageType={'show'} sampleSalary={candidate} />}

                        <TableRow>

                            <TableCell> Offered CTC</TableCell>

                            <TableCell>
                                <Input
                                    name='candidate.data.data.offeredSalary'
                                    style={{ width: 100, height: 50, fontSize: 15, border: "1px solid black", padding: 10 }}
                                    onChange={(e) => {

                                        setSampleCandidate({ ...candidate, offeredSalary: e.target.value })
                                    }}
                                />
                            </TableCell>

                            <TableCell> Variable Pay</TableCell>

                            <TableCell>
                                <Input
                                    name='variablePay'
                                    style={{ width: 100, height: 50, fontSize: 15, border: "1px solid black", padding: 10 }}
                                    onChange={(e) => { setSampleCandidate({ ...candidate, variablePay: e.target.value }) }}
                                />
                            </TableCell>

                            <TableCell>


                                <Select
                                    style={{ width: 100, height: 50, fontSize: 15 }}
                                    name='variablePayDurationId'
                                    variant="filled"
                                    onChange={(e) => { setSampleCandidate({ ...candidate, variablePayDurationId: e.target.value }) }}
                                >
                                    {variablePayDuration && variablePayDuration?.data?.data.map((status) =>
                                        <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.variablePay}</MenuItem>
                                    )}
                                </Select>

                            </TableCell>


                            <TableCell> <Button size="small" variant="contained" onClick={(e) => { toggleSalaryView() }}> Preview</Button> </TableCell>
                        </TableRow>


                    </Box>
                </form>


                <form>

                </form>

            </Box>
        </>
    )
}

export default MetaData;