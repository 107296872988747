import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddUser from '../../components/AddUser/AddUser';
import { useQuery } from 'react-query';
import { getAllTenantUsers, getAllGuestUsers, getAllAppUsers } from '../../backendApis';
import { useUsers } from '../../helpers/hooks/userHooks';

import SpinLoader from '../../components/SpinLoader/SpinLoader';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import { Button, Card, CardContent, Dialog, DialogContent, Grid, TableCell, TextField, Typography } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';


const Users = () => {

    const [tab, setValue] = useState('Org');
    const { instance, accounts } = useMsal();
    const [user, setUser] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [search, setSearch] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState();



    const { data: users, isLoading, refetch } = useQuery('fetchTenantUsers', () => getAllTenantUsers({ instance, accounts, ...search }), {
        retry: false, enabled: tab === 'Org',
        onError: (err) => {
            alert(err);
        },
        refetchOnWindowFocus: false
    });

    const { data: guestUsers, isLoading: guestLoading, refetch: refetchGuest } = useQuery('fetchGuestUsers', () => getAllGuestUsers({ instance, accounts, ...search }), {
        retry: false, enabled: tab === 'Guest',
        onError: (err) => {
            alert(err);
        },
        refetchOnWindowFocus: false
    })

    const { data: appUsers, isLoading: appLoading, refetch: refetchApp } = useQuery('fetchAppUsers', () => getAllAppUsers({ instance, accounts, ...search }), {
        retry: false, enabled: tab === 'App',
        onError: (err) => {
            alert(err);
        },
        refetchOnWindowFocus: false
    })


    if (isLoading || guestLoading || appLoading) {
        return <SpinLoader />
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            {isModalOpen && <AddUser setIsModalOpen={setIsModalOpen} user={user} isModalOpen={isModalOpen} editMode={editMode} setEditMode={setEditMode} />}
            <Box m={20} sx={{ width: 1200, height: 100, bgcolor: 'background.paper' }}>
                <div style={{ margin: '20px' }}>
                    <Button variant="contained" sx={{ borderRadius: 3, width: '320px' }}
                        onClick={(e) => { setValue('Org') }}> Orginazation Users </Button>
                    <Button variant="contained" sx={{ borderRadius: 3, width: '320px' }} style={{ marginLeft: '20px' }}
                        onClick={(e) => { setValue('Guest') }}> Guest Users </Button>
                    <Button variant="contained" sx={{ borderRadius: 3, width: '320px' }} style={{ marginLeft: '20px' }} onClick={(e) => { setValue('App') }}> App Users </Button>
                </div>
                {(tab === 'Org') &&
                    <>
                        <Box>

                            {/* <h3 style={{ marginLeft: '20px' }}>Search According to email</h3>

                            <TextField sx={{ marginLeft: '30px', marginTop: '10px' }}
                                type='email'
                                name="email"
                                size='small'
                                onChange={(e) => setSearch({ [e.target.name]: e.target.value })}
                            />

                            <Button variant="contained" sx={{ borderRadius: 3, width: '120px', height: 40, marginTop: '10px' }} style={{ padding: "10px", marginBottom: '20px', marginLeft: '20px' }} onClick={() => refetch()}>Search</Button> */}

                        </Box>
                        <div style={{ height: 'auto' }}>
                            <Paper>
                                <Box m={1} sx={{ overflow: 'hidden' }}>


                                    <TextField
                                        type="text"
                                        name="email"
                                        placeholder='search'
                                        sx={{ width: 200, height: 55 }}
                                        variant='outlined'
                                        onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })} />


                                    <Button
                                        variant="contained"
                                        style={{
                                            padding: "10px",
                                            marginBottom: '10px',
                                            marginLeft: '70px'
                                        }}
                                        onClick={(e) => { refetch()}}>Search</Button>



                                    <TableContainer sx={{ marginTop: 4, maxHeight: 900, height: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow sx={{
                                                    "& th": {
                                                        borderBottom: "2px solid black",
                                                        fontSize: "0.9rem",
                                                        color: "white",
                                                        backgroundColor: "#243c80",
                                                        borderLeft: "1px solid #3b4864",
                                                        height: '1px',
                                                        zIndex: 0
                                                    }
                                                }}>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Email</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Edit</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users?.data && users.data.map((user, i) => {
                                                    return (<TableRow>
                                                        <TableCell> {user.displayName}</TableCell>
                                                        <TableCell> {user.mail}</TableCell>
                                                        <TableCell>
                                                            <AddCircleOutlineIcon onClick={(e) => {
                                                                setUser(user);
                                                                setIsModalOpen(true);
                                                                setEditMode(true)
                                                            }} />
                                                        </TableCell>
                                                    </TableRow>)
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </div>
                    </>
                }

                {(tab == 'Guest') &&

                    <>
                        <Box>

                            {/* <h3 style={{ marginLeft: '20px' }}>Search According to email</h3>

                            <TextField sx={{ marginLeft: '30px', marginTop: '10px' }}
                                type='email'
                                name="email"
                                size='small'
                                onChange={(e) => setSearch({ [e.target.name]: e.target.value })}
                            />

                            <Button variant="contained" sx={{ borderRadius: 3, width: '120px', height: 40, marginTop: '10px' }} style={{ padding: "10px", marginBottom: '20px', marginLeft: '20px' }} onClick={() => refetch()}>Search</Button> */}

                        </Box>
                        <div style={{ height: 'auto' }}>
                            <Paper>
                                <Box m={1} sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ marginTop: 4, maxHeight: 900, height: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow sx={{
                                                    "& th": {
                                                        borderBottom: "2px solid black",
                                                        fontSize: "0.9rem",
                                                        color: "white",
                                                        backgroundColor: "#243c80",
                                                        borderLeft: "1px solid #3b4864",
                                                        height: '1px',
                                                        zIndex: 0
                                                    }
                                                }}>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Email</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Edit</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {guestUsers?.data && guestUsers.data.map((user) => {
                                                    return (<TableRow>
                                                        <TableCell>{user.displayName}</TableCell>
                                                        <TableCell>{user.mail}</TableCell>
                                                        <TableCell>
                                                            <AddCircleOutlineIcon onClick={(e) => {
                                                                setUser(user);
                                                                setIsModalOpen(true);
                                                                setEditMode(true)
                                                            }} />

                                                        </TableCell>
                                                    </TableRow>)
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </div>
                    </>
                }
                {(tab == 'App') &&
                    <>
                        <Box>

                            {/* <h3 style={{ marginLeft: '20px' }}>Search According to email</h3>

                            <TextField sx={{ marginLeft: '30px', marginTop: '10px' }}
                                type='email'
                                name="email"
                                size='small'
                                onChange={(e) => setSearch({ [e.target.name]: e.target.value })}

                            /> */}
                            {/* <Button variant="contained" sx={{ borderRadius: 3, width: '120px', height: 40, marginTop: '10px' }} style={{ padding: "10px", marginBottom: '20px', marginLeft: '20px' }} onClick={() => refetch()}>Search</Button> */}

                        </Box>
                        <div style={{ height: 'auto' }}>
                            <Paper>
                                <Box m={1} sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ marginTop: 4, maxHeight: 900, height: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow sx={{
                                                    "& th": {
                                                        borderBottom: "2px solid black",
                                                        fontSize: "0.9rem",
                                                        color: "white",
                                                        backgroundColor: "#243c80",
                                                        borderLeft: "1px solid #3b4864",
                                                        height: '1px',
                                                        zIndex: 0
                                                    }
                                                }}>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Email</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Role</TableCell>
                                                    <TableCell style={{ fontWeight: 700, fontSize: "18px" }}>Edit</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {appUsers?.data && appUsers.data.map((user) => {

                                                    return (<TableRow>
                                                        <TableCell> {user.displayName}</TableCell>
                                                        <TableCell> {user.email}</TableCell>
                                                        <TableCell> {user?.roleAssignments.map((role, i, arr) => arr?.length - 1 !== i ? role?.role?.roleName + ',' : role?.role?.roleName)}</TableCell>
                                                        <TableCell>

                                                            <AddCircleOutlineIcon onClick={(e) => {
                                                                setUser(user);
                                                                setIsModalOpen(true);
                                                                setEditMode(true)
                                                            }} />
                                                        </TableCell>
                                                    </TableRow>)
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </div>
                    </>

                }

            </Box>

        </>
    )
}

export default Users;