import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SpinLoader from '../../components/SpinLoader/SpinLoader';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useUsers } from '../../helpers/hooks/userHooks';
import { UserContext } from '../../components/Routes/Routes';


import ReactQuill from 'react-quill';
import { Box } from '@mui/system';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import { useAlert, positions } from 'react-alert'
import TableContainer from '@mui/material/TableContainer';
import { ArticleOutlined } from '@mui/icons-material';
import SalaryView from '../../components/SalaryView/SalaryView';
import Multiselect from 'multiselect-react-dropdown';

const CandidateSalaryView = () => {

  const value = useContext(UserContext);
  const [search, setSearch] = useState({});
  const navigate = useNavigate();
  const { useGetCandidates, updateCandidate } = useCandidates();
  const candidates = useGetCandidates(search);
  const { candidateStatuses } = useMetaData();
  const { useRecruiters } = useUsers(value.data.role);

  const recruiters = useRecruiters
  const [form, setForm] = useState();

  const { useGetJobTitle } = useJobs();
  const getJobTitle = useGetJobTitle();
  const [jobTitle, setJobTitle] = useState([]);

  const alert1 = useAlert()

  const [showOthers, setShowOthers] = useState(false)
  const [showSalaryStructure, setShowSalaryStructure] = useState(false)
  const [candidateId, setCandidateId] = useState()

  let job = getJobTitle?.data?.data?.filter((el) => el?.status === 'Active');

  function useHookWithRefCallback() {
    let fetching = false;
    const onScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (!fetching && ((scrollHeight - scrollTop) <= (clientHeight * 2.5))) {
        fetching = true;
        if (candidates.hasNextPage) await candidates.fetchNextPage();
        fetching = false;
      }
    };
    const ref = useRef(null);
    const setRef = useCallback(node => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", onScroll);
      }

      if (node) {
        node.addEventListener("scroll", onScroll);

      }

      ref.current = node
    }, [candidates.hasNextPage]);

    return [setRef];
  }

  const [ref] = useHookWithRefCallback();

  useEffect(() => {
    setSearch({ ...search, status: 'salaryViewCandidates' });
  }, [candidateStatuses?.data?.data, showOthers, showSalaryStructure]);


  if (candidates.isFetching && !candidates.isFetchingNextPage) {
    return <SpinLoader />
  }

  if (candidates.isLoading || candidateStatuses.isLoading || getJobTitle.isLoading || updateCandidate.isLoading, recruiters.isLoading) {
    return <SpinLoader />
  }


  const toggleSalaryView = () => {
    setShowSalaryStructure(!showSalaryStructure);
  };


  const handleSelectJobsColumns = (e) => {
    let value = e.map((a) => a.value)
    setSearch({ ...search, job: value })
    setJobTitle(e)
  }

  const handleRemoveJobsColumns = (e) => {
    let value = e.map((a) => a.value)
    setSearch({ ...search, job: value })
    setJobTitle(e)
  }

  function Reset() {
    setSearch({});
    window.location.reload(false);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  if (getJobTitle.isLoading || getJobTitle.isFetching) {
    return <SpinLoader />
  }

  return (
    <>

      <Box ml={10} mt={16} sx={{ boxShadow: 1 }} >

        <FormControl variant="standard" sx={{ width: 250 }} size="small">
          <TextField
            type="text"
            name="keyword"
            label=""
            placeholder='Candidate Name'
            sx={{ width: 200, height: 55 }}
            value={search?.keyword ? search?.keyword : ''}
            variant='outlined'
            onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })} />
        </FormControl>


        <FormControl>
          <Multiselect
            placeholder='Job Title'
            hidePlaceholder={true}
            displayValue="key"
            onKeyPressFn={function noRefCheck() { }}
            onSelect={(e) => { handleSelectJobsColumns(e) }}
            onRemove={(e) => { handleRemoveJobsColumns(e) }}
            onSearch={function noRefCheck() { }}
            options={getJobTitle?.data?.data?.map((el) => { return { key: el.jobTitle, value: el.jobId } })}
            selectedValues={jobTitle}
            style={{
              multiselectContainer: {
                zIndex: 888,
                "white-space": "nowrap",
              },

              searchBox: {
                margin: '5px 10px 0 0',
                width: '300px',
                height: '50px',
                zIndex: 999,
                overflow: "hidden"
              },
            }}
            showCheckbox
          />

        </FormControl>


        <Button
          variant="contained"
          endIcon={<PersonSearchTwoToneIcon />}
          sx={{ borderRadius: 3 }}
          style={{
            padding: "10px",
            marginBottom: '10px',
            marginLeft: '50px'
          }}
          onClick={(e) => { candidates.refetch() }}>Search</Button>

        <Button variant="contained"
          sx={{ borderRadius: 3 }}
          style={{marginBottom: '10px', marginLeft: '20px' }}
          onClick={(e) => { Reset({}) }} > Reset </Button>


        <Box mt={5} sx={{ background: 'white', width: 'auto', height: '70vh', display: 'flex', flexDirection: 'column' }}>

          {/* Render SalaryView component based on showSalaryStructure state */}
          {showSalaryStructure && <SalaryView close={toggleSalaryView} candidateId={candidateId} pageType={'show'} />}


          <TableContainer ref={ref} sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead style={{ backgroundColor: "#243c80", background: "white" }}>

                <TableRow
                  sx={{
                    "& th": {
                      borderBottom: "2px solid black",
                      fontSize: "0.9rem",
                      color: "white",
                      backgroundColor: "#243c80",
                      borderLeft: "1px solid #3b4864",
                      height: '1px'
                    }
                  }}
                >
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Email</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Job Title</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>HR</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Reporting Manager</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Offer Letter</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Letter Type</TableCell>
                  <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>View Salary Structure</TableCell>

                  {(window.localStorage.getItem('role') === "HR Manager" || window.localStorage.getItem('role') === "TA Manager" || window.localStorage.getItem('role') === "Admin") &&
                    <>
                      <TableCell style={{ fontWeight: 500, fontSize: "15px", color: "white" }}>Edit</TableCell>

                    </>

                  }

                </TableRow>
              </TableHead>

              <TableBody>
                {candidates?.data && candidates?.data.pages.map((page) => page.data.rows.map((candidate) => {
                  return <TableRow hover
                    key={candidate.candidateId}>
                    <TableCell>{candidate.candidateName}</TableCell>
                    <TableCell>{candidate.candidateEmail}</TableCell>
                    <TableCell>{candidate.jobTitle?.jobCode} - {candidate.jobTitle?.jobTitle}</TableCell>
                    <TableCell>{candidate?.hr?.displayName}</TableCell>

                    <TableCell>{candidate?.reportingManagerName}</TableCell>
                    <TableCell>{candidate?.offerLetter === null || candidate?.offerLetter === false ? '-' : 'Offered'}</TableCell>
                    <TableCell>{candidate?.letterType === null ? '-' : candidate?.letterType === false ? 'Manually Sent' : candidate?.letterType === true ? 'Auto Generate' : '-'}</TableCell>
                    <TableCell><ArticleOutlined onClick={(e) => { toggleSalaryView(); setCandidateId(candidate?.candidateId) }} /></TableCell>

                    {(window.localStorage.getItem('role') === "HR Manager" || window.localStorage.getItem('role') === "TA Manager" || window.localStorage.getItem('role') === "Admin") &&
                      <>

                        <TableCell>
                          <EditIcon onClick={(e) => { navigate(`/salaryView/${candidate.candidateId}`) }} />
                        </TableCell>

                      </>

                    }

                  </TableRow>

                }
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box >

    </>
  )
}

export default CandidateSalaryView;