import { useMsal } from '@azure/msal-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { acquireToken } from '../acquireToken';

let baseURL;

if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseURL = 'https://berec.futransolutions.com';
} else if(process.env.REACT_APP_NODE_ENV === 'test'){
    baseURL = 'https://hr-app-test-be.futransolutions.com';
}
else {
    baseURL = 'http://localhost:5000';
}

export const useMetaData = () => {
    const { instance, accounts } = useMsal();
    const role = window.localStorage.getItem('role');
    const queryClient = useQueryClient();

    const metaData = useQuery('metadata', async () =>{
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData');
        return data;

    })


    const updateMetadata = useMutation(

        async (reqInfo) => {
            console.log(reqInfo.formData);
            console.log(reqInfo.id);

            const token = await acquireToken(instance, accounts);
            const client = axios.create({
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-info': role
                }
            });
            const { data } = await client.put(`/metaData`, reqInfo.formData);
            return data;

            
        },

        {
            onSuccess: () => {
                        alert('success');
            },
            onError: (e) => {
                return alert(e.response.data.message);
            }
        },

    );
    
    const candidateStatuses = useQuery('candidateStatuses', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=candidateStatuses');
        return data;
    });

    const interviewStatuses = useQuery('interviewStatuses', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get('/metaData?type=interviewStatuses');
        return data;
    });

    const jobTypes = useQuery('jobTypes', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=jobTypes');
        return data;
    });

    const employmentType = useQuery('employmentType', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=employmentType');
        return data;
    });
    const industrialType = useQuery('industrialType', async () => {

        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get('/metaData?type=industrialType');
        return data;
    });
    const jobLocations = useQuery('jobLocations', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get('/metaData?type=jobLocations');
        return data;
    });

    const sources = useQuery('sources', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=sources');
        return data;
    });

    const jobRequisitionStatuses = useQuery('jobRequisitionStatuses', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=jobRequisitionStatuses');
        return data;
    });

    const backoutReasons = useQuery('backoutReasons', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=backoutReasons');
        return data;
    });
    const disqualifiedReasons = useQuery('disqualifiedReasons', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=disqualifiedReasons');
        return data;
    });

    const costCenter = useQuery('costCenter', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=costCenter');
        return data;
    });

    const department = useQuery('department', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=department');
        return data;
    });

    const division = useQuery('division', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=division');
        return data;
    });

    const devices = useQuery('devices', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=devices');
        return data;
    });

    const gender = useQuery('gender', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=gender');
        return data;
    });

    const shiftTimings = useQuery('shiftTimings', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=shiftTimings');
        return data;
    });

    const costCenters = useQuery('costCenters', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/getOrgDepartments?isCostCenter=true');
        return data;
    });
   
    const useDepartments = (id) => useQuery('departments', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get(`/getOrgDepartments?isDepartment=true&&costCenterId=${id}`);
        return data;
    });

    const useDivisions = (costCenterId, departmentId) => useQuery('divisions', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get(`/getOrgDepartments?isDivision=true&&costCenterId=${costCenterId}&&departmentId=${departmentId}`);
        return data;
    });
    
    const costCentersMulti = useQuery('costCenters', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/getOrgMultiSelectDepartments?isCostCenter=true');
        return data;
    });

    const useDepartmentsMulti = (id) =>  useQuery('departments', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        if (id.length && id.length) {
            console.log(id);
            const { data } = await client.get(`/getOrgMultiSelectDepartments?isDepartment=true&&costCenterId=${id.join(',')}`);
            return data;
        }
    });

    const useDivisionsMulti = (costCenterId, departmentId) => useQuery('divisions', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        if (costCenterId.length && costCenterId.length > 0 && departmentId.length && departmentId.length > 0) {
            const { data } = await client.get(`/getOrgMultiSelectDepartments?isDivision=true&&costCenterId=${costCenterId.join(',')}&&departmentId=${departmentId.join(',')}`);
            return data;
        }
    });

    const salaryDuration = useQuery('salaryDuration', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=duration');

        return data;
    });

    const offerLetterTemplate = useQuery('offerLetterTemplate', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=offerLetterTemplate');

        return data;
    });

    const variablePayDuration = useQuery('variablePayDuration', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=variablePayDuration');

        return data;
    });

    

    const bonusPayDuration = useQuery('bonusPayDuration', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=bonusPayDuration');

        return data;
    });
    
    const annexureFormulae = useQuery('annexureFormulae', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/metaData?type=annexureFormulae');

        return data;
    });

    const probationPeriod = useQuery("probationPeriod", async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
          baseURL: baseURL,
          headers: {
            Authorization: `Bearer ${token}`,
            "user-info": role,
          },
        });
        const { data } = await client.get("/metaData?type=probationPeriod");
        return data;
      });


    return {
        metaData,
        updateMetadata,
        costCentersMulti,
        useDepartmentsMulti,
        useDivisionsMulti,
        candidateStatuses,
        interviewStatuses,
        jobTypes,
        jobLocations,
        sources,
        jobRequisitionStatuses,
        backoutReasons,
        costCenter,
        employmentType,
        department,
        division,
        devices,
        gender,
        shiftTimings,
        industrialType,
        costCenters,
        useDepartments,
        useDivisions,
        costCentersMulti,
        useDepartmentsMulti,
        useDivisionsMulti,
        salaryDuration,
        offerLetterTemplate,
        variablePayDuration,
        bonusPayDuration,
        annexureFormulae,
        probationPeriod,
        disqualifiedReasons
    }
};