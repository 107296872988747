
import React, { useState, useContext, useRef, useReducer, useEffect, useMemo } from 'react';
import moment from 'moment';
import * as xl from 'xlsx';
import * as fileSaver from 'file-saver';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useJobs } from '../../helpers/hooks/jobsHooks';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SpinLoader from '../../components/SpinLoader/SpinLoader';
import { UserContext } from '../../components/Routes/Routes';
import CandidateList from '../../components/CandidateList/CandidateList';
import { useDashboard } from '../../helpers/hooks/dashboardHooks';
import { useUsers } from '../../helpers/hooks/userHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import Multiselect from 'multiselect-react-dropdown';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, TableSortLabel } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const exportToCSV = (apiData, fileName, selectedColumns) => {
  let excelData = [...apiData];
  const candidates = [];
  for (let i = 0; i < apiData.length; i++) {
    for (const key in apiData[i]) {
      let value, value1;
      if (apiData[i][key] && typeof apiData[i][key] === 'object' && apiData[i][key].hasOwnProperty('candidates')) {
        value = apiData[i][key].candidates;
        value1 = value?.map((el) => [{
          RecruiterName: el.createdBy?.displayName,
          CandidateName: el.candidateName,
          Remark: el.remark,
          CreatedDate: moment(el.createdDate).utc().format('DD-MM-YYYY'),
          Status: el.candidateStatus?.displayText.status,
          JoiningDate: moment(el.joiningDate).utc().format('DD-MM-YYYY'),
          ScheduledDate:`Date: ${moment(el?.interviews[0]?.interviewDate)?.utc()?.format('DD-MM-YYYY')} Status: ${el?.interviews[0]?.interviewStatus === undefined ? 'N/A' : el?.interviews[0]?.interviewStatus}` ,
          CallingDate: moment(el.candidateCallingDate).utc().format('DD-MM-YYYY'),
          CandidateEmail: el.candidateEmail, CandidatePhone: el.candidatePhone,
          JobTitle: el.jobTitle?.jobTitle, location: el.jobLocation?.displayText.location,
          Company: el.company, Source: el.source?.displayText, TotalExperience: el.totalExperience,
          location: el.jobLocation?.displayText.location,
          costCenter: el.jobTitle?.costCenter?.displayText.costCenter,
          department: el.jobTitle?.department?.displayText.department,
          division: el.jobTitle?.division?.displayText.division,
          RelevantExperience: el.relevantExperience,
          Source: el.source?.displayText.source,
          backoutReason: el?.backoutReason?.displayText?.backout,
          disqualifiedReason:el?.disqualifiedReason?.displayText?.disqualified,
        }]);

        for (let j = 0; j < value1.length; j++) {
          candidates.push(...value1[j]);
        }
      }
    }
  }
  excelData = excelData.map((obj) => {
    const data = { ...obj };
    for (const key in data) {
      if (selectedColumns?.some((column) => column.value === key)) {
        if ((typeof data[key] === 'object') && (key !== 'assignedRecruiters' && key !== 'assignedHiringManager')) {
          data[key] = data[key].count
        } else if (key === 'assignedHiringManager') {
          if (data[key].length > 0) {
            let hiringManagerNames = ''
            for (let i = 0; i < data[key].length; i++) {
              if (i === 0) {
                hiringManagerNames += data[key][i];
              } else {
                hiringManagerNames += ', ' + data[key][i];
              }
            }
            data[key] = hiringManagerNames;
          }
        }
      } else if (key === 'total' || key === 'assignedRecruiters' || key === 'jobCode' || key === 'jobTitle' ||
        key === 'jobType' || key === 'jobAge' || key === 'jobStatus' || key === 'noOfPositions' || key === 'hiringManager') {

        if (key === 'hiringManager') {
          if (data[key].length > 0) {
            let hiringManagerName = ''
            for (let i = 0; i < data[key].length; i++) {
              if (i === 0) {
                hiringManagerName += data[key][i];
              } else {
                hiringManagerName += ', ' + data[key][i];
              }
            }
            data[key] = hiringManagerName;
          }
        }
        else if (key === 'assignedRecruiters') {
          if (data[key].length > 0) {
            let recruiterNames = ''
            for (let i = 0; i < data[key].length; i++) {
              if (i === 0) {
                recruiterNames += data[key][i];
              } else {
                recruiterNames += ', ' + data[key][i];
              }
            }
            data[key] = recruiterNames;
          }
        }
        else {
          data[key] = data[key];
        }
      }
      else if (key === 'Screening' || key === 'Identified' || key === 'Scheduled' || key === 'Selected' || key === 'Offered' || key === 'Joined' || key === 'DocVerificationCompleted' ||
        key === 'Disqualified' || key === 'Rejected' || key === 'BackOut' || key === 'BackUp' || key === 'Hold') {
        data[key] = data[key]?.count
      }
      else {
        delete data[key];
      }
    }
    return data;
  });
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = xl.utils.json_to_sheet(excelData);
  const ws1 = xl.utils.json_to_sheet(candidates);
  const wb = {
    SheetNames: ["RecruiterDetails", "data"],
    Sheets: { data: ws, RecruiterDetails: ws1 },
  };

  const excelBuffer = xl.write(wb, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  fileSaver.saveAs(fileData, fileName + fileExtension);
}

const columnNames = [
  // { label: 'Job Age(Days)', value: 'jobAge' },
  // { label: 'Priority', value: 'priority' },
  // { label: 'Can engage external consultants?', value: 'canEngageExternalConsultants' },
  // { label: 'Job Status', value: 'jobStatus' },
  // { label: 'Job Created', value: 'jobCreated' },
  // { label: 'Number of Positions', value: 'noOfPositions' },
  { label: 'DocVerificationCompleted', value: 'docVerificationCount' },
  { label: 'Disqualified', value: 'disqualifiedCount' },
  { label: 'Rejected', value: 'rejectedCount' },
  { label: 'BackOut', value: 'backOutCount' },
  { label: 'BackUp', value: 'backUpCount' },
  { label: 'Hold', value: 'holdCount' }];


const statusFields =

  [
    {
      Status: 'Screening',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Screening Age', fieldValue: 'candidateScreeningAge' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' },  //! add every obj
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' }
      ]
    },
    {
      Status: 'Identified',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Identified Age', fieldValue: 'candidateIdentifiedAge' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Notice Period', fieldValue: 'noticePeriodInDays' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
      ]
    },
    {
      Status: 'Scheduled',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Scheduled Age', fieldValue: 'commonStatusWiseDiff' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Notice Period', fieldValue: 'noticePeriodInDays' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 }
      ]
    },
    {
      Status: 'Selected',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Selected Age', fieldValue: 'candidateSelectedAge' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Notice Period', fieldValue: 'noticePeriodInDays' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected Date', fieldValue: 'selectedRejectedDate' }

      ]
    },
    {
      Status: 'DocVerificationCompleted',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'DocVerificationCompleted Age', fieldValue: 'candidateDocAge' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Notice Period', fieldValue: 'noticePeriodInDays' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected Date', fieldValue: 'selectedRejectedDate' },
        { fieldName: 'Doc Vertification Date', fieldValue: 'mandatoryDocsVerifiedDate' }

      ]
    },
    {
      Status: 'Offered',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Offered Age', fieldValue: 'candidateOfferAge' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Notice Period', fieldValue: 'noticePeriodInDays' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected Date', fieldValue: 'selectedRejectedDate' },
        { fieldName: 'Doc Verification Date', fieldValue: 'documentVerificationInitiatedOn' },
        { fieldName: 'Offer Date', fieldValue: 'offerDate' },
        { fieldName: 'Offered Salary', fieldValue: 'offeredSalary' },
      ]
    },
    {
      Status: 'Joined',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Joined Age', fieldValue: 'candidateJoinedAge' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Notice Period', fieldValue: 'noticePeriodInDays' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected Date', fieldValue: 'selectedRejectedDate' },
        { fieldName: 'Doc Verification Date', fieldValue: 'documentVerificationInitiatedOn' },
        { fieldName: 'Offer Date', fieldValue: 'offerDate' },
        { fieldName: 'Offered Salary', fieldValue: 'offeredSalary' },
        { fieldName: 'Joining Date', fieldValue: 'joiningDate' },
      ]
    },
    {
      Status: 'Disqualified',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Disqualified Age', fieldValue: 'commonStatusWiseDiff' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected/Rejected Date', fieldValue: 'selectedRejectedDate' },
        { fieldName: 'Disqualified', fieldValue: 'disqualifiedReason' }
      ]
    },
    {
      Status: 'Rejected',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Rejected Age', fieldValue: 'candidateRejectedAge' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected/Rejected Date', fieldValue: 'selectedRejectedDate' },
      ]
    },
    {
      Status: 'BackOut',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'BackOut Age', fieldValue: 'commonStatusWiseDiff' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected/Rejected Date', fieldValue: 'selectedRejectedDate' },
        { fieldName: 'BackOut', fieldValue: 'backoutReason' }
      ]
    },
    {
      Status: 'BackUp',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'BackUp Age', fieldValue: 'commonStatusWiseDiff' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'Selected/Rejected Date', fieldValue: 'selectedRejectedDate' },
        { fieldName: 'BackOut', fieldValue: 'backoutReasonId' }
      ]
    },
    {
      Status: 'Hold',
      Fields: [
        { fieldName: 'Current Status', fieldValue: 'candidateStatus' },
        { fieldName: 'Candidate Name', fieldValue: 'candidateName' },
        { fieldName: 'Job Title', fieldValue: 'jobTitle' },
        { fieldName: 'Hold Age', fieldValue: 'commonStatusWiseDiff' },
        { fieldName: 'Email', fieldValue: 'candidateEmail' },
        { fieldName: 'Mobile', fieldValue: 'candidatePhone' },
        { fieldName: 'Job Location', fieldValue: 'jobLocation' },
        { fieldName: 'Distance from Office', fieldValue: 'distanceFromOffice' },
        { fieldName: 'Source', fieldnpmValue: 'source' }, //!
        { fieldName: 'Recruiter Name', fieldValue: 'createdBy' },
        { fieldName: 'Reporting Manager', fieldValue: 'reportingManager' },
        { fieldName: 'Calling date', fieldValue: 'candidateCallingDate' },
        { fieldName: 'Current CTC', fieldValue: 'currentCTC' },
        { fieldName: 'Expected CTC', fieldValue: 'expectedCTC' },
        { fieldName: 'Screening Date', fieldValue: 'createdDate' },
        { fieldName: 'Identified Date', fieldValue: 'identifiedDate' },
        { fieldName: 'InterView 1st Date', fieldValue: 'interviews', round: 1 },
        { fieldName: 'InterView 2nd Date', fieldValue: 'interviews', round: 2 },
        { fieldName: 'BackOut', fieldValue: 'backoutReasonId' }
      ]
    },

  ]

const Dashboard = () => {
  const { candidateStatuses, jobRequisitionStatuses, costCenter, department, division, costCentersMulti, useDepartmentsMulti, useDivisionsMulti } = useMetaData();

  const { useGetJobDetails, useGetJobs } = useJobs();
  const jobs = useGetJobDetails();


  const { useGetDashboard, useGetDashboardCandidates, useGetDashboardExcel } = useDashboard();

  const [filter, setFilter] = useState({});
  const [jobDetails, setJobDetails] = useState(null)
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showCandidateList, setShowCandidateList] = useState(null);
  const [candidates, setCandidates] = useState(null);
  const dashboard = useGetDashboard(filter);
  const value = useContext(UserContext);



  const { useHR, useHiringManagers, useRecruiters } = useUsers(value?.data?.assignedRoles?.assignedRole.role)
  const HR = useHR()
  const hiringManagers = useHiringManagers();
  const recruiters = useRecruiters();


  const [jobSelectedColumns, setJobSelectedColumns] = useState([]);
  const [candidateFilter, setCandidateFilter] = useState({});
  const tempCandidates = useGetDashboardCandidates(candidateFilter);
  const [open, setOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [scroll, setScroll] = React.useState('paper');
  const [excelFilter, setExcelFilter] = useState({});
  const excelCandidates = useGetDashboardExcel(filter, enabled);
  const [jobTitle, setJobTitle] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);
  const [recruiterUserId, setRecruiterUserId] = useState([]);
  const [costCenter1, setcostCenter] = useState([]);
  const [department1, setdepartment] = useState([]);
  const [division1, setdivision] = useState([]);
  const [removeUserId, setRemoveUserId] = useState([]);
  const [hrSelectedColumns, setHrSelectedColumns] = useState([]);
  const [hrSelectedColumns1, setHrSelectedColumns1] = useState([]);
  const [otherColumns, setOtherColumns] = useState([]);
  const [resetRefetch, setresetRefetch] = useState(false);
  const [searchType, setsearchType] = useState();
  const [asOfDate, setAsOfDate] = useState(false);
  const [boolean, setBoolean] = useState(true);
  const [costCenterIds, setCostCenterIds] = useState([]);
  const [departmentIds, setDepartmentIds] = useState([]);
  const departments = useDepartmentsMulti(costCenterIds, enabled);
  const divisions = useDivisionsMulti(costCenterIds, departmentIds, enabled);
  const [referralChecked, setReferralChecked] = React.useState(false);
  const [rehirechecked, setRehireChecked] = React.useState(false);
  let hiringManagerList = hiringManagers?.data?.data.map((el) => el.displayName)
  const [sortOrder, setSortOrder] = useState("ascending"); // Default to ascending order


  // const [dashboardData, setDashboardData] = useState(dashboard?.data?.data);
  const [sortBy, SetSortBy] = useState();

  useEffect(() => {

    if (hrSelectedColumns?.length <= 0 && dashboard?.data?.data) {
      setJobSelectedColumns(dashboard?.data?.data?.map((job) => job.jobTitle));
    }

    if (hrSelectedColumns?.length <= 0) {
      if (value[value.length - 1] === "all") {
        setJobSelectedColumns(jobSelectedColumns.length === dashboard?.data?.data?.length ? [] : dashboard?.data?.data?.map((job) => job.jobTitle));
        return;
      }
    }
    if (candidateFilter) {
      tempCandidates.refetch();
    }

    if (excelCandidates?.data?.data && !excelCandidates.isLoading && !excelCandidates.isFetching && enabled) {
      exportToCSV(excelCandidates.data.data, `dashboard-${new Date().toLocaleDateString()}`, selectedColumns);
      window.location.reload(false);
    }

    if (resetRefetch) {
      dashboard.refetch();
      setresetRefetch(false);
    }
    if (costCenterIds?.length && costCenterIds?.length > 0) {
      departments.refetch()
    }
    if (departmentIds?.length && departmentIds?.length > 0) {
      divisions.refetch()
    }

    // if (dashboard?.data?.data) {
    //   setDashboardData(dashboard?.data?.data)
    // }
  }, [candidateFilter,
    enabled,
    excelCandidates?.data?.data,
    hrSelectedColumns,
    resetRefetch,
    searchType,
    costCenterIds,
    departmentIds,
    dashboard?.data?.data,

    // [costCenterIds, departmentIds]
  ]);

  const sortedData = useMemo(() => {
    // Depending on the sort order, sort the data accordingly
    console.log(sortOrder);
    if (sortOrder === "JobAgeAscending") {
      return dashboard?.data?.data?.sort((a, b) => a.jobAge - b.jobAge);
    } else if (sortOrder === "JobAgeDescending") {
      return dashboard?.data?.data?.sort((a, b) => b.jobAge - a.jobAge);
    }

    else if (sortOrder === "positionDsc") {
      return dashboard?.data?.data?.sort((a, b) => b.noOfPositions - a.noOfPositions)
    } else if (sortOrder === "positionAsc") {
      return dashboard?.data?.data?.sort((a, b) => a.noOfPositions - b.noOfPositions)
    } else if (sortOrder === "totalDsc") {
      return dashboard?.data?.data?.sort((a, b) => b.total - a.total)
    } else if (sortOrder === "totalAsc") {
      return dashboard?.data?.data?.sort((a, b) => a.total - b.total)
    } else if (sortOrder === "ScreeningDsc") {
      dashboard?.data?.data?.sort((a, b) => {
        const ScreeningA = a.Screening ? a.Screening.count : 0;
        const ScreeningB = b.Screening ? b.Screening.count : 0;

        return ScreeningB - ScreeningA
      })
    } else if (sortOrder === "ScreeningAsc") {
      dashboard?.data?.data?.sort((a, b) => {
        const ScreeningA = a.Screening ? a.Screening.count : 0;
        const ScreeningB = b.Screening ? b.Screening.count : 0;

        return ScreeningA - ScreeningB
      })
    }
    else if (sortOrder === "IdentifiedDsc") {
      dashboard?.data?.data?.sort((a, b) => {
        const IdentifiedA = a.Identified ? a.Identified.count : 0;
        const IdentifiedB = b.Identified ? b.Identified.count : 0;

        return IdentifiedB - IdentifiedA
      })
    }
    else if (sortOrder === "Identifiedasc") {
      dashboard?.data?.data?.sort((a, b) => {
        const IdentifiedA = a.Identified ? a.Identified.count : 0;
        const IdentifiedB = b.Identified ? b.Identified.count : 0;

        return IdentifiedA - IdentifiedB
      })
    }
    else if (sortOrder === "scheduledDes") {
      dashboard?.data?.data?.sort((a, b) => {
        const ScheduledA = a.Scheduled ? a.Scheduled.count : 0;
        const ScheduledB = b.Scheduled ? b.Scheduled.count : 0;

        return ScheduledB - ScheduledA
      })
    }
    else if (sortOrder === "scheduledAsc") {
      dashboard?.data?.data?.sort((a, b) => {
        const ScheduledA = a.Scheduled ? a.Scheduled.count : 0;
        const ScheduledB = b.Scheduled ? b.Scheduled.count : 0;

        return ScheduledA - ScheduledB
      })
    }
    else if (sortOrder === "SelectedDes") {
      dashboard?.data?.data?.sort((a, b) => {
        const SelectedA = a.Selected ? a.Selected.count : 0;
        const SelectedB = b.Selected ? b.Selected.count : 0;

        return SelectedB - SelectedA
      })
    }
    else if (sortOrder === "Selectedasc") {
      dashboard?.data?.data?.sort((a, b) => {
        const SelectedA = a.Selected ? a.Selected.count : 0;
        const SelectedB = b.Selected ? b.Selected.count : 0;

        return SelectedA - SelectedB
      })
    }
    else if (sortOrder === "OfferedDes") {
      dashboard?.data?.data?.sort((a, b) => {
        const OfferedA = a.Offered ? a.Offered.count : 0;
        const OfferedB = b.Offered ? b.Offered.count : 0;

        return OfferedB - OfferedA
      })
    }
    else if (sortOrder === "Offeredasc") {
      dashboard?.data?.data?.sort((a, b) => {
        const OfferedA = a.Offered ? a.Offered.count : 0;
        const OfferedB = b.Offered ? b.Offered.count : 0;

        return OfferedA - OfferedB
      })
    }

    else if (sortOrder === "JoinedDes") {
      dashboard?.data?.data?.sort((a, b) => {
        const JoinedA = a.Joined ? a.Joined.count : 0;
        const JoinedB = b.Joined ? b.Joined.count : 0;

        return JoinedB - JoinedA
      })
    }
    else if (sortOrder === "Joinedasc") {
      dashboard?.data?.data?.sort((a, b) => {
        const JoinedA = a.Joined ? a.Joined.count : 0;
        const JoinedB = b.Joined ? b.Joined.count : 0;

        return JoinedA - JoinedB
      })
    }

  }, [dashboard?.data?.data, sortOrder]);

  if (dashboard.isLoading || HR.isLoading || recruiters.isLoading || dashboard.isFetching || excelCandidates.isLoading || excelCandidates.isFetching) {
    return <SpinLoader />
  }

  const reset = (e) => {
    console.log('hit')
    setHrSelectedColumns1({})
    setRecruiterUserId({})
    setcostCenter({})
    setdepartment({})
    setdivision({})
    setOtherColumns({})
    setSelectedColumns([])
    setJobStatus({})
    setFilter({});
    setresetRefetch(true)
    setJobSelectedColumns(jobSelectedColumns.length === dashboard?.data?.data?.length ? [] : dashboard?.data?.data?.map((job) => job.jobTitle));
    setsearchType();
    setAsOfDate(false);
    setReferralChecked(false);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 500,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);
    setSelectedColumns(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleJob = (event) => {
    const { target: { value }, } = event;

    if (value[value.length - 1] === "all") {
      setJobSelectedColumns(jobSelectedColumns.length === dashboard?.data?.data?.length ? [] : dashboard?.data?.data?.map((job) => job.jobTitle));
      return;
    }
    setJobSelectedColumns(typeof value === 'string' ? value.split(',') : value);
  }

  const handleClick = (e, data, statusName, statusId) => {

    if (searchType === 'AsOfDate') {
      {
        setCandidateFilter({
          jobId: data?.jobId,
          candidateStatusId: statusId,
          recruiter: filter?.recruiter,
          hiringManager: filter?.hiringManager,
          searchType: 'AsOfDate',
          jobStatus: jobStatus,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      }
      if (data?.[statusName]?.count > 0) {
        setOpen(true);
        setJobDetails({
          status: statusName,
          jobCode: data.jobCode,
          jobTitle: data.jobTitle
        });
      }
    } else {
      if (filter?.startDate && filter?.endDate) {
        setCandidateFilter({
          jobId: data?.jobId,
          candidateStatusId: statusId,
          recruiter: filter?.recruiter,
          startDate: filter?.startDate,
          endDate: filter?.endDate,
          searchType: 'Normal',
          jobStatus: jobStatus,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      } else {
        setCandidateFilter({
          jobId: data?.jobId,
          candidateStatusId: statusId,
          recruiter: filter?.recruiter,
          searchType: 'Normal',
          jobStatus: jobStatus,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      }

      if (data?.[statusName]?.count > 0) {
        setOpen(true);
        setJobDetails({
          status: statusName,
          jobCode: data.jobCode,
          jobTitle: data.jobTitle
        });
      }
    }

  };
  const handleTotal = (e, statusName, statusCount) => {
    let Status = jobStatus?.map((el) => el.value)
    if (searchType === 'AsOfDate') {
      if (filter?.startDate && filter?.endDate) {
        setCandidateFilter({
          recruiter: filter?.recruiter,
          startDate: filter?.startDate,
          endDate: filter?.endDate,
          statusName: statusName,
          hiringManager: filter?.hiringManager,
          type: 'total',
          searchType: 'AsOfDate',
          jobStatus: Status,
          jobId: filter?.jobId,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      } else {
        setCandidateFilter({
          recruiter: filter?.recruiter,
          statusName: statusName,
          hiringManager: filter?.hiringManager,
          type: 'total',
          searchType: 'AsOfDate',
          jobStatus: Status,
          jobId: filter?.jobId,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      }

      if (statusCount > 0) {
        setOpen(true);
        setJobDetails({
          status: statusName,
        });
      }
    } else {
      if (filter?.startDate && filter?.endDate) {
        setCandidateFilter({
          recruiter: filter?.recruiter,
          startDate: filter?.startDate,
          endDate: filter?.endDate,
          statusName: statusName,
          hiringManager: filter?.hiringManager,
          type: 'total',
          searchType: 'Normal',
          jobStatus: Status,
          jobId: filter?.jobId,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      } else {
        setCandidateFilter({
          recruiter: filter?.recruiter,
          statusName: statusName,
          hiringManager: filter?.hiringManager,
          type: 'total',
          searchType: 'Normal',
          jobStatus: Status,
          jobId: filter?.jobId,
          costCenter: filter.costCenter,
          department: filter.department,
          division: filter.division,
          referral: filter.referral
        });
      }

      if (statusCount > 0) {
        setOpen(true);
        setJobDetails({
          status: statusName,
        });
      }
    }

  };

  //* Tempory disabled *//
  const handleVerticalTotal = (e, data) => {

    let Status = jobStatus?.map((el) => el.value)

    if (searchType === 'AsOfDate') {
      if (filter?.startDate && filter?.endDate) {
        setCandidateFilter({
          jobId: data.jobId,
          startDate: filter?.startDate,
          endDate: filter?.endDate,
          recruiter: filter?.recruiter,
          hiringManager: filter?.hiringManager,
          type: 'verticalTotal',
          searchType: 'AsOfDate',
          jobStatus: Status
        });
      } else {
        setCandidateFilter({
          jobId: data.jobId,
          recruiter: filter?.recruiter,
          hiringManager: filter?.hiringManager,
          type: 'verticalTotal',
          searchType: 'AsOfDate',
          jobStatus: Status

        });
      }

      if (data && Object.keys(data).length > 0) {
        setOpen(true);
        setJobDetails({
          status: 'All',
          jobCode: data.jobCode,
          jobTitle: data.jobTitle
        });
      }
    } else {
      if (filter?.startDate && filter?.endDate) {
        setCandidateFilter({
          jobId: data.jobId,
          startDate: filter?.startDate,
          endDate: filter?.endDate,
          recruiter: filter?.recruiter,
          hiringManager: filter?.hiringManager,
          type: 'verticalTotal',
          jobStatus: Status
        });
      } else {
        setCandidateFilter({
          jobId: data.jobId,
          recruiter: filter?.recruiter,
          hiringManager: filter?.hiringManager,
          type: 'verticalTotal',
          jobStatus: Status
        });
      }

      if (data && Object.keys(data).length > 0) {
        setOpen(true);
        setJobDetails({
          status: 'All',
          jobCode: data.jobCode,
          jobTitle: data.jobTitle

        });
      }
    }


  }
  const handleExcel = async (e) => {
    if (filter?.endDate !== undefined && filter?.endDate !== undefined) {
      const start = new Date(filter?.startDate);
      const end = new Date(filter?.endDate);

      const differenceInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

      if (differenceInMonths <= 3) {
        e.preventDefault();
        setEnabled(true);
        excelCandidates.refetch()
      } else {
        alert('Start Date and End Date should be 3 months')
      }
    } else {
      alert('Please add StartDate EndDate for Excel file', 'error');
    }
  };

  var array;
  var filtered;
  const handleSelectHiringManagerUserId = (e) => {
    let userid = e.map((a) => a.value)
    setFilter({ ...filter, hiringManager: userid })

    setHrSelectedColumns1(e);

  }
  const handleRemoveHiringManagerUserId = (e, value) => {
    let userid = e.map((a) => a.value)
    setFilter({ ...filter, hiringManager: userid })
    setHrSelectedColumns1(e);
  }
  const handleSelectRecruitersUserId = (e) => {
    let value = e.map((a) => a.value)
    setFilter({ ...filter, recruiter: value })
    setRecruiterUserId(e)
  }
  const handleRemoveRecruitersUserId = (e) => {

    let value = e.map((a) => a.value)
    setFilter({ ...filter, recruiter: value })
    setRecruiterUserId(e)
  }
  //!-----------------------------------------------
  const handleSelectcostCenterId = (e) => {
    console.log("costCenterId", e)
    let userid = e.map((a) => a.value)
    setCostCenterIds(userid)
    setFilter({ ...filter, costCenter: userid })
    setcostCenter(e)
  }
  const handleSelectdepartmentId = (e) => {
    console.log("departmentid", e)
    let userid = e.map((a) => a.value)
    setDepartmentIds(userid);
    setFilter({ ...filter, department: userid })
    setdepartment(e)

  }
  const handleSelectdivisionId = (e) => {
    console.log("divisionId", e)
    let userid = e.map((a) => a.value)
    setFilter({ ...filter, division: userid })
    setdivision(e)
  }
  const handleRemovecostCenterId = (e) => {
    let userid = e.map((a) => a.value)
    setCostCenterIds(userid)
    setFilter({ ...filter, costCenter: userid })
    setcostCenter(e)
  }
  const handleRemovedepartmentId = (e) => {
    let userid = e.map((a) => a.value)
    setFilter({ ...filter, department: userid })
    setdepartment(e)
  }
  const handleRemovedivisionId = (e) => {
    let userid = e.map((a) => a.value)
    setFilter({ ...filter, division: userid })
    setdivision(e)
  }
  //!-----------------------------------------------
  const handleSelectOtherColumns = (e) => {
    let value = e.map((a) => a.key)
    setSelectedColumns(typeof value === 'string' ? value.split(',') : value)
    setOtherColumns(e)
  }
  const handleRemoveOtherColumns = (e) => {
    let value = e.map((a) => a.key)
    setSelectedColumns(value)
    setOtherColumns(e)
  }
  const handleSelectJobStatusColumns = (e) => {

    let value = e.map((a) => a.key)

    console.log(value);
    setJobStatus(e)
    setFilter({ ...filter, status: value })

  }
  const handleRemoveJobStatusColumns = (e) => {
    let value = e.map((a) => a.key)
    setJobStatus(e)
    setFilter({ ...filter, status: value })

  }
  const handleSelectJobsColumns = (e) => {
    let value = e.map((a) => a.value)
    setJobTitle(e)
    setFilter({ ...filter, jobId: value })
  }
  const handleRemoveJobsColumns = (e) => {
    let value = e.map((a) => a.key)
    setJobTitle(e)
    setFilter({ ...filter, jobId: value })
  }
  const statusOptions = [
    { value: 'All', key: 'All' },
    { value: 'Active', key: 'Active' },
    { value: 'InActive', key: 'InActive' },
    { value: 'Hold', key: 'Hold' }
  ]
  const JobTitles = jobs?.data?.data?.filter((el) => { return { jobTitle: el.jobTitle, jobId: el.jobId } });
  const handleData = (e) => {
    if (e.target.value === 'true') {
      // if(filter.searchType === 'AsOfDate'){
      setsearchType({})
      setFilter({ ...filter, searchType: 'Normal' });
      // }
      setBoolean(true);
    } else if (e.target.value === 'false') {
      setsearchType('AsOfDate');
      setFilter({ ...filter, searchType: 'AsOfDate' });
      setBoolean(false);
    }
  }

  const handleCandidateType = (e) => {

    if (e.target.name === 'referral') {
      setReferralChecked(e.target.checked);
      setFilter({ ...filter, referral: e.target.checked })
    } else {
      setRehireChecked(e.target.checked);
      setFilter({ ...filter, rehire: e.target.checked })
    }
  }

  const getStartAndEndDate = () => {
    const today = new Date();
    const currentDay = today.getDay();
    const daysSinceMonday = (currentDay + 6) % 7; // 0 for Monday, 1 for Tuesday, ..., 6 for Sunday

    const start = new Date(today);
    start.setDate(today.getDate() - daysSinceMonday);
    start.setHours(0, 0, 0, 0);
    const end = new Date(today);
    console.log(start)
    console.log(end)
    console.log('IIIIIIIIIIIIIIIIIIIIIIIIIIIS')

    setFilter({ ...filter, startDate: start, endDate: end });
  };

  return (
    <Box ml={10} mt={15}>


      <Grid container>
        {(showCandidateList && !tempCandidates.isLoading) &&
          <CandidateList candidates={tempCandidates?.data?.data} setShowCandidateList={setShowCandidateList} jobDetails={jobDetails} />}


        {window.localStorage.getItem('role') !== 'Hiring Manager' &&
          <Grid item xs='auto'>
            <FormControl sx={{ m: 1 }}>
              <label> Select Hiring Manager</label>
              <Multiselect
                displayValue="key"
                onKeyPressFn={function noRefCheck() { }}
                onSelect={(e) => { handleSelectHiringManagerUserId(e) }}
                onRemove={(e, value) => { handleRemoveHiringManagerUserId(e, value) }}
                onSearch={function noRefCheck() { }}
                disabled={(window.localStorage.getItem('role') === 'Recruiter') ? true : false}
                options={hiringManagers?.data?.data?.map((el) => { return { key: el.displayName, value: el.userId } })}
                selectedValues={hrSelectedColumns1}
                onChange={(e) => handleChange(e)}
                selectionLimit={1}
                hidePlaceholder={true}
                style={{
                  chips: {

                  },
                  multiselectContainer: {
                    // color: '1px solid blue',
                    zIndex: 888,
                    "white-space": "nowrap",

                  },
                  searchBox: {
                    // border: 'none',
                    // 'border-radius': '0px',
                    margin: '10px 10px 0 0',
                    width: '200px',
                    height: '40px',
                    zIndex: 9999,
                    overflow: "hidden"
                  },
                }}
                showCheckbox
              />
            </FormControl>
          </Grid>

        }
        <Grid item xs='auto'>
          {(window.localStorage.getItem('role') === 'HR' || window.localStorage.getItem('role') === 'TA Manager' || window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') === 'Hiring Manager') &&

            <>
              <FormControl sx={{ m: 1 }}>
                <label> Select Recruiter</label>
                <Multiselect
                  hidePlaceholder={true}
                  displayValue="key"
                  selectionLimit={1}
                  onKeyPressFn={function noRefCheck() { }}
                  onSelect={(e) => { handleSelectRecruitersUserId(e) }}
                  onRemove={(e) => { handleRemoveRecruitersUserId(e) }}
                  onSearch={function noRefCheck() { }}
                  options={recruiters?.data?.data?.map((el) => { return { key: el.displayName, value: el.userId } })}
                  selectedValues={recruiterUserId}
                  style={{
                    chips: {
                    },
                    multiselectContainer: {
                      zIndex: 888,
                      "white-space": "nowrap",

                    },
                    searchBox: {
                      margin: '10px 10px 0 0',
                      width: '200px',
                      height: '40px',
                      zIndex: 999,
                      overflow: "hidden"
                    },
                  }}
                  showCheckbox
                />

              </FormControl>
            </>
          }
        </Grid>

        <Grid item xs='auto'>

          <FormControl sx={{ m: 1 }}>
            <label>Job Title</label>
            <Multiselect
              hidePlaceholder={true}
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSelect={(e) => { handleSelectJobsColumns(e) }}
              onRemove={(e) => { handleRemoveJobsColumns(e) }}
              onSearch={function noRefCheck() { }}
              options={JobTitles?.map((el) => { return { key: el.jobTitle, value: el.jobId } })}
              selectedValues={jobTitle}
              style={{
                chips: {
                },
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '10px 10px 0 0',
                  width: '250px',
                  height: '40px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>

        </Grid>

        <Grid item xs='auto'>
          <FormControl sx={{ m: 1 }}>
            <label>Job Status</label>
            <Multiselect
              hidePlaceholder={true}
              placeholder=' Select Job Status'
              displayValue="key"
              selectionLimit={1}
              onKeyPressFn={function noRefCheck() { }}
              onSelect={(e) => { handleSelectJobStatusColumns(e) }}
              onRemove={(e) => { handleRemoveJobStatusColumns(e) }}
              onSearch={function noRefCheck() { }}
              options={statusOptions}
              selectedValues={jobStatus}
              style={{
                chips: {
                },
                multiselectContainer: {
                  zIndex: 800,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '10px 10px 0 0',
                  width: '200px',
                  height: '40px',
                  zIndex: 900,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>

        </Grid>

        <Grid item xs='auto'>
          <FormControl sx={{ m: 1 }}>

            <label>Other Columns</label>
            <Multiselect
              hidePlaceholder={true}
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSelect={(e) => { handleSelectOtherColumns(e) }}
              onRemove={(e) => { handleRemoveOtherColumns(e) }}
              onSearch={function noRefCheck() { }}
              options={columnNames?.map((el) => { return { key: el.value, value: el.label } })}
              selectedValues={otherColumns}
              style={{
                chips: {
                },
                multiselectContainer: {
                  zIndex: 800,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '10px 10px 0 0',
                  width: '200px',
                  height: '40px',
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>
        </Grid>
        <Grid item xs='auto'>
          <FormControl sx={{ m: 1 }}>
            Cost Center
            <Multiselect
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSearch={function noRefCheck() { }}
              onSelect={(e) => { handleSelectcostCenterId(e) }}
              onRemove={(e) => { handleRemovecostCenterId(e) }}
              options={costCentersMulti?.data?.data?.map((data) => { return { value: data?.metaDataId, key: data?.displayText } })}
              selectedValues={costCenter1}
              onChange={(e, data) => {
                setFilter({ ...filter, costCenter: data?.value })
              }}
              style={{
                chips: {
                },
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '10px 10px 0 0',
                  width: '200px',
                  height: '40px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>
        </Grid>

        <Grid item xs='auto'>
          <FormControl sx={{ m: 1 }} >
            Department
            <Multiselect
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSearch={function noRefCheck() { }}
              onSelect={(e) => { handleSelectdepartmentId(e) }}
              onRemove={(e) => { handleRemovedepartmentId(e) }}
              options={departments?.data?.data?.map((data) => { return { value: data?.metaDataId, key: data?.displayText } })}
              selectedValues={department1}
              disable={costCenter1.length === 0 ? true : false}
              onChange={(e, data) => {
                setFilter({ ...filter, department: data?.value })
              }}
              style={{
                chips: {
                },
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '10px 10px 0 0',
                  width: '200px',
                  height: '40px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>
        </Grid>

        <Grid item xs='auto'>
          <FormControl sx={{ m: 1 }}>
            Division
            <Multiselect
              displayValue="key"
              onKeyPressFn={function noRefCheck() { }}
              onSearch={function noRefCheck() { }}
              onSelect={(e) => { handleSelectdivisionId(e) }}
              onRemove={(e) => { handleRemovedivisionId(e) }}
              options={divisions?.data?.data?.map((data) => { return { value: data?.metaDataId, key: data?.displayText } })}
              // onKeyDown={(e) => handleKeyDown(e)}
              selectedValues={division1}
              disable={department1.length === 0 ? true : false || costCenter1.length === 0 ? true : false}
              // selectionLimit={1}
              onChange={(e, data) => {
                setFilter({ ...filter, division: data?.value })
              }}
              style={{
                chips: {
                },
                multiselectContainer: {
                  zIndex: 888,
                  "white-space": "nowrap",
                },
                searchBox: {
                  margin: '10px 10px 0 0',
                  width: '250px',
                  height: '40px',
                  zIndex: 999,
                  overflow: "hidden"
                },
              }}
              showCheckbox
            />
          </FormControl>
        </Grid>

        {(window.localStorage.getItem('role') !== 'Hiring Manager') &&

          <>
            <Grid item xs='auto' >
              <FormControl sx={{ m: 1, mt: 2 }}>


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    id="startDate"

                    disabled={filter?.searchType === 'AsOfDate' ? true : false}

                    value={filter?.startDate ? filter?.startDate : null}
                    onChange={(newDate) => {
                      setFilter({ ...filter, startDate: newDate });
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: '200px', height: '40px' }} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs='auto'>

              <FormControl sx={{ m: 1, ml: 3, mt: 2 }} >

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={filter?.searchType === 'AsOfDate' ? true : false}

                    label="End Date"
                    id="endDate"
                    value={filter?.endDate ? filter?.endDate : null}
                    onChange={(newDate) => {
                      setFilter({ ...filter, endDate: newDate });
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: '200px' }} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </>

        }


        <Grid item xs='auto' ml={3}>
          <FormControl variant="standard" sx={{ width: 150, marginTop: 2.5, marginBottom: 1 }} size="small">
            <FormControlLabel control={<Checkbox name={'referral'} checked={referralChecked} onChange={handleCandidateType} inputProps={{ 'aria-label': 'controlled' }} />} label="Referrals" />
            {/* <FormControlLabel control={<Checkbox name={'rehire'}  checked={rehirechecked} onChange={handleCandidateType}/>} label="Re-hire" /> */}

          </FormControl>
        </Grid>


        {/* Radio Button */}
        <Grid Item xs='auto' ml={2} >
          <FormControl variant="standard" sx={{ marginTop: 2.5, marginBottom: 1 }} size="small">
            <RadioGroup
              row
              required
              aria-labelledby="input-radio-buttons-group-fresher-label"
              name="searchType"
              variant="filled"
              defaultValue={boolean}
              onChange={(e) => handleData(e)}
            >
              <FormControlLabel value="true" control={<Radio required={true} />} label="StatusWise" />
              <FormControlLabel value="false" control={<Radio required={true} />} label="CurrentData" />
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid Item xs='auto' mt={1}>
          <FormControl sx={{ m: 1, minWidth: 100 }} >
            <Button variant="contained" onClick={(e) => {

              if (boolean === true) {
                console.log('Boolean True')
                setEnabled(false);
                dashboard.refetch()
              }
              else {
                console.log('Boolean False')
                setsearchType('AsOfDate');
                setFilter({ ...filter, searchType: 'AsOfDate' });
                dashboard.refetch()
              }
            }}>Filter</Button>
          </FormControl>
        </Grid>

        <Grid Item xs='auto' mt={1}>
          <FormControl sx={{ m: 1, minWidth: 100 }} >
            <Button variant="contained" onClick={(e) => { reset(e); setJobTitle([]) }}>Reset</Button>
          </FormControl>
        </Grid>

        {(window.localStorage.getItem('role') === 'TA Manager') &&
          <Grid Item xs='auto' mt={1}>
            <FormControl sx={{ m: 1, minWidth: 100 }} >
              <Button variant="contained"
                onClick={() => getStartAndEndDate(0)}
              >Weekly Dashboard</Button>
            </FormControl>
          </Grid>
        }

        {(window.localStorage.getItem('role') !== 'HR' ||
          window.localStorage.getItem('role') === 'TA Manager' ||
          window.localStorage.getItem('role') === 'Admin' || window.localStorage.getItem('role') !== 'Hiring Manager'
          || window.localStorage.getItem('role') === 'Recruiter') &&
          <Grid Item xs='auto' mt={1}>
            <FormControl sx={{ m: 1, minWidth: 100, mt: 1 }}>
              <Button variant="contained" type='button' disabled={searchType !== 'AsOfDate' ? false : true} onClick={(e) => { handleExcel(e) }}>Export Excel</Button>
            </FormControl>
          </Grid>
        }
      </Grid>

      <Box sx={{
        background: 'white', width: 'auto',
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'flex-end'
      }}>
        <TableContainer component={Paper} sx={{ width: '100%', overflow: 'auto' }}>
          <Table stickyHeader aria-label="sticky table" >
            <TableHead stickyHeader>


              <TableRow
                sx={{
                  "& th": {
                    bgcolor: 'background.paper',
                    backgroundColor: "#243c80",
                    color: 'white',
                  }
                }}>
                <TableCell
                  align="left"
                  style={{
                    position: 'sticky',
                    left: 0,

                    top: 0, zIndex: 666,
                    minWidth: 100,
                    maxWidth: 100,

                  }}>Job Code


                </TableCell>

                <TableCell
                  align="left"
                  style={{
                    position: 'sticky',
                    left: 100,

                    top: 0, zIndex: 666,

                    minWidth: 200,
                    maxWidth: 200
                  }}>Job Title</TableCell>

                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>CostCenter</TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Department</TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Division</TableCell>

                {/* <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Job Age</TableCell> */}

                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>

                  <p style={{ marginTop: '25px' }}>Job Age</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'jobAgeDes' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small'
                        onClick={() => {
                          SetSortBy('jobAgeDes')
                          setSortOrder("JobAgeDescending");
                        }}
                      />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'jobAgeAes' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small'
                        onClick={() => {
                          SetSortBy('jobAgeAes')
                          setSortOrder("JobAgeAscending");
                        }}

                      />
                    </IconButton>
                  </div>
                </TableCell>

                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Hiring Manager</TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Recruiters</TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>

                  <p style={{ marginTop: '25px' }}>Positions</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'positionAsc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('positionAsc')
                        setSortOrder("positionAsc");
                        // setDashboardData([...dashboardData]?.sort((a, b) => b.noOfPositions - a.noOfPositions))
                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'positionDsc' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {

                        SetSortBy('positionDsc')
                        setSortOrder("positionDsc");
                        // setDashboardData([...dashboardData]?.sort((a, b) => a.noOfPositions - b.noOfPositions))
                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Status</TableCell>
                {/* <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>Job Status</TableCell> */}
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>

                  <p style={{ marginTop: '25px' }}>Total</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'totalAsc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {

                        SetSortBy('totalAsc')
                        setSortOrder("totalAsc");
                        // setDashboardData([...dashboardData]?.sort((a, b) => b.total - a.total))
                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'totalDsc' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('totalDsc')
                        setSortOrder("totalDsc");
                        // setDashboardData([...dashboardData]?.sort((a, b) => a.total - b.total))
                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>

                  <p style={{ marginTop: '25px' }}>Screening</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'ScreeningAsc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('ScreeningAsc')
                        setSortOrder("ScreeningAsc");
                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'ScreeningDsc' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('ScreeningDsc')
                        setSortOrder("ScreeningDsc");
                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>

                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <p style={{ marginTop: '25px' }}>Identified</p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'Identifiedasc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('Identifiedasc')
                        setSortOrder("Identifiedasc");
                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'IdentifiedDsc' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('IdentifiedDsc')
                        setSortOrder("IdentifiedDsc");
                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>

                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <p style={{ marginTop: '25px' }}>Scheduled</p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'scheduledAsc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {

                        SetSortBy('scheduledAsc')
                        setSortOrder("scheduledAsc");
                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'scheduledDes' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('scheduledDes')
                        setSortOrder("scheduledDes");
                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>

                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <p style={{ marginTop: '25px' }}>Selected</p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'Selectedasc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {

                        SetSortBy('Selectedasc')
                        setSortOrder("Selectedasc");

                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'SelectedDes' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('SelectedDes')
                        setSortOrder("SelectedDes");

                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <p style={{ marginTop: '25px' }}>Offered</p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'Offeredasc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {

                        SetSortBy('Offeredasc')
                        setSortOrder("Offeredasc");

                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'OfferedDes' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('OfferedDes')
                        setSortOrder("OfferedDes");

                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  <p style={{ marginTop: '25px' }}>Joined</p>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ color: sortBy === 'Joinedasc' ? 'white' : '' }} hover title='descending' size='small'>
                      <ArrowDownwardIcon fontSize='small' onClick={(e) => {

                        SetSortBy('Joinedasc')
                        setSortOrder("Joinedasc");

                      }} />
                    </IconButton>

                    <IconButton style={{ color: sortBy === 'JoinedDes' ? 'white' : '' }} hover title='asecending' size="small" >
                      <ArrowUpwardIcon fontSize='small' onClick={(e) => {
                        SetSortBy('JoinedDes')
                        setSortOrder("JoinedDes");

                      }}

                      />
                    </IconButton>
                  </div>
                </TableCell>
                {
                  selectedColumns && selectedColumns?.map((column) => {
                    let cell = columnNames.filter((col) => col.value === column)[0];
                    return <TableCell style={{ position: 'sticky', top: 0, zIndex: 1 }}>{cell.label}</TableCell>

                  })
                }
              </TableRow>



              {hrSelectedColumns && hrSelectedColumns?.length === 0 ?


                <TableRow>
                  <TableCell style={{
                    position: 'sticky',
                    border: "1px solid #3b4864",
                    top: '60px', zIndex: 666,
                    background: '#539cde '

                  }}>Total Jobs : </TableCell>
                  <TableCell style={{
                    position: 'sticky',
                    border: "1px solid #3b4864",
                    top: '60px', zIndex: 666,
                    background: '#539cde '
                  }}>{dashboard?.data?.data?.length - 1}</TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  {/* <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '70px', zIndex: 999, background: '#539cde ' }}></TableCell> */}
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}></TableCell>
                  <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ' }}>Total</TableCell>
                  {dashboard?.data?.data?.map((el) => {
                    if (el?.totalCount) {

                      return (
                        <>
                          <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount.screeningCount > 0 ? 'pointer' : '' }}
                            onClick={(e) => { handleTotal(e, 'Screening', el.totalCount.screeningCount) }}
                          >{el.totalCount.screeningCount}</TableCell>
                          <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount.identifedCount > 0 ? 'pointer' : '' }}
                            onClick={(e) => { handleTotal(e, 'Identified', el.totalCount.identifedCount) }}
                          >{el.totalCount.identifedCount}</TableCell>
                          <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount.scheduledCount > 0 ? 'pointer' : '' }}
                            onClick={(e) => { handleTotal(e, 'Scheduled', el.totalCount.scheduledCount) }}
                          >{el.totalCount.scheduledCount}</TableCell>
                          <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount.selectedCount > 0 ? 'pointer' : '' }}
                            onClick={(e) => { handleTotal(e, 'Selected', el.totalCount.selectedCount) }}
                          >{el.totalCount.selectedCount}</TableCell>
                          <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount.offeredCount > 0 ? 'pointer' : '' }}
                            onClick={(e) => { handleTotal(e, 'Offered', el.totalCount.offeredCount) }}
                          >{el.totalCount.offeredCount}</TableCell>
                          <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount.joinedCount > 0 ? 'pointer' : '' }}
                            onClick={(e) => { handleTotal(e, 'Joined', el.totalCount.joinedCount) }}
                          >{el.totalCount.joinedCount}</TableCell>
                          {
                            selectedColumns && selectedColumns?.map((column) => {
                              let cell = columnNames.filter((col) => col.value === column)[0];
                              return <TableCell style={{ border: "1px solid #3b4864", position: 'sticky', top: '60px', zIndex: 666, background: '#539cde ', 'cursor': el.totalCount?.[cell.value] > 0 ? 'pointer' : '' }}
                                onClick={(e) => { handleTotal(e, cell.label, el.totalCount?.[cell.value]) }}
                              >{el.totalCount?.[cell.value]}</TableCell>
                            })
                          }

                        </>
                      )
                    }
                  })}
                </TableRow>
                :

                ''
              }
            </TableHead>
            <TableBody>
              {dashboard?.data?.data && dashboard?.data?.data?.map((data, i) => {
                if (jobSelectedColumns?.includes(data?.jobTitle)) {
                  return (
                    <TableRow
                      key={data?.jobCode}
                      hover
                      sx={{
                        fontSize: "0.5rem",
                        height: '1px'
                      }}>
                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: '#539cde ',
                          border: "1px solid #ADD8E6",
                          zIndex: 500,
                          borderTop: 0,
                          borderLeft: 0,
                          minWidth: 140,
                          maxWidth: 140
                        }}>
                        {data?.jobCode}</TableCell>

                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          left: 100,
                          zIndex: 500,
                          background: '#ADD8E6 ',
                          border: "1px solid #3b4864",
                          borderTop: 0,
                          borderLeft: 0,
                          minWidth: 100,
                          maxWidth: 100
                        }}>{data?.jobTitle}</TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>
                        {
                          costCenter?.data?.data?.map((el) => el.metaDataId === data?.costCenterId ? el?.displayText?.costCenter : '')
                        }
                      </TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>
                        {
                          department?.data?.data?.map((el) => el.metaDataId === data?.departmentId ? el?.displayText?.department : '')  // data?.divisionId
                        }
                      </TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>
                        {
                          division?.data?.data?.map((el) => el?.metaDataId === data?.divisionId ? el.displayText?.division : '')  // data?.departmentId
                        }
                      </TableCell>


                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>{data?.jobAge}</TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 200 }}>
                        {data.recruiters?.map((el) => el?.displayName).filter((e) => hiringManagerList.includes(e)).length <= 0 ? 'N/A' :
                          data.recruiters?.map((el) => el?.displayName).filter((e) => hiringManagerList.includes(e))}
                      </TableCell>


                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 300, maxWidth: 300 }}>
                        {data?.recruiter ? data?.recruiter : data?.recruiters?.map(

                          (r, i, a) => ((a.length - 0) !== i)
                            ?
                            r?.status === 'Active' && r?.roleAssignments?.map((el) => el?.role?.roleName === 'Recruiter'
                              ?
                              r?.displayName + ','
                              :
                              '')
                            :
                            ''
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>{data?.noOfPositions}</TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>{data?.jobStatus}</TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>{data?.total}</TableCell>

                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data.Screening?.count > 0 ? 'pointer' : '', 'color': data.Screening?.count > 0 ? 'blue' : '' }}
                        onClick={(e) => { handleClick(e, data, 'Screening', data?.Screening?.metaDataId) }}>
                        {data.Screening?.count ? data.Screening?.count : 0}</TableCell>
                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data.Identified?.count > 0 ? 'pointer' : '', 'color': data.Identified?.count > 0 ? 'blue' : '' }}
                        onClick={(e) => {
                          handleClick(e, data, 'Identified', data?.Identified?.metaDataId)
                        }}>{data.Identified?.count ? data.Identified?.count : 0}</TableCell>

                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data.Scheduled?.count > 0 ? 'pointer' : '', 'color': data.Scheduled?.count > 0 ? 'blue' : '' }}
                        onClick={(e) => { handleClick(e, data, 'Scheduled', data?.Scheduled?.metaDataId) }}
                      >{data.Scheduled?.count ? data.Scheduled?.count : 0}</TableCell>

                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data.Selected?.count > 0 ? 'pointer' : '', 'color': data.Selected?.count > 0 ? 'blue' : '' }}
                        onClick={(e) => { handleClick(e, data, 'Selected', data?.Selected?.metaDataId) }}
                      >{data.Selected?.count ? data.Selected?.count : 0}</TableCell>

                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data.Offered?.count > 0 ? 'pointer' : '', 'color': data.Offered?.count > 0 ? 'blue' : '' }}
                        onClick={(e) => { handleClick(e, data, 'Offered', data?.Offered?.metaDataId) }}
                      >{data.Offered?.count ? data.Offered?.count : 0}</TableCell>

                      <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data.Joined?.count > 0 ? 'pointer' : '', 'color': data.Joined?.count > 0 ? 'blue' : '' }}
                        onClick={(e) => { handleClick(e, data, 'Joined', data?.Joined?.metaDataId) }}
                      >{data.Joined?.count ? data.Joined?.count : 0}</TableCell>

                      {
                        selectedColumns && selectedColumns.map((col) => {
                          let column = columnNames.filter((el) => el.value === col)[0];
                          if (typeof data[column.label] !== 'object') {
                            return <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100 }}>
                              {data[column.label]?.count ? data[column.label]?.count : 0}
                            </TableCell>
                          } else {
                            return <TableCell style={{ border: "1px solid #3b4864", borderTop: 0, borderLeft: 0, minWidth: 100, maxWidth: 100, 'cursor': data[column.label]?.count > 0 ? 'pointer' : '', 'color': data[column.label]?.count > 0 ? 'blue' : '' }}
                              onClick={(e) => { handleClick(e, data, column.label, data?.[column.label]?.metaDataId) }

                              }
                            >{data[column.label]?.count ? data[column.label]?.count : 0}</TableCell>
                          }
                        })
                      }


                    </TableRow>
                  )

                }
              }

              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>


      {
        (tempCandidates.isFetching || tempCandidates.isLoading) ?
          <>
            return <SpinLoader />
          </>
          :
          <Grid container>

            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              maxWidth='xl'
              scroll={scroll}
            >

              <DialogTitle id="responsive-dialog-title">
                `Status: {jobDetails?.status} <br />
                {jobDetails?.jobCode} - {jobDetails?.jobTitle}`
              </DialogTitle>
              <DialogContent >
                <TableContainer sx={{ maxHeight: 440 }} dividers={scroll === 'paper'}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow sx={{
                        "& th": {
                          borderBottom: "2px solid black",
                          fontSize: "0.9rem",
                          color: "white",
                          backgroundColor: "#243c80",
                          borderLeft: "1px solid #3b4864",
                          height: '1px'
                        }
                      }}>
                        {/* {
                          statusFields.filter((el) => el.Status === jobDetails?.status)[0]?.Fields.map((field) => <StyledTableCell>{field.fieldName}</StyledTableCell>)
                        } */}
                        {
                          jobDetails?.status === 'All' ?
                            statusFields?.filter((el) => el.Status === 'Screening')[0]?.Fields.map((field) => <StyledTableCell>{field.fieldName}</StyledTableCell>)
                            :
                            statusFields.filter((el) => el.Status === jobDetails?.status)[0]?.Fields.map((field) => <StyledTableCell>{field.fieldName}</StyledTableCell>)

                        }

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tempCandidates?.data && tempCandidates?.data?.data?.map((data, i) => {
                        return <TableRow
                          key={data.candidateName}
                          hover
                        >
                          {jobDetails?.status === 'All' ?
                            statusFields?.filter((el) => el.Status === 'Screening')[0]?.Fields.map((field) => {
                              let value;
                              if (!data[field.fieldValue]) {
                                value = 'N/A';
                              }
                              else {
                                if (typeof data[field.fieldValue] === 'object') {

                                  if (data[field.fieldValue] instanceof Array && field?.fieldValue === 'interviews') {
                                    const interviewDetails = data[field.fieldValue]?.filter((interview) => {
                                      return interview?.interviewRound === field?.round;
                                    })[0];
                                     value = interviewDetails ? `Date:${moment(interviewDetails.interviewDate).format('DD-MM-YYYY')}, 
                                     Status:${interviewDetails.interviewStatus}` : 'N/A';
                                  }

                                  else if (field?.fieldValue === 'jobTitle') {
                                    value = data[field.fieldValue]?.jobTitle ? data[field.fieldValue]?.jobTitle : 'N/A';
                                  }

                                  else if (field?.fieldValue === 'candidateStatus') {
                                    value = data[field.fieldValue]?.displayText?.status ? data[field.fieldValue]?.displayText?.status : 'N/A';
                                  }

                                  else if (field?.fieldValue === 'jobLocation') {
                                    value = data[field.fieldValue]?.displayText?.location ? data[field.fieldValue]?.displayText?.location : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'backoutReason') {
                                    value = data[field.fieldValue]?.displayText?.backout ? data[field.fieldValue]?.displayText?.backout : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'disqualifiedReason') {
                                    value = data[field.fieldValue]?.displayText?.disqualified ? data[field.fieldValue]?.displayText?.disqualified : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'source') {
                                    if (data[field.fieldValue]?.displayText?.source === 'Other') {
                                      value = data['otherSource']
                                    } else {
                                      value = data[field.fieldValue]?.displayText?.source ? data[field.fieldValue]?.displayText?.source : 'N/A';
                                    }
                                  }
                                  else {
                                    value = data[field.fieldValue].displayText ? data[field.fieldValue].displayText : data[field.fieldValue].displayName;
                                  }
                                } else {
                                  value = data[field.fieldValue]
                                }
                              }
                              return <TableCell style={{ border: "1px solid" }}>{moment(value).isValid() && typeof value !== 'number' ? moment(value).utc().format('DD-MM-YYYY') : value}</TableCell>
                            })
                            :
                            statusFields?.filter((el) => el?.Status === jobDetails?.status)[0]?.Fields?.map((field) => {
                              let value;
                              if (!data[field.fieldValue]) {
                                value = 'N/A';
                              }
                              else {
                                if (typeof data[field.fieldValue] === 'object') {

                                  if (data[field.fieldValue] instanceof Array && field?.fieldValue === 'interviews') {
                                    // value = data[field.fieldValue]?.filter((interview) => {
                                    //   return interview?.interviewRound === field?.round
                                    // })[0]?.interviewDate;
                                    // value = value ? value : 'N/A'
                                    const interviewDetails = data[field.fieldValue]?.filter((interview) => {
                                      return interview?.interviewRound === field?.round;
                                    })[0];
                                     value = interviewDetails ? `Date:${moment(interviewDetails.interviewDate).format('DD-MM-YYYY')}, 
                                     Status:${interviewDetails.interviewStatus}` : 'N/A';
                                  }

                                  else if (field?.fieldValue === 'jobTitle') {
                                    value = data[field.fieldValue]?.jobTitle ? data[field.fieldValue]?.jobTitle : 'N/A';
                                  }

                                  else if (field?.fieldValue === 'candidateStatus') {
                                    value = data[field.fieldValue]?.displayText?.status ? data[field.fieldValue]?.displayText?.status : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'jobLocation') {
                                    value = data[field.fieldValue]?.displayText?.location ? data[field.fieldValue]?.displayText?.location : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'backoutReason') {
                                    value = data[field.fieldValue]?.displayText?.backout ? data[field.fieldValue]?.displayText?.backout : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'disqualifiedReason') {
                                    value = data[field.fieldValue]?.displayText?.disqualified ? data[field.fieldValue]?.displayText?.disqualified : 'N/A';
                                  }
                                  else if (field?.fieldValue === 'source') {
                                    // value = data[field.fieldValue]?.displayText?.source ? data[field.fieldValue]?.displayText?.source : 'N/A';
                                    if (data[field.fieldValue]?.displayText?.source === 'Other') {
                                      value = data['otherSource']
                                    } else {
                                      value = data[field.fieldValue]?.displayText?.source ? data[field.fieldValue]?.displayText?.source : 'N/A';
                                    }
                                  }
                                  else {
                                    value = data[field.fieldValue].displayText ? data[field.fieldValue].displayText : data[field.fieldValue].displayName;
                                  }
                                } else {
                                  value = data[field.fieldValue]
                                }
                              }
                              return <TableCell style={{ border: "1px solid" }}>{moment(value).isValid() && typeof value !== 'number' ? moment(value).utc().format('DD-MM-YYYY') : value}</TableCell>
                            })
                          }

                        </TableRow >
                      }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </DialogContent>
              <DialogActions>

                <Button onClick={handleClose} autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
      }
    </Box >

  )
}

export default Dashboard