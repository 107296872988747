
import React, { useState, useEffect, useRef, useContext } from 'react';

import { UserContext } from '../../components/Routes/Routes';



import { useJobs } from '../../helpers/hooks/jobsHooks';
import { useMetaData } from '../../helpers/hooks/metaDataHooks';
import { useCandidates } from '../../helpers/hooks/candidatesHooks';
import { useUsers } from '../../helpers/hooks/userHooks';
import ReactQuill from 'react-quill';

import SpinLoader from '../../components/SpinLoader/SpinLoader';
import DocumentUpload from '../../components/DocumentUpload/DocumentUpload';
import DeleteCandidates from '../../components/DeleteCandidate/DeleteCandidate';
//!--------------------MUI------------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


import Link from '@mui/material/Link'
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel, FormControl, MenuItem, InputLabel, Select, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';


const Candidate = () => {

  const user = useContext(UserContext);

  const location = useLocation();
  const ref = useRef(null);
  const candidateId = location.pathname.split('/')[2];
  const { sources, jobLocations, backoutReasons, gender, candidateStatuses, costCenter, department, division, devices, variablePayDuration, bonusPayDuration, shiftTimings, probationPeriod, disqualifiedReasons } = useMetaData();
  const { useCandidate, updateCandidate, deleteDocument, uploadDocuments } = useCandidates();
  const candidate = useCandidate(candidateId);
  const { useGetJobDetails } = useJobs();
  const jobs = useGetJobDetails();

  const [form, setForm] = useState({});
  // const [company, setCompany] = useState(null);
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const [showDocument, setshowDocument] = useState(false);
  // const [showHr, setShowHr] = useState(false);
  const [remark, setRemark] = useState(candidate?.data?.data?.remark);
  const { useHR, useHiringManagers, useTaManager } = useUsers()
  const HR = useHR();
  const hiringManagers = useHiringManagers();
  const TaManager = useTaManager();
  const [currentStatus, setCurrentStatus] = useState(candidate?.data?.data?.candidateStatusId);
  const [joiningDetails, setJoiningDetails] = useState(candidate?.data?.data?.joiningDetails);
  const [showOthers, setShowOthers] = useState(false)
  const [isFresher, setIsFresher] = useState(candidate?.data?.data?.candidateType);
  const [hike, setHikePercentage] = useState();
  const [disabledFields] = useState(window.localStorage.getItem('role') === 'HR' || window.localStorage.getItem('role') === 'HR Manager' ? true : false)
  const [joiningDatedisabledFields] = useState(window.localStorage.getItem('role') === 'TA Manager' || window.localStorage.getItem('role') === 'Admin' ? false : true)
  const [reportingManagerNames, setReportingmanagerNames] = useState("")
  const [showVariablePay, setShowVariablePay] = useState(candidate?.data?.data?.candidateType === false ? candidate?.data?.data?.variablePayOption === true ? false : true : true);
  const [showBonusPay, setShowBonusPay] = useState(candidate?.data?.data?.bonusPayOption === true ? false : true);
  const [salaryView, setSalaryView] = useState(disabledFields || (candidate?.data?.data?.candidateStatusId !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04') && (candidate?.data?.data?.candidateStatusId !== '7bfcc09d-7014-47bd-8bda-984081ddd991'));
  const [docVerificationCheckTA, setdocVerificationCheckTA] = useState(true);
  const [showLinkedIn, setShowLinkedIn] = useState(candidate?.data?.data?.linkedinLink ? true : false);
  let hiringManagerList = hiringManagers?.data?.data.map((el) => el?.displayName)
  let taManagerList = TaManager?.data?.data.map((el) => el?.displayName)
  const [isDeleteCandidate, setDeleteCandidate] = useState(false);
  const value = useContext(UserContext);
  const { useRecruiters } = useUsers(value.data.role);
  const recruiters = useRecruiters()
  const navigate = useNavigate();
  const [candidateVisitedoffice, setCandidateVisitedoffice] = useState(candidate?.data?.data?.candidateVisitedoffice);
  const [candidateExceptionalCase, setCandidateExceptionalCase] = useState(candidate?.data?.data?.candidateExceptionalCase);


  const handleChange = (e) => {

    if (e.target.name === 'DocverificationTAManager') {
      if (e.target.checked === true) {
        setdocVerificationCheckTA(false)
      } else if (e.target.checked === false) {
        setdocVerificationCheckTA(true)
      }
    }
    if (e.target.name === 'sourceId') {
      if (e.target.value === sources?.data?.data.filter((source) => source?.displayText?.source === 'Other')[0]?.metaDataId) {
        setShowOthers(true)
      } else {
        setShowOthers(false)
      }
    }
    if (e.target.name === 'reportingManagerName') {
      setReportingmanagerNames(e.target.value)
    }

    if (e.target.name === 'candidateStatusId') {
      setCurrentStatus(e.target.value)
    }

    if (e.target.name === 'candidateStatusId') {
      if (e.target.value === 'a5392787-669d-43cd-ba75-773c1a8ddc02') {

        setShowOnBoarding(true);
      }
    }
    if (e.target.name === 'bonusPayOption') {
      setShowBonusPay(e.target.value === 'false' ? true : false)
    }
    if (e.target.name === 'variablePayOption') {
      setShowVariablePay(e.target.value === 'false' ? true : false)
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })

  }
  const handleCandidateVisitedoffice = (e) => {
    console.log(e.target.value);
    setCandidateVisitedoffice(JSON.parse(e.target.value));
  };
  const handleExceptionalCase = (e) => {
    setCandidateExceptionalCase(JSON.parse(e.target.value));
  };
  const hiringManagerNames = candidate?.data?.data?.jobTitle?.jobAssignments?.map((el) => el?.user?.displayName).filter(
    (el) => !taManagerList?.includes(el)).filter((el) => hiringManagerList?.includes(el)).length === 0
    ?
    candidate?.data?.data?.jobTitle?.jobAssignments?.map((el) => el?.user?.displayName).filter(
      (el) => taManagerList?.includes(el))
    :
    candidate?.data?.data?.jobTitle?.jobAssignments?.map((el) => el?.user?.displayName).filter(
      (el) => !taManagerList?.includes(el)).filter((el) => hiringManagerList?.includes(el))

  const handlesubmit = (e) => {
    e.preventDefault();
    form.hiringManagerNames = hiringManagerNames
    form.candidateVisitedoffice = candidateVisitedoffice;
    form.candidateExceptionalCase = candidateExceptionalCase;
    form.joiningDetails = joiningDetails;


    form.remark = remark
    if (form?.candidateStatusId === (candidateStatuses?.data?.data.filter((status) => status.displayText.status === 'DocVerificationCompleted'))[0].metaDataId) {

      if (isFresher === true) {


        if (candidate?.data?.data?.documents.length < 4) {
          return alert('pLease upload all the documents');
        }
      } else {
        if (candidate?.data?.data?.documents.length < 7) {

          return alert('pLease upload all the documents');
        }
      }
    }

    const formData = new FormData();
    Object.keys(form).forEach(key => {
      formData.append(key, form[key]);
    });

    if (parseInt(form.expectedCTC) <= parseInt(form.currentCTC)) {
      alert('Current CTC should be less than Expected CTC');
      return;
    }

    if (e.target.files) {
      updateCandidate.mutate({ id: candidateId, formData: form },
        {
          onSuccess: (data) => {
            if (form.file) {
              // formData.append('candidateId', data.data.candidateId);
              // formData.append('documentName', 'resume');

              console.log(candidateId)
              console.log(e.target.files);
              if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('candidateId', candidateId);
                formData.append('documentName', e.target.name);
                uploadDocuments.mutate({ formData },
                  {
                    onSuccess: (data) => {
                      alert('success')
                      window.location.reload(false);
                    }
                  },
                  {
                    onError: (data) => {
                      alert('error')
                    }
                  }
                );
              }
            }
          }
        }
      );
    }
    else {
      updateCandidate.mutate({ id: candidateId, formData: form },
        {
          onSuccess: (data) => {
            alert('success')
            window.location.reload(false);
          }
        },
        {
          onError: (data) => {
            alert('error')
          }
        }
      )
    }
  }

  const handleUpload = (e) => {
    console.log(candidateId)
    console.log(e.target.files);
    if (e.target.files[0].size > 10 && (e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[0].type === '.docx' || e.target.files[0].type === 'application/pdf')) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('candidateId', candidateId);
      formData.append('documentName', e.target.name);

      uploadDocuments.mutate({ formData },
        {
          onSuccess: (data) => {
            // alert('success')
          }
        },
        {
          onError: (data) => {
            alert('error')
          }
        }
      );
    } else {
      alert('Document type should be PDf or Word only');
      e.target.value = ''
    }
  }

  const linkedProfileinLink = (e) => {
    e.preventDefault();
    if (candidate?.data?.data?.linkedinLink) {
      console.log(candidate?.data?.data?.linkedinLink)
      window.open('https://' + candidate?.data?.data?.linkedinLink, '_blank');

    } else {
      alert('Please provide Linkedin Profile link')
    }
  }

  const downloadDocument = (e) => {
    e.preventDefault();

    if (candidate?.data?.data?.documents) {
      let document = candidate?.data?.data?.documents.filter((doc) => doc.documentName === e.target.name)[0];

      if (document) {
        window.open(document.downloadLink, '_blank', 'noopener,noreferrer')
      }
    }
  }

  const handleDelete = (e) => {
    const formData = new FormData();

    formData.append('candidateId', candidate?.data?.data.candidateId);
    formData.append('documentName', e.target.name);
    formData.append('delete', true);

    deleteDocument.mutate({ formData },
      {
        onSuccess: (data) => {
          // alert('success')
        }
      },
      {
        onError: (data) => {
          alert('error')
        }
      }

    );



  }
  // let data = candidate?.data?.data?.candidateStatus?.displayText?.status === "DocVerificationCompleted"
  const handleClickOpen = () => {
    setDeleteCandidate(true);
  };
  const handleClose = () => {
    setDeleteCandidate(false);
  };
  let statuses, index, filteredStatuses;

  if (window.localStorage.getItem('role') === 'Recruiter') {

    // Screening
    if (candidate?.data?.data?.candidateStatusId === '630b6be8-07e8-4031-81e5-df1b997c5bcb') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 1 || el.order === 2 || el.order === 8 || el.order === 9 || el.order === 10 || el.order === 11 || el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Identified
    if (candidate?.data?.data?.candidateStatusId === 'c3db3358-9c57-47b5-9e53-967d0a151541') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 2 || el.order === 8 || el.order === 9 || el.order === 10 || el.order === 11 || el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Selected
    if (candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 4 || el.order === 5 || el.order === 10 || el.order === 11 || el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //DocVerificationCompleted
    if (candidate?.data?.data?.candidateStatusId === '95d729ef-0b79-45ea-8eb4-afbb0128ab04') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 5 || el.order === 10 || el.order === 11 || el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //CorrectDocumentNeeded
    if (candidate?.data?.data?.candidateStatusId === '79a602f2-2db7-421e-ac4c-3454e8063851') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 13 || el.order === 5 || el.order === 10 || el.order === 11 || el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Offered
    if (candidate?.data?.data?.candidateStatusId === '7bfcc09d-7014-47bd-8bda-984081ddd991') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 6 || el.order === 7 || el.order === 10 || el.order === 11 || el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Joined
    if (candidate?.data?.data?.candidateStatusId === 'a5392787-669d-43cd-ba75-773c1a8ddc02') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 7 || el.order === 10 || el.order === 11 || el.order === 12 )
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //BackOut
    else if (candidate?.data?.data?.candidateStatusId === 'c162cc5a-e812-43d3-967a-a6c2d913e5e5') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 10)
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //Separated
    else if (candidate?.data?.data?.candidateStatusId === '79a602f2-2db7-421e-ac4c-3454e8063852') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 14)
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //BackUp
    else if (candidate?.data?.data?.candidateStatusId === '2e99b215-fac5-4a17-a160-b7746b767ef4') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 11)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Hold
    else if (candidate?.data?.data?.candidateStatusId === '53786270-66a2-4044-9f7a-97dec411c538') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Disqualified
    else if (candidate?.data?.data?.candidateStatusId === 'fa30118a-7501-4532-a51f-17a3c15f5308') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 8)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Rejected
    else if (candidate?.data?.data?.candidateStatusId === 'b63d6ad3-233f-41c4-8ba2-fa8d0b63730b') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 9)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    filteredStatuses = statuses?.slice(index);
  } else if (window.localStorage.getItem('role') === 'HR' || window.localStorage.getItem('role') === 'HR Manager') {

    //Offered
    if (candidate?.data?.data?.candidateStatusId === '7bfcc09d-7014-47bd-8bda-984081ddd991') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 6)
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //Joined
    else if (candidate?.data?.data?.candidateStatusId === 'a5392787-669d-43cd-ba75-773c1a8ddc02') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 7 || el.order === 14)
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //BackOut
    else if (candidate?.data?.data?.candidateStatusId === 'c162cc5a-e812-43d3-967a-a6c2d913e5e5') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 10 )
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //BackUp
    else if (candidate?.data?.data?.candidateStatusId === '2e99b215-fac5-4a17-a160-b7746b767ef4') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 11 )
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //Hold
    else if (candidate?.data?.data?.candidateStatusId === '53786270-66a2-4044-9f7a-97dec411c538') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 12)
      index = statuses?.findIndex((status) => status.metaDataId)
    }
    //Disqualified
    else if (candidate?.data?.data?.candidateStatusId === 'fa30118a-7501-4532-a51f-17a3c15f5308') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 8)
      index = statuses?.findIndex((status) => status.metaDataId)
    }

    //Rejected
    else if (candidate?.data?.data?.candidateStatusId === 'b63d6ad3-233f-41c4-8ba2-fa8d0b63730b') {
      statuses = candidateStatuses?.data?.data.filter(el => el.order === 9)
      index = statuses?.findIndex((status) => status.metaDataId)
    }
       //Separated
       else if (candidate?.data?.data?.candidateStatusId === '79a602f2-2db7-421e-ac4c-3454e8063852') {
        statuses = candidateStatuses?.data?.data.filter(el => el.order === 14)
        index = statuses?.findIndex((status) => status.metaDataId)
      }
    else {
      statuses = candidateStatuses?.data?.data.filter(el => el.order !== 0 && el.order !== 1 && el.order !== 2 && el.order !== 3 && el.order !== 7 && el.order !== 8 && el.order !== 9 && el.order !== 10 && el.order !== 11  && el.order !== 14)
      index = statuses?.findIndex((status) => status.metaDataId === candidate?.data?.data.candidateStatusId);
    }
    filteredStatuses = statuses?.slice(index);

  } else {
    // For TA Manager and Admin
    filteredStatuses = candidateStatuses?.data?.data.filter(el => el.order !== 3)
  }


  useEffect(() => {

    if (candidate?.data?.data?.candidateStatusId === 'a5392787-669d-43cd-ba75-773c1a8ddc02') {
      setShowOnBoarding(true);
    }

    if (candidate?.data?.data.sourceId === 'other') {
      setShowOthers(true);
    }

    setIsFresher(candidate?.data?.data?.candidateType);

    if (candidate?.data?.data?.offeredSalary) {
      let a = candidate?.data?.data.offeredSalary - candidate?.data?.data?.currentCTC;
      let b = a / candidate?.data?.data?.currentCTC
      let hike = Math.trunc(Number(b * 100));
      setHikePercentage(hike)
    }

    if (candidate?.data?.data?.candidateStatusId === '95d729ef-0b79-45ea-8eb4-afbb0128ab04' || candidate?.data?.data?.candidateStatusId === '7bfcc09d-7014-47bd-8bda-984081ddd991') {
      setSalaryView((disabledFields || (candidate?.data?.data?.candidateStatusId !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04') && (candidate?.data?.data?.candidateStatusId !== '7bfcc09d-7014-47bd-8bda-984081ddd991')));
    }

  }, [candidateVisitedoffice, candidate?.data?.data, currentStatus, candidate?.data?.data?.candidateStatusId, filteredStatuses,
    candidate?.data?.data?.joiningDetails, candidate?.data?.data?.candidateType, candidate?.data?.data?.linkedinLink, showOthers, showVariablePay, showBonusPay,
    salaryView, showLinkedIn
  ])


  if (sources.isLoading || jobLocations.isLoading || backoutReasons.isLoading || updateCandidate.isLoading || candidate.isLoading) {
    return <SpinLoader />
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let salaryVisibility;
  user?.data?.roleAssignments.filter((el) => {
    if (el.role.roleName === 'SalaryAccess') {
      salaryVisibility = true
    }
  }
  )

  return (
    <>
      {uploadDocuments.isLoading || deleteDocument.isLoading &&
        <>
          <SpinLoader />
        </>
      }
      {showDocument &&
        <>
          <DocumentUpload candidate={candidate?.data?.data} showDocument={showDocument}
            setshowDocument={setshowDocument}
          // statuses={statuses}
          />
        </>
      }
      <div style={{ height: 'auto', margin: '50px', marginTop: '10px' }}>
        {isDeleteCandidate &&
          <DeleteCandidates setDeleteCandidate={setDeleteCandidate} isDeleteCandidate={isDeleteCandidate} candidate={candidate} />

        }
      </div>
      {!candidate.isLoading &&

        <form onSubmit={(e) => handlesubmit(e)}>
          <Box m={8} mt={20} ml={10} sx={{ boxShadow: 1, backgroundColor: 'white' }} >

            <h2 style={{
              marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
              color: "white", fontWeight: 500, fontSize: "18px",
            }} > Personal Details </h2>
            <hr />
            <Grid container m={5} gap={5}>
              <Grid Item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Candidate Name</h3>
                  <TextField
                    labelId="input-candidate-name-label"
                    id="outlined-required"
                    label="Candidate Name"
                    placeholder='Enter Candidate Name'
                    size='small'
                    margin='normal'
                    name='candidateName'
                    variant="filled"
                    required
                    disablePortal
                    autoComplete="off"
                    disabled={disabledFields}
                    defaultValue={candidate?.data?.data?.candidateName ? candidate?.data?.data?.candidateName : ''}

                    onChange={(e) => handleChange(e)} />
                </FormControl>
              </Grid>
              <Grid Item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Candidate Email</h3>
                  <TextField

                    labelId="input-candidate-email-label"
                    id="outlined-required"
                    label="Candidate Email"
                    placeholder='Enter Candidate Email'
                    size='small'
                    margin='normal'
                    name='candidateEmail'
                    required
                    defaultValue={candidate?.data?.data.candidateEmail}
                    variant="filled"

                    disabled={disabledFields}

                    onChange={(e) => handleChange(e)} />
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">

                  <h3>Candidate Phone Number</h3>
                  <TextField
                    required
                    labelId="input-candidate-phone-number"
                    id="outlined-required"
                    label="Candidate Phone Number"
                    placeholder='Enter Candidate Phone Number'
                    size='small'
                    margin='normal'
                    type='number'
                    name='candidatePhone'

                    disabled={disabledFields}

                    defaultValue={candidate?.data?.data.candidatePhone}
                    variant="filled"

                    onChange={(e) => handleChange(e)} />
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <h3>Gender</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <InputLabel id="select-gender-label">Gender</InputLabel>
                  <Select
                    labelId="select-gender-label"
                    id="demo-simple-select"
                    label="Gender"
                    size='small'
                    margin='normal'
                    name='gender'
                    variant="filled"
                    required

                    disabled={disabledFields}
                    defaultValue={candidate?.data?.data?.gender}
                    onChange={(e) => handleChange(e)}
                  >

                    {gender?.data && gender?.data?.data.map((gen) =>
                      <MenuItem key={gen.metaDataId} value={gen.metaDataId}>{gen.displayText.gender}</MenuItem>
                    )}
                  </Select>
                </FormControl>


              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                  <h3>Is Candidate Fresher?</h3>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="input-radio-buttons-group-fresher-label"
                    name="candidateType"
                    variant="filled"
                    defaultValue={candidate?.data?.data?.candidateType}
                    onChange={(e) => handleChange(e)}
                  >

                    <FormControlLabel value="true" disabled={disabledFields} control={<Radio required={true} />} label="Yes" />
                    <FormControlLabel value="false" disabled={disabledFields} control={<Radio required={true} />} label="No" />

                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container m={5} gap={5}>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 600 }} size="small">

                  <h3>Candidate Address</h3>
                  <TextField
                    required
                    autocomplete="off"
                    name='candidateAddress'
                    labelId="input-candidate-address"
                    id="outlined-required"
                    label="Candidate Address"
                    placeholder='Enter Candidate Address'
                    size='large'
                    margin='normal'
                    defaultValue={candidate?.data?.data.candidateAddress}
                    variant="filled"
                    disabled={disabledFields}
                    onChange={(e) => handleChange(e)} />
                </FormControl>
              </Grid>

              {showLinkedIn === false ?
                <>
                  <Grid item xs='auto'>
                    <FormControl variant="standard" sx={{ width: 300 }} size="small">
                      <h3>Candidate Linkedin Profile Link</h3>
                      <TextField

                        labelId="input-candidate-Linkedin-label"
                        id="outlined-required"
                        label="Candidate Linkedin Profile Link"
                        placeholder='Enter Linkedin Profile Link'
                        size='small'
                        margin='normal'
                        name='linkedinLink'
                        variant="filled"
                        defaultValue={candidate?.data?.data.linkedinLink}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </Grid>
                </>
                :
                <Grid item xs='auto'>
                  <FormControl variant="standard" sx={{ width: 300 }} size="small">
                    <Link
                      style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "40px" }}
                      variant='contained'
                      name='Candidate Linkedin Profile Link'
                      onClick={(e) => linkedProfileinLink(e)} >
                      Candidate Linkedin Profile Link
                    </Link>
                  </FormControl>
                  <FormControl variant="standard" sx={{ top: 25 }}>
                    <IconButton>
                      <EditIcon onClick={() => showLinkedIn === true ? setShowLinkedIn(false) : setShowLinkedIn(true)} />
                    </IconButton>
                  </FormControl>
                </Grid>
              }

              {/* <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 30 }} size="small">
                  <IconButton>
                    {showLinkedIn === false &&
                      <>
                        <EditIcon onClick={() => showLinkedIn === true ? setShowLinkedIn(false) : setShowLinkedIn(true)} />
                      </>
                    }

                  </IconButton>
                </FormControl>
              </Grid> */}



              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 300 }} size="small">
                  <h3>Distance from Office</h3>
                  <TextField

                    labelId="input-candidate-Linkedin-label"
                    id="outlined-required"
                    placeholder='Distance from Office in Kms'
                    size='small'
                    margin='normal'
                    name='distanceFromOffice'
                    variant="filled"
                    defaultValue={candidate?.data?.data.distanceFromOffice}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>

            </Grid>
            <h2 style={{
              marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
              color: "white", fontWeight: 500, fontSize: "18px",
            }}  >Professional Details</h2>
            <hr />

            <Grid container m={5} gap={5}>


              {salaryVisibility === true &&
                <>

                  <Grid item xs='auto'>
                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                      <h3>Current CTC</h3>
                      <TextField
                        required
                        labelId="input-candidate-current_ctc-label"
                        id="outlined-required"
                        label="Current CTC"
                        placeholder='Enter Current CTC'
                        InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }}
                        size='small'
                        margin='normal'
                        type='number'
                        name='currentCTC'
                        variant="filled"
                        defaultValue={candidate?.data?.data.currentCTC}
                        onChange={(e) => handleChange(e)}
                        disabled={disabledFields}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs='auto'>
                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                      <h3>Expected CTC</h3>
                      <TextField
                        required
                        labelId="input-candidate-current_ctc-label"
                        id="outlined-required"
                        label="Expected CTC"
                        placeholder='Enter Expected CTC'
                        InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }}
                        size='small'
                        margin='normal'
                        type='number'
                        name='expectedCTC'
                        variant="filled"
                        defaultValue={candidate?.data?.data.expectedCTC}
                        onChange={(e) => handleChange(e)}
                        disabled={disabledFields}
                      />
                    </FormControl>
                  </Grid>

                </>
              }

              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Total Experience</h3>
                  <TextField
                    required
                    labelId="input-total-experience-label"
                    id="outlined-required"
                    label="Total Experience"
                    placeholder='Enter Total Experience'
                    size='small'
                    margin='normal'
                    name='totalExperience'
                    defaultValue={candidate?.data?.data.totalExperience}
                    variant="filled"
                    onChange={(e) => handleChange(e)}

                    disabled={disabledFields}

                  />

                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Relevant Experience</h3>
                  <TextField
                    required
                    labelId="input-total-experience-label"
                    id="outlined-required"
                    label="Relevant Experience"
                    placeholder='Enter Total Experience'
                    size='small'
                    margin='normal'
                    name='relevantExperience'
                    defaultValue={candidate?.data?.data.relevantExperience}
                    variant="filled"
                    onChange={(e) => handleChange(e)}

                    disabled={disabledFields}

                  />
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <h3>Candidate LWD</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">

                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    inputFormat="DD/MM/YYYY"
                    type="date"
                    id="candidateLastWorkingDate"
                    name="candidateLastWorkingDate"
                    defaultValue={
                      candidate?.data?.data.candidateLastWorkingDate
                        ? new Date(candidate?.data?.data.candidateLastWorkingDate).toISOString().split('T')[0]
                        : ''

                    } onChange={(e) => handleChange(e)}
                    disabled={disabledFields} />

                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Notice period in Days</h3>
                  <TextField
                    required
                    labelId="input-notice-days-label"
                    id="outlined-required"
                    label="Notice period in Days"
                    placeholder='Enter Notice period in days'
                    size='small'
                    margin='normal'
                    type='number'
                    name='noticePeriodInDays'
                    defaultValue={candidate?.data?.data?.noticePeriodInDays}
                    variant="filled"
                    // defaultValue={candidate?.data?.data.notice_period_in_days}
                    onChange={(e) => handleChange(e)}

                    disabled={disabledFields}

                  />
                </FormControl>
              </Grid>

            </Grid>

            <h2 style={{
              marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
              color: "white", fontWeight: 500, fontSize: "18px",
            }}> Interview schedule Details</h2>
            <hr />
            <Grid container m={5} gap={5}>

              <Grid item xs='auto'>
                <h3>Job Title</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <Select
                    labelId="select-job-title-label"
                    id="select-job-label"
                    variant="filled"
                    margin='normal'
                    name='jobId'
                    required
                    defaultValue={candidate?.data?.data.jobId}
                    MenuProps={MenuProps}
                    onChange={(e) => handleChange(e)}
                    disabled={disabledFields === true || window.localStorage.getItem('role') === 'Recruiter' ? true : false}
                  >
                    {jobs?.data && jobs?.data?.data.map((job) => {
                      return <MenuItem key={job.jobId} value={job.jobId}>{job.jobTitle}</MenuItem>
                    })}
                  </Select>
                </FormControl>


              </Grid>

              <Grid item xs='auto'>
                <h3>Job Code</h3>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <TextField
                    labelId="input-candidate-name-label"
                    id="outlined-required"
                    label="Job Code"

                    placeholder='Enter Job Code'
                    size='small'
                    margin='normal'
                    name='jobCode'
                    variant="filled"
                    value={candidate?.data?.data?.jobTitle?.jobCode}
                    disabled={true}
                    onChange={(e) => handleChange(e)}
                  // required
                  />
                </FormControl>
              </Grid>


              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Company Name</h3>
                  <TextField
                    required
                    labelId="input-candidate-name-label"
                    id="outlined-required"
                    placeholder='Enter Company Name'

                    size='medium'
                    variant="outlined"
                    margin='normal'
                    name='company'
                    value={candidate?.data?.data?.company}

                    onChange={(e) => handleChange(e)}
                    disabled={true}
                  />
                </FormControl>
              </Grid>



              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <InputLabel id="demo-simple-select-label">Cost Center</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="costCenter"
                    name="costCenter"
                    sx={{ width: 250, height: 50 }}
                    style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                    disabled={true}
                    defaultValue={candidate?.data?.data?.jobTitle?.costCenterId}
                  >

                    {costCenter?.data && costCenter.data.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText.costCenter}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <InputLabel id="demo-simple-select-label">Department</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="department"
                    label="department"
                    name="department"
                    sx={{ width: 250, height: 50 }}
                    style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                    disabled={true}
                    defaultValue={candidate?.data?.data?.jobTitle?.departmentId}
                  >
                    {department?.data && department.data.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText.department}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                  <InputLabel id="demo-simple-select-label">division</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="division"
                    label="division"
                    name="division"
                    sx={{ width: 250, height: 50 }}
                    style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                    disabled={true}
                    defaultValue={candidate?.data?.data?.jobTitle?.divisionId}
                  >
                    {division?.data && division.data.data.map(cc =>
                      <MenuItem key={cc.metaDataId} value={cc.metaDataId}>{cc.displayText.division}</MenuItem>
                    )}
                  </Select>

                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <h3>Designation</h3>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <TextField
                    required
                    id="outlined-required"
                    label="Designation"
                    placeholder='Enter Designation'
                    size='medium'
                    variant="outlined"
                    margin='normal'
                    name='designation'

                    defaultValue={candidate?.data?.data?.designation}
                    onChange={(e) => handleChange(e)} />
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} mt={2} size="small">

                  <h3>Job Location</h3>
                  <RadioGroup
                    row
                    aria-labelledby="input-radio-buttons-group-job-location-label"
                    name="jobLocationId"
                    variant="filled"
                    defaultValue={candidate?.data?.data?.jobLocationId}
                    onChange={(e) => handleChange(e)}
                  >
                    {jobLocations?.data && jobLocations.data.data.map((location) =>
                      <>

                        <FormControlLabel disabled={disabledFields} value={location.metaDataId} control={<Radio required={true} />} label={location.displayText.location} />

                      </>
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs='auto'>

                <FormControl
                  variant="standard"
                  sx={{ width: 250 }}
                  mt={2}
                  size="small">
                  <h3>Source</h3>


                  <RadioGroup
                    row
                    aria-labelledby="input-radio-buttons-group-source-label"
                    name="sourceId"
                    defaultValue={candidate?.data?.data?.sourceId}
                    onChange={(e) => handleChange(e)}
                  >
                    {sources?.data && sources.data.data.map((source) =>
                      <>

                        <FormControlLabel value={source.metaDataId} control={<Radio />} label={source.displayText.source} disabled={disabledFields} />
                      </>
                    )}
                    {/* 
                    <>
                      <FormControlLabel value='other' control={<Radio checked={candidate.data.data.otherSource ? true : false} type="radio" onClick={(e) => setShowOthers(true)} />} label='Other' />
                    </> */}

                  </RadioGroup>
                  {
                    (showOthers === true || candidate?.data?.data.otherSource) &&

                    <>
                      <TextField
                        id='otherSource'
                        name='otherSource'
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Other Source"

                        disabled

                        defaultValue={candidate?.data?.data.otherSource}
                        required
                      />
                    </>}
                </FormControl>


                <FormControl variant="standard" sx={{ width: 250,marginLeft:5 }} size="small">
                  <h3 style={{marginBottom: '20px' }}>Shift Timingss</h3>
                  {/* <InputLabel id="demo-simple-select-label">shift_Timings</InputLabel> */}
                  <Select
                    labelId="shift_Timings"
                    // label="shift_Timings"
                    id="shiftTimings"
                    variant="filled"
                    margin='normal'
                    name='shiftTimings'
                    required
                    defaultValue={candidate?.data?.data?.shiftTimings}
                    disabled={currentStatus !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04'}
                    MenuProps={MenuProps}
                    onChange={(e) => handleChange(e)}
                  >
                    {shiftTimings?.data && shiftTimings?.data?.data.map((shiftTime) => {
                      return <MenuItem key={shiftTime.metaDataId} value={shiftTime.metaDataId}>{shiftTime.displayText.shiftTime}</MenuItem>
                    })}

                  </Select>

                </FormControl>

              </Grid>



            </Grid>
            <Grid container m={5} gap={5}>
              <Grid item xs='auto'>
                <h3>Calling Date</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">

                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    type="date"
                    id="candidateCallingDate"
                    name="candidateCallingDate"
                    defaultValue={
                      candidate?.data?.data.candidateCallingDate
                        ? new Date(candidate?.data?.data.candidateCallingDate).toISOString().split('T')[0]
                        : ''

                    } onChange={(e) => handleChange(e)}
                    disabled={disabledFields}
                  />


                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <h3>Identified date</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">

                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    type="date"
                    id="identifiedDate"
                    name="identifiedDate"
                    required
                    defaultValue={
                      candidate?.data?.data.identifiedDate
                        ? new Date(candidate?.data?.data.identifiedDate).toISOString().split('T')[0]
                        : ''

                    } onChange={(e) => handleChange(e)}
                    disabled={disabledFields}
                  />


                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <h3>Selected or Rejected Date</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">

                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    type="date"
                    id="selectedRejectedDate"
                    name="selectedRejectedDate"
                    // defaultValue={candidate?.data?.data.selectedRejectedDate}
                    readOnly
                    defaultValue={
                      candidate?.data?.data.selectedRejectedDate
                        ? new Date(candidate?.data?.data.selectedRejectedDate).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={(e) => handleChange(e)} />


                </FormControl>

              </Grid>
              <Grid item xs='auto'>
                <h3>Mandatory Docs Verified Date</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">

                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    type="date"
                    id="mandatoryDocsVerifiedDate"
                    name="mandatoryDocsVerifiedDate"
                    readOnly
                    defaultValue={
                      candidate?.data?.data.mandatoryDocsVerifiedDate
                        ? new Date(candidate?.data?.data.mandatoryDocsVerifiedDate).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={(e) => handleChange(e)} />


                </FormControl>

              </Grid>
              <Grid item xs='auto'>
                <h3>Offer Date</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">

                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    type="date"
                    id="offerDate"
                    name="offerDate"
                    // defaultValue={candidate?.data?.data.offerDate}
                    readOnly
                    defaultValue={
                      candidate?.data?.data.offerDate
                        ? new Date(candidate?.data?.data.offerDate).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={(e) => handleChange(e)} />


                </FormControl>

              </Grid>

              <Grid item xs='auto'>
                <h3>Tentative Date of Joining</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}


                    type="date"
                    id="tentativeDateOfJoining"
                    name="tentativeDateOfJoining"
                    defaultValue={
                      candidate?.data?.data.tentativeDateOfJoining
                        ? new Date(candidate?.data?.data.tentativeDateOfJoining).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                    disabled={disabledFields}
                  />

                </FormControl>

              </Grid>

              <Grid item xs='auto'>
                <h3>Joining Date</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                  <input
                    style={{ width: 250, height: 50, fontSize: 15 }}
                    type="date"
                    id="joiningDate"
                    name="joiningDate"
                    defaultValue={
                      candidate?.data?.data.joiningDate
                        ? new Date(candidate?.data?.data.joiningDate).toISOString().split('T')[0]
                        : ''
                    }
                    // readOnly
                    disabled={joiningDatedisabledFields || (candidate?.data?.data?.candidateStatusId !== 'a5392787-669d-43cd-ba75-773c1a8ddc02') && (candidate?.data?.data?.candidateStatusId !== '7bfcc09d-7014-47bd-8bda-984081ddd991')}
                    onChange={(e) => handleChange(e)} />


                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, mt: 2 }} size="small">
                  <h3>Candidate Visited office</h3>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="input-radio-buttons-group-fresher-label"
                    name="candidateVisitedoffice"
                    variant="filled"
                    defaultValue={candidate?.data?.data?.candidateVisitedoffice}
                    onChange={(e) => handleCandidateVisitedoffice(e)}

                  >
                    <FormControlLabel value="true" control={<Radio required={true} disabled={candidate?.data?.data?.candidateStatusId !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04'} />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio required={false} disabled={candidate?.data?.data?.candidateStatusId !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04'} />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250 }} size="small">
                  <h3>Whom Did The Candidate Meet In The Office</h3>
                  <TextField
                    required
                    labelId="Candidate-in-Office"
                    id="outlined-required"
                    label="Person name"
                    placeholder='Enter person name'
                    name='candidateMetPersonName'
                    size='small'
                    margin='normal'
                    defaultValue={candidate?.data?.data?.candidateMetPersonName}
                    disabled={!candidateVisitedoffice}
                    variant="filled"
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, mt: 2 }} size="small">
                  <h3>Exceptional Case</h3>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="input-radio-buttons-group-fresher-label"
                    name="candidateExceptionalCase"
                    variant="filled"
                    defaultValue={candidate?.data?.data?.candidateExceptionalCase}
                    onChange={(e) => handleExceptionalCase(e)}
                  >
                    <FormControlLabel value="true" control={<Radio required={true} disabled={candidate?.data?.data?.candidateStatusId != '95d729ef-0b79-45ea-8eb4-afbb0128ab04'} />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio required={false} disabled={candidate?.data?.data?.candidateStatusId != '95d729ef-0b79-45ea-8eb4-afbb0128ab04'} />} label="No" />

                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 550 }} size="small">

                  <h3>Exceptional Case Reason</h3>
                  <TextField
                    required
                    labelId="input-candidate-Exceptional-Case-Reason"
                    name='exceptionalCaseReason'
                    id="outlined-required"
                    label="Exceptional Case Reason"
                    placeholder='Enter Exceptional Case Reason'
                    size='small'
                    margin='normal'
                    defaultValue={candidate?.data?.data?.exceptionalCaseReason}
                    disabled={!candidateExceptionalCase}
                    variant="filled"
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {salaryVisibility === true &&
              <>

                <Grid container m={5} gap={5}>

                  <Grid item xs='auto'>
                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                      {
                        candidate?.data?.data?.variablePayOption === null ? <h3>Offered Salary</h3> : candidate?.data?.data?.variablePayOption === false ? <h3>Offered Salary</h3> : candidate?.data?.data?.variablePayOption === true ? <h3>Offered Salary <span style={{ color: 'red' }}><br />(Includes VariablePay*)</span></h3> : '-'
                      }
                      <TextField
                        required
                        id="outlined-required"
                        label="Offered Salary"
                        placeholder='Enter Offered Salary'
                        InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }}
                        size='medium'
                        variant="outlined"
                        margin='normal'
                        type='number'
                        name='offeredSalary'
                        disabled={currentStatus !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04' || disabledFields}
                        // disabled={true}
                        defaultValue={candidate?.data?.data.offeredSalary}
                        onChange={(e) => handleChange(e)} />
                    </FormControl>
                  </Grid>


                  <Grid item xs='auto'>
                    <FormControl variant="standard" sx={{ width: 250 }} size="small">
                      <h3>Percentage Hike</h3>
                      <TextField

                        id="outlined-required"
                        InputProps={{ inputProps: { min: "100000", max: Infinity, step: "1" } }}
                        size='medium'
                        variant="outlined"
                        margin='normal'
                        type='number'
                        name='hikePercentage'
                        disabled={true}
                        value={hike}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs='auto' >
                    <h3>Probation Period</h3>
                    <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                      <Select
                        labelId="select-job-title-label"
                        id="select-job-label"
                        variant="filled"
                        margin='normal'
                        name='probationPeriodId'
                        required
                        defaultValue={candidate?.data?.data?.probationPeriodId}

                        MenuProps={MenuProps}
                        onChange={(e) => handleChange(e)}
                        disabled={currentStatus !== '95d729ef-0b79-45ea-8eb4-afbb0128ab04' || disabledFields}
                      >

                        {probationPeriod?.data && probationPeriod?.data?.data.map((probation) => {

                          // if (job?.status === 'Active') {
                          return <MenuItem key={probation.metaDataId} value={probation.metaDataId}>{probation.displayText.probation}</MenuItem>
                          // }
                        })}
                      </Select>
                    </FormControl>
                  </Grid>


                </Grid>


                {/US\sIT/ig.test(candidate.data.data.jobTitle.jobTitle) === true && isFresher === true ?

                  <>
                  </>
                  :

                  <>

                    <Grid container m={5} gap={5}>
                      <Grid Item xs='auto'>
                        <FormControl variant="standard" sx={{ width: 250 }} size="small">
                          <h3>Variable Pay</h3>
                          <RadioGroup
                            row
                            required
                            aria-labelledby="input-radio-buttons-group-fresher-label"
                            name="variablePayOption"
                            variant="filled"
                            defaultValue={candidate?.data?.data?.variablePayOption}
                            onChange={(e) => handleChange(e)}
                          >


                            <FormControlLabel value="true" control={<Radio required={true} disabled={salaryView} />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio required={true} disabled={salaryView} />} label="No" />

                          </RadioGroup>
                        </FormControl>

                      </Grid>


                      <Grid Item xs='auto'>
                        <FormControl variant="standard" sx={{ width: 250 }} size="small">
                          <TextField
                            labelId="input-candidate-name-label"
                            id="outlined-required"
                            label="Candidate Variable Pay"
                            placeholder='Enter Candidate Variable Pay'
                            size='small'
                            margin='normal'
                            name='variablePay'
                            variant="filled"
                            required
                            type='float'
                            disabled={disabledFields === true ? true : showVariablePay === true ? true : false}
                            defaultValue={candidate?.data?.data?.variablePay ? candidate?.data?.data?.variablePay : ''}
                            onChange={(e) => handleChange(e)} />
                        </FormControl>
                      </Grid>



                      <Grid item xs='auto'>
                        <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                          <InputLabel id="select-status-label">Select Variable Pay</InputLabel>
                          <Select
                            size='small'
                            margin='normal'
                            required
                            name='variablePayDurationId'
                            variant="filled"
                            disabled={disabledFields === true ? true : showVariablePay === true ? true : false}
                            defaultValue={candidate?.data?.data?.variablePayDurationId}
                            onChange={(e) => handleChange(e)}
                          >
                            {variablePayDuration && variablePayDuration?.data?.data.map((status) =>
                              <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.variablePay}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid Item xs='auto'>
                        <FormControl variant="standard" sx={{ width: 250 }} size="small">
                          <h3>Bonus Pay</h3>
                          <RadioGroup
                            row
                            required
                            aria-labelledby="input-radio-buttons-group-fresher-label"
                            name="bonusPayOption"
                            variant="filled"
                            defaultValue={candidate?.data?.data?.bonusPayOption}
                            onChange={(e) => handleChange(e)}
                          >

                            <FormControlLabel value="true" control={<Radio required={true} disabled={salaryView} />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio required={true} disabled={salaryView} />} label="No" />

                          </RadioGroup>

                        </FormControl>
                      </Grid>

                      <Grid>
                        <FormControl variant="standard" sx={{ width: 250 }} size="small">
                          <TextField
                            labelId="input-candidate-name-label"
                            id="outlined-required"
                            label="Candidate Bonus Pay"
                            placeholder='Enter Candidate Bonus Pay'
                            size='small'
                            margin='normal'
                            name='bonusPay'
                            variant="filled"
                            type='float'
                            required
                            disabled={disabledFields === true ? true : showBonusPay === true ? true : false}
                            defaultValue={candidate?.data?.data?.bonusPay ? candidate?.data?.data?.bonusPay : ''}

                            onChange={(e) => handleChange(e)} />
                        </FormControl>
                      </Grid>


                      <Grid item xs='auto'>
                        <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                          <InputLabel id="select-status-label">Select Bonus Pay</InputLabel>
                          <Select
                            size='small'
                            margin='normal'
                            required
                            name='bonusPayDurationId'
                            variant="filled"
                            disabled={disabledFields === true ? true : showBonusPay === true ? true : false}
                            defaultValue={candidate?.data?.data?.bonusPayDurationId}
                            onChange={(e) => handleChange(e)}
                          >
                            {bonusPayDuration && bonusPayDuration?.data?.data.map((status) =>
                              <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.bonusPay}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                    </Grid>
                    <Grid container m={5} gap={5}>


                    </Grid>

                  </>

                }

              </>}


            <Grid container m={5} gap={5}>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 5 }} size="small">
                  <InputLabel id="select-status-label">Status</InputLabel>
                  <Select
                    ref={ref}
                    id="candidateStatusId"
                    size='small'
                    margin='normal'
                    required
                    name='candidateStatusId'
                    variant="filled"
                    defaultValue={candidate?.data?.data?.candidateStatusId}
                    onChange={(e) => handleChange(e)}
                  >
                    {filteredStatuses && filteredStatuses.map((status) =>
                      <MenuItem key={status.metaDataId} value={status.metaDataId}>{status.displayText.status}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <h3>BackOut / Other Reason</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 3, }} size="small">
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="backout_reason_id"
                    size='small'
                    margin='normal'
                    name='backoutReasonId'
                    defaultValue={candidate?.data?.data.backoutReasonId}
                    variant="filled"
                    onChange={(e) => handleChange(e)}
                    disabled={currentStatus !== 'c162cc5a-e812-43d3-967a-a6c2d913e5e5'}
                  >
                    {backoutReasons?.data && backoutReasons.data.data.map((backoutReason) =>
                      <MenuItem key={backoutReason.metaDataId} value={backoutReason.metaDataId}>{backoutReason.displayText.backout}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <h3>Disqualified Reason</h3>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 3, }} size="small">
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="disqualifiedReasonId"
                    size='small'
                    margin='normal'
                    name='disqualifiedReasonId'
                    defaultValue={candidate?.data?.data?.disqualifiedReasonId}
                    variant="filled"
                    onChange={(e) => handleChange(e)}
                    disabled={currentStatus !== 'fa30118a-7501-4532-a51f-17a3c15f5308'}
                  >
                    {disqualifiedReasons?.data && disqualifiedReasons?.data?.data?.map((disqualified) =>
                      <MenuItem key={disqualified?.metaDataId} value={disqualified?.metaDataId}>{disqualified?.displayText?.disqualified}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 600 }} size="small">
                  <h3>Rejected Reason</h3>
                  <TextField
                    required
                    autocomplete="off"
                    name='rejectedReason'
                    labelId="input-candidate-address"
                    id="outlined-required"
                    label="Rejected Reason"
                    placeholder='Enter Rejected Reason'
                    size='large'
                    margin='normal'
                    defaultValue={candidate?.data?.data?.rejectedReason}
                    variant="filled"
                    disabled={currentStatus !== 'b63d6ad3-233f-41c4-8ba2-fa8d0b63730b'}
                    onChange={(e) => handleChange(e)} />
                </FormControl>
              </Grid>
              {(candidate?.data?.data?.offerLetter !== true && currentStatus === '7bfcc09d-7014-47bd-8bda-984081ddd991') && window.localStorage.getItem('role') == 'HR' &&

                <>
                  <Grid>
                    <FormControl variant="standard" sx={{ width: 250, marginTop: 7 }} size="small">
                      <Button variant='contained' name='generateOfferLetter' onClick={(e) => { navigate(`/salaryView/${candidate?.data?.data.candidateId}`) }}>Generate Offer</Button>
                    </FormControl>
                  </Grid>
                </>
              }


              {(window.localStorage.getItem('role') == 'Admin' || window.localStorage.getItem('role') == 'HR Manager') && <>

                {(currentStatus === '7bfcc09d-7014-47bd-8bda-984081ddd991' || candidate?.data?.data?.candidateStatusId === '7bfcc09d-7014-47bd-8bda-984081ddd991') &&
                  <>
                    <Grid>
                      <FormControl variant="standard" sx={{ width: 250, marginTop: 7 }} size="small">
                        <Button variant='contained' name='generateOfferLetter' onClick={(e) => { navigate(`/salaryView/${candidate?.data?.data.candidateId}`) }}>Generate Offer</Button>
                      </FormControl>
                    </Grid>
                  </>
                }
              </>}

            </Grid>
            {(candidate?.data?.data?.candidateStatusId === 'a5392787-669d-43cd-ba75-773c1a8ddc02' || currentStatus === 'a5392787-669d-43cd-ba75-773c1a8ddc02') &&
              <>
                {showOnBoarding &&

                  <>
                    <h2 style={{
                      marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
                      color: "white", fontWeight: 500, fontSize: "18px",
                    }}   > OnBoarding Details </h2>
                    <hr />
                    <Grid container m={2} gap={5}>

                      <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                        <TextField
                          id="outlined-required"
                          label="office Mail"
                          variant="filled"
                          placeholder='Enter Office 365 Mail'
                          size='small'
                          margin='normal'
                          name='officeMail'
                          required
                          value={joiningDetails?.officeMail}
                          defaultValue={candidate?.data?.data?.joiningDetails?.officeMail}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, officeMail: e.target.value }) }}
                        // onChange={(e) => handleChange(e)}
                        />
                      </FormControl>


                      <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                        <InputLabel id="demo-simple-select-label">devices</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="devices"
                          label="devices"
                          name="devices"
                          sx={{ width: 250, height: 50 }}
                          style={{ marginBottom: '20px', marginRight: "20px", borderRadius: "10px" }}
                          value={joiningDetails?.devices}
                          defaultValue={candidate?.data?.data?.joiningDetails?.devices}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, devices: e.target.value }) }}
                        >
                          {devices?.data && devices.data.data.map(cc =>
                            <MenuItem key={cc.metaDataId} value={cc.displayText.device}>{cc.displayText.device}</MenuItem>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl variant="standard" sx={{ width: 250, marginTop: 2 }} size="small">
                        <TextField
                          required
                          id="outlined-required"
                          label="offered Designation"
                          variant="filled"
                          placeholder='Enter designation'
                          size='small'
                          margin='normal'
                          name='offeredDesignation'
                          value={joiningDetails?.offeredDesignation}
                          defaultValue={candidate?.data?.data?.joiningDetails?.offeredDesignation}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, offeredDesignation: e.target.value }) }}
                        // onChange={(e) => handleChange(e)}
                        />
                      </FormControl>



                      <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                        <h3>Zoom Access</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="zoomAccess"
                          variant="filled"
                          value={joiningDetails?.zoomAccess}
                          defaultValue={candidate?.data?.data?.joiningDetails?.zoomAccess}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, zoomAccess: e.target.value }) }}
                        >
                          <FormControlLabel value="yes" control={<Radio required={true} />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                      </FormControl>



                      <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                        <h3>Ceipal Access</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="ceipalAccess"
                          variant="filled"
                          value={joiningDetails?.ceipalAccess}
                          defaultValue={candidate?.data?.data?.joiningDetails?.ceipalAccess}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, ceipalAccess: e.target.value }) }}

                        >
                          <FormControlLabel value="yes" control={<Radio required={true} />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                      </FormControl>

                      <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                        <h3>Sim Card</h3>
                        <RadioGroup
                          row
                          required
                          aria-labelledby="input-radio-buttons-group-fresher-label"
                          name="simCard"
                          variant="filled"
                          value={joiningDetails?.simCard}
                          defaultValue={candidate?.data?.data?.joiningDetails?.simCard}
                          onChange={(e) => { setJoiningDetails({ ...joiningDetails, simCard: e.target.value }) }}
                        >
                          <FormControlLabel value="yes" control={<Radio required={true} />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                      </FormControl>


                    </Grid>
                  </>
                }
              </>

            }

            <h2 style={{
              marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
              color: "white", fontWeight: 500, fontSize: "18px",
            }}>Recruiter / Hiring Manager  /HR </h2>
            <hr />

            <Grid container m={2} gap={5}>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 6, }} size="small">

                  <InputLabel id="select-hr"> Recruiter</InputLabel>
                  <Select
                    labelId="select-Recruiter"
                    id="assignCandidateToRecruiter"
                    name='assignCandidateToRecruiter'
                    label='Recruiter'
                    variant="filled"
                    required
                    placeholder='Assign Candidate To Recruiter'
                    disabled={(window.localStorage.getItem('role') !== 'TA Manager')}
                    onChange={(e) => handleChange(e)}
                    margin='normal'
                    defaultValue={candidate?.data?.data?.createdBy?.userId}>
                    {recruiters?.data?.data?.map((h) =>
                      <MenuItem key={h?.userId} value={h?.userId}>{h?.displayName}</MenuItem>
                    )}
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                  <h3>Hiring Manager</h3>
                  <TextField
                    required
                    labelId="input-recruiter-label"
                    id="outlined-required"
                    placeholder='Enter Recruiter Name'
                    size='small'
                    margin='normal'
                    name='createdById'
                    variant="filled"
                    value={
                      candidate?.data?.data?.jobTitle?.jobAssignments?.map((el) => el?.user?.displayName).filter(
                        (el) => !taManagerList?.includes(el)).filter((el) => hiringManagerList?.includes(el)).length === 0
                        ?
                        candidate?.data?.data?.jobTitle?.jobAssignments?.map((el) => el?.user?.displayName).filter(
                          (el) => taManagerList?.includes(el))
                        :
                        candidate?.data?.data?.jobTitle?.jobAssignments?.map((el) => el?.user?.displayName).filter(
                          (el) => !taManagerList?.includes(el)).filter((el) => hiringManagerList?.includes(el))

                    }

                    disabled={true}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                  <h3>Reporting Manager Name</h3>
                  <TextField
                    required
                    size='medium'
                    margin='normal'
                    name='reportingManagerName'
                    variant="filled"
                    onChange={(e) => handleChange(e)}
                    defaultValue={candidate?.data?.data?.reportingManagerName ? candidate?.data?.data?.reportingManagerName : ""}
                    disabled={candidate?.data?.data?.candidateStatusId === 'c3db3358-9c57-47b5-9e53-967d0a151541' ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 250, marginTop: 2, }} size="small">
                  <h3>Reporting Manager Email</h3>
                  <TextField
                    required
                    id="reporting_manager_id"
                    placeholder='Enter Reporting Manager'
                    size='small'
                    margin='normal'
                    name='reportingManager'
                    variant="filled"
                    type="email"
                    defaultValue={candidate?.data?.data.reportingManager}
                    disabled={candidate?.data?.data?.candidateStatusId === 'c3db3358-9c57-47b5-9e53-967d0a151541' ? true : false}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <FormControl variant="standard" sx={{ width: 300, marginTop: 6, }} size="small">

                  <InputLabel id="select-hr">HR</InputLabel>
                  <Select
                    labelId="select-hr"
                    id="HR"
                    name='hrId'
                    label='HR'
                    variant="filled"
                    required
                    placeholder='Select HR'
                    disabled={candidate?.data?.data?.candidateStatusId === '95d729ef-0b79-45ea-8eb4-afbb0128ab04' ? false : true}
                    onChange={(e) => handleChange(e)}
                    margin='normal'
                    defaultValue={candidate?.data?.data?.hrId}>
                    {HR?.data?.data?.filter((x) => x?.status === 'Active')?.map((h) =>
                      <MenuItem key={h?.userId} value={h?.userId}>{h?.displayName}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

            </Grid>
            <h2 style={{
              marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
              color: "white", fontWeight: 500, fontSize: "18px",
            }}   > Documents </h2>
            <hr />
            <Grid container m={2} gap={5}>

              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow sx={{
                    "& th": {
                      borderBottom: "2px solid black",
                      fontSize: "0.9rem",
                      color: "white",
                      backgroundColor: "#243c80",
                      borderLeft: "1px solid #3b4864",
                      height: '1px'
                    }
                  }}>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <TableCell> Resume</TableCell>
                    {
                      (candidate?.data?.data.documents?.filter((doc) => doc.documentName === 'resume')[0]) ?
                        <>
                          <TableCell>
                            <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                              variant='contained'
                              name='resume'
                              onClick={(e) => downloadDocument(e)} >
                              Download Resume
                            </Link>
                          </TableCell>

                          {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='resume'
                                  onClick={(e) => handleDelete(e)} >
                                  Delete Resume
                                </Link>
                              </TableCell>
                            </>
                          }
                        </>
                        :
                        <TableCell>
                          <input id="resume" type="file" name='resume' accept=".doc,.docx,application/pdf" onChange={(e) => { handleUpload(e) }} />
                        </TableCell>
                    }

                  </TableRow>

                  <TableRow hover>
                    <TableCell> Aadhar Card</TableCell>
                    {
                      (candidate?.data?.data.documents?.filter((doc) => doc.documentName === 'Adhaar Card')[0]) ?
                        <>
                          <TableCell>
                            <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                              variant='contained'
                              name='Adhaar Card'
                              onClick={(e) => downloadDocument(e)} >
                              Download Adhaar Card
                            </Link>

                          </TableCell>
                          {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='Adhaar Card'
                                  onClick={(e) => handleDelete(e)} >
                                  Delete Adhaar Card
                                </Link>
                              </TableCell>
                            </>
                          }
                        </>
                        :
                        <TableCell>
                          <input id="adhaar_card" type="file" name='Adhaar Card' accept=".doc,.docx,application/pdf" onChange={(e) => handleUpload(e)} />
                        </TableCell>
                    }

                  </TableRow>

                  <TableRow hover>
                    <TableCell> Pan Card</TableCell>
                    {
                      (candidate?.data?.data.documents?.filter((doc) => doc.documentName === 'Pan Card')[0]) ?
                        <>
                          <TableCell>
                            <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                              variant='contained'
                              name='Pan Card'
                              onClick={(e) => downloadDocument(e)} >
                              Download Pan Card
                            </Link>

                          </TableCell>
                          {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='Pan Card'
                                  onClick={(e) => handleDelete(e)} >
                                  Delete Pan Card
                                </Link>
                              </TableCell>
                            </>
                          }
                        </>
                        :
                        <TableCell>
                          <input id="pan_card" type="file" name='Pan Card' accept=".doc,.docx,application/pdf" onChange={(e) => handleUpload(e)} />
                        </TableCell>
                    }

                  </TableRow>

                  <TableRow hover>
                    <TableCell> Latest Education Degree Certificate </TableCell>
                    {
                      candidate?.data?.data?.documents?.filter((doc) => doc.documentName === 'Graduation Letter')[0] ?
                        <>
                          <TableCell>
                            <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                              variant='contained'
                              name='Graduation Letter'
                              onClick={(e) => downloadDocument(e)} >
                              Download Graduation Letter
                            </Link>
                          </TableCell>
                          {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='Graduation Letter'
                                  onClick={(e) => handleDelete(e)} >
                                  Delete Graduation Letter
                                </Link>
                              </TableCell>
                            </>
                          }
                        </>
                        :
                        <TableCell>
                          <input id="graduation_letter" type="file" name='Graduation Letter' accept=".doc,.docx,application/pdf" onChange={(e) => handleUpload(e)} />
                        </TableCell>
                    }
                  </TableRow>

                  {isFresher === false &&
                    <>
                      <TableRow hover>
                        <TableCell> Salary Slip</TableCell>
                        {
                          (candidate?.data?.data?.documents?.filter((doc) => doc.documentName === 'Salary Slip')[0]) ?
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='Salary Slip'
                                  onClick={(e) => downloadDocument(e)} >
                                  Download Salary Slip
                                </Link>

                                {/* <Button variant='contained' name='Salary Slip' onClick={(e) => downloadDocument(e)}>Download Salary Slip</Button> */}
                              </TableCell>

                              {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                                <>
                                  <TableCell>
                                    <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                      variant='contained'
                                      name='Salary Slip'
                                      onClick={(e) => handleDelete(e)} >
                                      Delete Salary Slip
                                    </Link>
                                    {/* <Button variant='contained' name='Salary Slip' onClick={(e) => handleDelete(e)}>Delete Salary Slip</Button> */}
                                  </TableCell>
                                </>
                              }
                            </>
                            :
                            <TableCell>
                              <input id="salary_slips" type="file" name='Salary Slip' accept=".doc,.docx,application/pdf" onChange={(e) => handleUpload(e)} />
                            </TableCell>
                        }
                      </TableRow>
                      <TableRow hover>
                        <TableCell> Bank Statement</TableCell>
                        {
                          candidate?.data?.data?.documents?.filter((doc) => doc.documentName === 'Bank Statement')[0] ?
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='Bank Statement'
                                  onClick={(e) => downloadDocument(e)} >
                                  Download Bank Statement
                                </Link>
                                {/* <Button variant='contained' name='Bank Statement' onClick={(e) => downloadDocument(e)}>Download Bank Statement</Button> */}
                              </TableCell>

                              {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                                <>
                                  <TableCell>
                                    <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                      variant='contained'
                                      name='Bank Statement'
                                      onClick={(e) => handleDelete(e)} >
                                      Delete Bank Statement
                                    </Link>
                                    {/* <Button variant='contained' name='Bank Statement' onClick={(e) => handleDelete(e)}>Delete Bank Statement</Button> */}
                                  </TableCell>
                                </>
                              }
                            </>
                            :
                            <TableCell>
                              <input id="bank_statement" type="file" name='Bank Statement' accept=".doc,.docx,application/pdf" onChange={(e) => handleUpload(e)} />
                            </TableCell>
                        }
                      </TableRow>
                      <TableRow hover>
                        <TableCell> Previous Company Offer letter</TableCell>
                        {
                          candidate?.data?.data?.documents?.filter((doc) => doc.documentName === 'Offer Letter')[0] ?
                            <>
                              <TableCell>
                                <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                  variant='contained'
                                  name='Offer Letter'
                                  onClick={(e) => downloadDocument(e)} >
                                  Download Offer Letter
                                </Link>
                                {/* <Button variant='contained' name='Offer Letter' onClick={(e) => downloadDocument(e)}>Download Offer Letter</Button> */}
                              </TableCell>

                              {(candidate?.data?.data?.candidateStatusId === '9098ccd3-b893-4f82-bbb1-b5663a596a71' || '95d729ef-0b79-45ea-8eb4-afbb0128ab04') &&
                                <>
                                  <TableCell>
                                    <Link style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}
                                      variant='contained'
                                      name='Offer Letter'
                                      onClick={(e) => handleDelete(e)} >
                                      Delete Offer Letter
                                    </Link>
                                    {/* <Button variant='contained' name='Offer Letter' onClick={(e) => handleDelete(e)}>Delete Offer Letter</Button> */}
                                  </TableCell>
                                </>
                              }

                            </>
                            :
                            <TableCell>
                              <input id="offer_letter" type="file" name='Offer Letter' accept=".doc,.docx,application/pdf" onChange={(e) => handleUpload(e)} />
                            </TableCell>
                        }
                      </TableRow>

                    </>
                  }

                  <TableRow hover>
                    <TableCell> Document Verification Check </TableCell>
                    <TableCell>
                      <Grid item xs='auto'>
                        <FormControl variant="standard" style={{ fontWeight: 500, fontSize: "16px", padding: "10px", marginTop: "15px" }}>
                          <FormControlLabel control={
                            <Checkbox name={'DocverificationTAManager'}
                              defaultChecked={candidate?.data?.data?.hrId !== null ? true : false}
                              color="success"
                              onChange={(e) => handleChange(e)}
                              inputProps={{ 'aria-label': 'controlled' }}
                              disabled={(window.localStorage.getItem('role') !== 'TA Manager')} />}
                            label='All Document Verified'
                          />
                        </FormControl>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      {
                        candidate?.data?.data?.hrId !== null ?
                          candidate.data.data.statusHistory.map((el) => el.candidateStatusId === '95d729ef-0b79-45ea-8eb4-afbb0128ab04' ? el.lastModifiedBy.displayName : '')
                          :
                          ''
                      }
                    </TableCell>
                  </TableRow>




                </TableBody>
              </Table>

            </Grid>


            <h2 style={{
              marginTop: '80px', padding: '10px', backgroundColor: "#243c80",
              color: "white", fontWeight: 500, fontSize: "18px",
            }}   > Remark </h2>
            <hr />


            <Grid item xs='auto'>
              <ReactQuill
                theme="snow"
                name='remark'
                defaultValue={candidate?.data?.data?.remark}
                onChange={setRemark}
                placeholder="Write Something"
                style={{ height: '100px', margin: "19px" }} />
              <Button
                variant="contained"
                sx={{ borderRadius: 3 }}
                style={{
                  padding: "10px", width: "250px", marginTop: "50px",
                }}
                type="submit"
                disabled={currentStatus === '7bfcc09d-7014-47bd-8bda-984081ddd991'}
              > Update </Button>
              {(window.localStorage.getItem('role') == 'TA Manager') && <>
                <Button
                  variant="contained"
                  color="error"
                  endIcon={<DeleteIcon />}
                  sx={{ borderRadius: 3 }}
                  style={{
                    padding: "10px", width: "100px", marginTop: "50px", marginLeft: '50px'
                  }}
                  onClick={() => { setDeleteCandidate(true) }}
                >Delete</Button>
              </>}
            </Grid>
          </Box>
        </form>
      }
    </>)
}

export default Candidate