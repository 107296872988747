// import { useMsal } from '@azure/msal-react';
import { loginRequest, msalConfig } from "../authConfig";


export const acquireToken = async function (instance, accounts) {
  // const {instance, accounts} = useMsal();
  // let token = window.localStorage.getItem('token');

  // if (token !== null) {
  //   return token;
  // } else {
  const request = {
    // scopes:[],
    scopes:["api://55071dcb-87be-43f8-84f0-5555fa96a932/access_as_user"],
    account: accounts[0]
  };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  // const token =  await instance.acquireTokenSilent(request).then((response) => {
  //     return response.accessToken;
  // }).catch((e) => {
  //     instance.acquireTokenPopup(request).then((response) => {
  //       return  response.accessToken;
  //     });
  // });
  let response;

  try {
    response = await instance.acquireTokenSilent(request);
    
  } catch (e) {
    response = await instance.acquireTokenPopup(request)
    console.log(e);
  }
  console.log(response);

  window.localStorage.setItem('token', response.accessToken);
  return response.accessToken;

}



