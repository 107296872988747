
import React, { useContext } from 'react';
import './UserProfile.css';
import Box from '@mui/material/Box';
import { UserContext } from '../Routes/Routes';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';



import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useSpring, animated } from 'react-spring';
export default function UserProfile() {

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    const animation = useSpring({
        from: { opacity: 0, transform: 'translateY(-100%)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { duration: 900 },
      });
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    const value = useContext(UserContext);
    console.log(value.data)
    return (
        <animated.div style={animation}>
            <div className='profileCard'>
                <Paper>
                    <List
                        sx={{
                            width: 360,
                            height: 180,
                            bgcolor: 'background.paper',
                            borderRadius: '500px',
                            borderShadow: "1px solid #3b4864",
                            marginLeft: 1
                        }}
                    >
                        <ListItem>

                            <ListItemAvatar>
                                <Avatar>
                                    {/* <ImageIcon /> */}
                                    <Avatar {...stringAvatar(value?.data?.displayName)} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={value.data.displayName}

                                secondary="" />

                        </ListItem>
                        <Divider light />

                        <CardContent>

                            <Typography gutterBottom variant="subtitle2" component="div">
                                Role : {window.localStorage.getItem('role')}</Typography>
                            <Typography variant="subtitle2" component="div" >
                                Email :{value.data.email} </Typography>
                            <Typography gutterBottom variant="subtitle2" component="div">
                                Phone No : {value.data.mobile}</Typography>
                        </CardContent>

                    </List>
                </Paper>
            </div>
        </animated.div>

    );
}
