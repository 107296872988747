import { useMsal } from '@azure/msal-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { acquireToken } from '../acquireToken';

let baseURL;

if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseURL = 'https://berec.futransolutions.com';
} else if(process.env.REACT_APP_NODE_ENV === 'test'){
    baseURL = 'https://hr-app-test-be.futransolutions.com';
}
else {
    baseURL = 'http://localhost:5000';
}

export const useUsers = () => {

    const { instance, accounts } = useMsal();
    const queryClient = useQueryClient();
    const role = window.localStorage.getItem('role');




    const useUser = () => useQuery('user', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {

                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get('/user');
        return data;
    });


    const useAppUsers = () => useQuery('appUsers', async() => {
        const token  = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
            'Authorization': `Bearer ${token}`,
            'user-info': role
            }
        });
        const { data } = await client.get('/appUsers');
        return data;
    });



    const useHiringManagers = () => useQuery('hiringManagers', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,

            headers: { 'Authorization': `Bearer ${token}` }
        });
        const { data } = await client.get('/appUsers?role=Hiring Manager',
            { headers: { 'user-info': role } });
        return data;
    });
    const AllappUsers = (roleName) =>  useQuery('allappUsers', async() => {
        const token  = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
            'Authorization': `Bearer ${token}`,
            'user-info': role
            }
        });
        const { data } = await client.get(`/appUsers?role=${roleName}`);
        return data;
    });
    const useRecruiters = () => useQuery('recruiters', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role
            }
        });
        const { data } = await client.get('/appUsers?role=Recruiter');
        return data;
    },
        // {
        //     enabled: role === 'Admin' ? true : false
        // }
    );


    const addReferalCandidate = useMutation(async (candidate) => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.post('/candidate', candidate);
        return data;
    },

        {
            onSuccess: (updatedCandidate) => {
                queryClient.invalidateQueries(['referalCandidate']);
            },
            onError: (e) => {
                return alert(e.response.data.message);
            }
        }


    );

    const useGetReferalCandidates = (id, filter) => useQuery('referalCandidate', async () => {
        let query;
        let keys = Object.keys(filter);
        if (keys.length > 0 && keys.length === 1) {
            query = `&${keys[0]}=${filter[keys[0]]}`;
        } else if (keys.length > 0 && keys.length > 1) {
            query = '?';
            for (let i = 0; i < keys.length; i++) {
                query += `${keys[i]}=${filter[keys[i]]}&`
            }
        }

        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await client.get(`/getReferralByuserid?${role !== 'Admin' && role !== 'TA Manager' ? `userId=${id}` : ''}${query ? query : ''}`);

        return data;
    });

    const useHR = () => useQuery('HR', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get(`/hr`);
        return data;

    });


    const useTaManager = () => useQuery('TaManager', async () => {
        const token = await acquireToken(instance, accounts);
        const client = axios.create({
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'user-info': role

            }
        });
        const { data } = await client.get('/TaManager');
        return data;

    },
        // {
        //     enabled: role === 'Admin' ? true : false
        // }

    );

    return {
        useUser,
        useAppUsers,
        // tenantUsers,
        // guestUsers,
        useRecruiters,
        useHR,
        useTaManager,

        useHiringManagers,

        // hiringManagers,
        // createAppUser,
        // useUpdateAppUser
        addReferalCandidate,
        useGetReferalCandidates,
        AllappUsers
    }
};