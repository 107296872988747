import React, { useEffect, useState, useContext } from 'react';
import './Header.css';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { UserContext } from '../../components/Routes/Routes';
// import {userProfile} from '../../components/userProfile/userProfile';
import UserProfile from '../UserProfile/UserProfile'
import Box from '@mui/material/Box';
const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [headerText, setHeaderText] = useState('')
  const location = useLocation();
  const value = useContext(UserContext);
  const [open, setOpen] = React.useState(false);

  const handleBoxOpen = () => {
    setOpen(true);
    
  };
  const handleBoxClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setHeaderText("Home");
    } else if (location.pathname.startsWith('/candidate') && !location.pathname.endsWith('/interview')) {
      setHeaderText("Candidates");
    } else if (location.pathname.endsWith('/interview')) {
      setHeaderText("Interviews");
    } else if (location.pathname.startsWith('/jobRequisition')) {
      setHeaderText("Job Requisition");
    }
    else if (location.pathname.startsWith('/SalaryView')) {
      setHeaderText("Candidates Salary View");
    }
    else if (location.pathname.startsWith('/dashboard')) {
      setHeaderText("Dashboard");

    }
    else if (location.pathname.startsWith('/recruiterdashboard')) {
      setHeaderText("Recruiter Dashboard");

    } else if (location.pathname.startsWith('/admin')) {
      setHeaderText("Admin");
    } else if (location.pathname.startsWith('/referral')) {
      setHeaderText("Referral");
    }
  }, [location.pathname]);

  function handleLogout(instance) {
    instance.logoutRedirect().then(() => {
    }).catch(e => {
      console.error(e);
    });
    window.localStorage.removeItem('role');
    window.localStorage.removeItem('token');
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  console.log(open)

  return (

    <>
    {open ===true ?
        <>
          <UserProfile/>
        </>
        :
        <>
        </>
      }
      <div className='header-container'>
        {
          isAuthenticated &&
          <>
            <h1 className='header-container__header'>{headerText}</h1>
            <div className='header-container__logout'>

              <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
                <Avatar onMouseOver={handleBoxOpen} onMouseOut={handleBoxClose} {...stringAvatar(value?.data?.displayName)} />
              </Stack>
              <h3 className='header-container__content'>Logout</h3>
              <PowerSettingsNewIcon className='header-container__icon' onClick={() => handleLogout(instance)} />
            </div>
          </>
        }
      </div>

    </>

  )
};

export default Header;